import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpRequest } from '@angular/common/http';
import { NgForm, FormsModule, FormBuilder, FormGroup, Validators, FormControl, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, NgClass, NgIf, formatDate } from '@angular/common';
import { environment } from '../../environments/environment';
import { Title } from "@angular/platform-browser";
import { Router } from '@angular/router';
declare var google: any;
@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [CommonModule, NgIf, NgClass, ReactiveFormsModule, FormsModule],
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(private http: HttpClient,
    private ngZone: NgZone,
    private formBuilder: FormBuilder,
    private titleService: Title,
    private router: Router) {
    this.titleService.setTitle("Driver Risk-Resolva | Dashboard");
  }
  dateformat = 'dd-MM-yyyy';
  locale = 'en-US';
  reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('token')
  });
  dashletslists: any;
  DashboardLastUpdated: any;
  DashboardId: any;
  DrivingEventsPerDay_Chart: any;
  GshockEventsPerDay_Chart: any;
  AccidentEventsPerDay_Chart: any;
  IncidentEventsPerDay_Chart: any;
  EmergencyEventsPerDay_Chart: any;
  Updatedashboardchk: boolean = true;

  private DrivingEventsChart: ElementRef;
  @ViewChild('DrivingEventsPerDayChart') set drivingcontent(DrivingContent: ElementRef) {
    this.DrivingEventsChart = DrivingContent;
  }
  private GshockEventsChart: ElementRef;
  @ViewChild('GshockEventsPerDayChart') set gshockcontent(GshockContent: ElementRef) {
    this.GshockEventsChart = GshockContent;
  }
  private AccidentEventsChart: ElementRef;
  @ViewChild('AccidentEventsPerDayChart') set accidentcontent(AccidentContent: ElementRef) {
    this.AccidentEventsChart = AccidentContent;
  }
  private IncidentEventsChart: ElementRef;
  @ViewChild('IncidentEventsPerDayChart') set incidentcontent(IncidentContent: ElementRef) {
    this.IncidentEventsChart = IncidentContent;
  }
  private EmergencyEventsChart: ElementRef;
  @ViewChild('EmergencyEventsPerDayChart') set emergencycontent(EmergencyContent: ElementRef) {
    this.EmergencyEventsChart = EmergencyContent;
  }

  ngOnInit() {
    this.getDashboard();
  }
  //redirecting to selected incident
  goToIncident(data) {
    debugger;
    //this.router.navigate(['home/incidents'], { queryParams: { id: data.EventId } });
    sessionStorage.setItem('id', data.EventId);
    this.router.navigate(['home/incidents']);
  }
  //get dashboards
  getDashboard() {
    this.http.get(environment.API_URL + "GetFleetDashboards/"+0, { headers: this.reqHeader })
      .subscribe(
      (data: any) => {
        if (data != null) {
          this.DashboardId = data._fleetDashboards[0].DashboardId;
          this.getDashlets(data._fleetDashboards[0].DashboardId, 0);
        }
        },
        error => {
          console.log("Error", error);
        }
      );
  };
  //get dashlets
  dashletsArray = [];
  getDashlets(id, status) {
    this.Updatedashboardchk = false;
    this.dashletsArray = [];
    this.http.get(environment.API_URL + "GetDashBoardData/" + id + '/' + status, { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          console.log(data);
          this.Updatedashboardchk = true;
          this.DashboardLastUpdated = data.LastUpdatedTime;
          this.dashletslists = data._Dashboards;
          this.dashletslists.forEach((value, key) => {
            var obj: any = {};
            obj.Icon = value.Icon;
            obj.Color = value.Color;
            obj.DashletTitle = value.DashletTitle;
            obj.ChartType = value.ChartType;
            obj.OrderId = value.OrderId;
            obj.DashletId = value.DashletId;
            obj.DashboardId = value.DashboardId;
            obj.Description = value.DashletDesc;
            obj.TileSize = value.TileSize.toString();
            obj.Time = value.Time.toString();
            obj.isview = true;
            obj.KPI = value.KPI;
            obj.StoreProcedure = value.StoreProcedure;
            obj.fleetList = value.fleetList;
            obj.vehicleList = value.vehicleList;
            if (value.TileSize === 1) {
              obj.height = 'small';
              obj.width = 'col-3';
            } else if (value.TileSize === 2) {
              obj.height = 'small';
              obj.width = 'col-6';
            }
            else {
              obj.height = 'small';
              obj.width = 'col-12';
            }
            obj.dashletdata = JSON.parse(value.DashletData);
            if (obj.dashletdata == null)
            if (obj.dashletdata.High !== 0 || obj.dashletdata.Medium !== 0 || obj.dashletdata.Low !== 0) {
              if (value.ChartType === "bar" && value.KPI === 2) {
                setTimeout(() => {
                  this.DrivingEventsPerDay_Chart = obj.dashletdata;
                  google.charts.load('current', { 'packages': ['corechart'] });
                  google.charts.setOnLoadCallback(this.DrivingEventsPerDayChart);
                });
              }
              if (value.ChartType === "bar" && value.KPI === 21) {
                setTimeout(() => {
                  this.GshockEventsPerDay_Chart = obj.dashletdata;
                  google.charts.load('current', { 'packages': ['corechart'] });
                  google.charts.setOnLoadCallback(this.GshockEventsPerDayChart);
                });

              }
              if (value.ChartType === "bar" && value.KPI === 23) {
                setTimeout(() => {
                  this.AccidentEventsPerDay_Chart = obj.dashletdata;
                  google.charts.load('current', { 'packages': ['corechart'] });
                  google.charts.setOnLoadCallback(this.AccidentEventsPerDayChart);
                });

              }
              if (value.ChartType === "bar" && value.KPI === 25) {
                setTimeout(() => {
                  this.IncidentEventsPerDay_Chart = obj.dashletdata;
                  google.charts.load('current', { 'packages': ['corechart'] });
                  google.charts.setOnLoadCallback(this.IncidentEventsPerDayChart);
                });

              }
              if (value.ChartType === "bar" && value.KPI === 19) {
                setTimeout(() => {
                  this.EmergencyEventsPerDay_Chart = obj.dashletdata;
                  google.charts.load('current', { 'packages': ['corechart'] });
                  google.charts.setOnLoadCallback(this.EmergencyEventsPerDayChart);
                });

              }
            }
            this.dashletsArray.push(obj);

          });
     

        },
        error => {
          console.log("Error", error);
        }
      );
  };

  //Driving Events PerDay Chart
  DrivingEventsPerDayChart = () => {
    const data = google.visualization.arrayToDataTable([
      ["Element", "Severity", { role: "style" }],
      ["High", this.DrivingEventsPerDay_Chart.High, "#ff003d"],
      ["Medium", this.DrivingEventsPerDay_Chart.Medium, "#0ba3db"],
      ["Low", this.DrivingEventsPerDay_Chart.Low, "color: #e5a603"]
    ]);
    const view = new google.visualization.DataView(data);
    view.setColumns([0, 1,
      {
        calc: "stringify",
        sourceColumn: 1,
        type: "string",
        role: "annotation"
      },
      2]);
    const options = {
      title: "Severity",
      bar: { groupWidth: "95%" },
      legend: { position: "none" }
    };
    const chart = new google.visualization.BarChart(this.DrivingEventsChart.nativeElement);
    chart.draw(view, options);
  }
  //Driving Events PerDay Chart
  GshockEventsPerDayChart = () => {
    const data = google.visualization.arrayToDataTable([
      ["Element", "Severity", { role: "style" }],
      ["High", this.GshockEventsPerDay_Chart.High, "#ff003d"],
      ["Medium", this.GshockEventsPerDay_Chart.Medium, "#0ba3db"],
      ["Low", this.GshockEventsPerDay_Chart.Low, "color: #e5a603"]
    ]);
    const view = new google.visualization.DataView(data);
    view.setColumns([0, 1,
      {
        calc: "stringify",
        sourceColumn: 1,
        type: "string",
        role: "annotation"
      },
      2]);
    const options = {
      title: "Severity",
      bar: { groupWidth: "95%" },
      legend: { position: "none" }
    };
    const chart = new google.visualization.BarChart(this.GshockEventsChart.nativeElement);
    chart.draw(view, options);
  }
  //Accident Events PerDay Chart
  AccidentEventsPerDayChart = () => {
    const data = google.visualization.arrayToDataTable([
      ["Element", "Severity", { role: "style" }],
      ["High", this.AccidentEventsPerDay_Chart.High, "#ff003d"],
      ["Medium", this.AccidentEventsPerDay_Chart.Medium, "#0ba3db"],
      ["Low", this.AccidentEventsPerDay_Chart.Low, "color: #e5a603"]
    ]);
    const view = new google.visualization.DataView(data);
    view.setColumns([0, 1,
      {
        calc: "stringify",
        sourceColumn: 1,
        type: "string",
        role: "annotation"
      },
      2]);
    const options = {
      title: "Severity",
      bar: { groupWidth: "95%" },
      legend: { position: "none" }
    };
    const chart = new google.visualization.BarChart(this.AccidentEventsChart.nativeElement);
    chart.draw(view, options);
  }
  //Incident Events PerDay Chart
  IncidentEventsPerDayChart = () => {
    const data = google.visualization.arrayToDataTable([
      ["Element", "Severity", { role: "style" }],
      ["High", this.IncidentEventsPerDay_Chart.High, "#ff003d"],
      ["Medium", this.IncidentEventsPerDay_Chart.Medium, "#0ba3db"],
      ["Low", this.IncidentEventsPerDay_Chart.Low, "color: #e5a603"]
    ]);
    const view = new google.visualization.DataView(data);
    view.setColumns([0, 1,
      {
        calc: "stringify",
        sourceColumn: 1,
        type: "string",
        role: "annotation"
      },
      2]);
    const options = {
      title: "Severity",
      bar: { groupWidth: "95%" },
      legend: { position: "none" }
    };
    const chart = new google.visualization.BarChart(this.IncidentEventsChart.nativeElement);
    chart.draw(view, options);
  }
  //Emergency Events PerDay Chart
  EmergencyEventsPerDayChart = () => {
    const data = google.visualization.arrayToDataTable([
      ["Element", "Severity", { role: "style" }],
      ["High", this.EmergencyEventsPerDay_Chart.High, "#ff003d"],
      ["Medium", this.EmergencyEventsPerDay_Chart.Medium, "#0ba3db"],
      ["Low", this.EmergencyEventsPerDay_Chart.Low, "color: #e5a603"]
    ]);
    const view = new google.visualization.DataView(data);
    view.setColumns([0, 1,
      {
        calc: "stringify",
        sourceColumn: 1,
        type: "string",
        role: "annotation"
      },
      2]);
    const options = {
      title: "Severity",
      bar: { groupWidth: "95%" },
      legend: { position: "none" }
    };
    const chart = new google.visualization.BarChart(this.EmergencyEventsChart.nativeElement);
    chart.draw(view, options);
  }
}
