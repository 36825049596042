import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Title } from "@angular/platform-browser";
import { CommonModule } from '@angular/common';
import { NgIf, NgClass } from '@angular/common';
@Component({
  selector: 'app-sso',
  standalone: true,
  imports: [NgIf, NgClass, CommonModule],
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.css']
})
export class SsoComponent implements OnInit {

  constructor(
    private actrouter: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private toaster: ToastrService,
    private titleService: Title
  ) {
    //this.GetAppSettings();
    const token = this.actrouter.snapshot.queryParamMap.get('token');
    setTimeout(() => {
      if (token !== undefined && token !== null && localStorage.getItem('apiUrl') !== null)
        this.UserData(token);
    }, 2000);

  }
  appsettings: any;
  loading = true;
  ngOnInit() {
    
  }
  refreshComponent() {
    this.router.navigate([this.router.url])
  }
  //getting the userdata
  UserData(accessToken) {
    /*localStorage.clear();*/
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + accessToken
    });
    this.http.get(localStorage.getItem('apiUrl') + "users/Byusername", { headers: reqHeader })
      .subscribe(
        (data: any) => {
          if (data !== null) {
            console.log(data);
            //data.body.access_token = access_token;
            localStorage.setItem('currentUser', JSON.stringify(data));
            localStorage.setItem("userdata", data);
            localStorage.setItem("token", accessToken);
            localStorage.setItem("userdata", data);
            localStorage.setItem("DeviceId", data.DeviceId);
            localStorage.setItem("UserName", data.UserName);
            localStorage.setItem("FleetId", data.FleetGroupid);
            localStorage.setItem("userid", data.Id);
            localStorage.setItem("profile", data.blobUserProfileUrl);
            localStorage.setItem("fname", data.FirstName);
            localStorage.setItem("lname", data.LastName);
            localStorage.setItem("rolename", data.RoleName);
            localStorage.setItem("roledisplayname", data.RoleDisplayName);
            localStorage.setItem("UserTimeZone", data.UserTimeZone);
            this.router.navigate(['/home']);
            this.loading = false;
          }
          else {
            this.loading = false;
            //localStorage.clear();
            this.toaster.error("You don't have permission to access from this url.");
          }
        },
        error => {
          this.loading = false;
          console.log("Error", error);
          if (accessToken !== undefined && accessToken !== null)
            this.UserData(accessToken);
        }
      );
  }
}
