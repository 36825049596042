import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConstantsService {
  //Constants
  SelectedEventObj: any;
  Viewmap_Str: string;
  public isDivEnabled: boolean = true;

  constructor() { }
  enableDiv() {
    this.isDivEnabled = true;
  }

  disableDiv() {
    this.isDivEnabled = false;
  }
}
