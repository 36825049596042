import '@angular/compiler';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { GoogleChartsModule } from 'angular-google-charts';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ViewMapComponent } from './app/view-map/view-map.component';
import { ProfileComponent } from './app/profile/profile.component';
import { ReportsComponent } from './app/reports/reports.component';
import { PendingrequestsComponent } from './app/pendingrequests/pendingrequests.component';
import { VideosComponent } from './app/videos/videos.component';
import { IncidentsComponent } from './app/incidents/incidents.component';
import { DashboardComponent } from './app/dashboard/dashboard.component';
import { AuthGuard } from './app/guards/auth.guard';
import { HomeComponent } from './app/home/home.component';
import { SsoComponent } from './app/sso/sso.component';
import { LoginComponent } from './app/login/login.component';
import { provideRouter } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { provideToastr } from 'ngx-toastr';
import { provideAnimations } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from 'ng2-tooltip-directive';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { ErrorInterceptor } from './app/helpers/error.interceptor';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { BnNgIdleService } from 'bn-ng-idle';
import { GoogleMapsModule } from '@angular/google-maps';

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

const providers = [
  { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }
];

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
    importProvidersFrom(BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }), TooltipModule, FormsModule, ReactiveFormsModule, AutocompleteLibModule, NgxPaginationModule, NgMultiSelectDropDownModule.forRoot(), GoogleChartsModule.forRoot(),
      BsDatepickerModule.forRoot(), GoogleMapsModule),
        BnNgIdleService,
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    provideToastr(),
        provideRouter([
            { path: '', component: LoginComponent },
            { path: 'sso', component: SsoComponent, pathMatch: 'full' },
            {
                path: 'home', component: HomeComponent, canActivate: [AuthGuard],
                children: [
                    { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
                    { path: 'dashboard', component: DashboardComponent },
                    { path: 'events', component: IncidentsComponent },
                    { path: 'videos', component: VideosComponent },
                    { path: 'Requests', component: PendingrequestsComponent },
                    { path: 'reports', component: ReportsComponent },
                    { path: 'profile', component: ProfileComponent },
                    { path: 'view-map', component: ViewMapComponent }
                ]
            },
            { path: 'login', component: LoginComponent }
            //,
            //{ path: '**', redirectTo: '' }
        ])
    ]
})
  .catch(err => console.log(err));

