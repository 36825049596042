
<div class="main-sidebar" [ngClass]="{'sidebar-large':!navmenutogglebutton,'sidebar-mini':navmenutogglebutton}">
  <div class="logo-bg">
    <div class="side_large_logo_maindiv">
      <div class="logo pb-1">
        <!--<img src="../../assets/images/FNOL-logo1.svg" width="148" />-->
      </div>
    </div>
    <a class="togglebutton" data-toggle="offcanvas" (click)="navmenutoggle()"><i class="far fa-bars pull-right"></i></a>
    <div class="row no-m profile">
      <div class="col-3 my-auto text-center">
        <img class="img-circle" [src]="profilepic" *ngIf="profilepic!=null" />
        <span *ngIf="profilepic==null"><img src="../../assets/images/nouserimage.png" width="130" class="img-thumbnail" style="width:140px;">
        </span>
      </div>
      <div class="col-9 p-0 my-auto">
        <p class="name txt_overflow">{{username | uppercase}}</p>
        <p class="role txt_overflow">{{rolename | uppercase}}</p>
      </div>
    </div>
  </div>
  <ul class="sidebar-menu">
    <li [routerLinkActive]="['active']">
      <a [routerLink]="['dashboard']">
        <span>Dashboard</span>
        <i class="far fa-tachometer-alt"></i>
      </a>
    </li>
    <li [routerLinkActive]="['active']">
      <a [routerLink]="['events']">
        <!--<span>Incidents</span>-->
        <span>Events</span>
        <i class="far fa-car-crash"></i>
      </a>
    </li>
    <li [routerLinkActive]="['active']">
      <a [routerLink]="['videos']">
        <span>Videos</span>
        <i class="far fa-video"></i>
      </a>
    </li>
    <li [routerLinkActive]="['active']">
      <a [routerLink]="['Requests']">
        <span>Requests</span>
        <i class="far fa-clipboard-list"></i>
      </a>
    </li>
    <li [routerLinkActive]="['active']">
      <a [routerLink]="['reports']">
        <span>Reports</span>
        <i class="far fa-file-chart-pie"></i>
      </a>
    </li>
    <li [routerLinkActive]="['active']">
      <a [routerLink]="['profile']">
        <span>Profile</span>
        <i class="far fa-user"></i>
      </a>
    </li>
    <li>
      <a (click)="openLockScreen(true)">
        <span>Screen lock</span>
        <i class="far fa-lock-alt"></i>
      </a>
    </li>
  </ul>
  <div class="sidebar-footer" [ngClass]="{'sidebar-large':!navmenutogglebutton,'sidebar-mini':navmenutogglebutton}">
    <a class="link1" (click)="logout()">
      <i class="fad fa-sign-out-alt"></i>
    </a>
  </div>
</div>

<div class="lock">
  <div id="lock_screen" class="modal animated bounceInDown lockScreenModal" role="dialog" [ngStyle]="{'display': mdlSampleIsOpen ? 'block' : 'none', 'opacity': 1}">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-body">
          <div class="w3_info">
            <div class="row bord">
              <div class="col-md-12 text-center">
                <!--  <img src="../../assets/images/FNOL-logo.svg" width="250" />-->
                <div class="logo-login"></div>
              </div>

              <div class="col-md-3 mt-2 user-style">
                <img [src]="profilepic" class="user-image img-circle no-p">
                <div class="lock-ico">
                  <i class="fas fa-user-lock"></i>
                </div>
              </div>
              <div class="col-md-8" style="margin-top: 52px;">
                <!--<span class="lock-email">Fnol.india@fleetly.net</span>-->
                <span class="lock-email">{{username}}</span>
                <form class="lock-form">
                  <div class="input-group">
                    <input type="Password" id="unlockpswd" placeholder="Password" required="" name="txtpassword" [(ngModel)]="lockpassword" (keyup.enter)="submitLockScreen()">
                  </div>
                  <span class="input-group-addon animated pulse" type="submit" id="unlock" (click)="submitLockScreen()"><i class="fas fa-key"></i></span>

                </form>                  <div *ngIf="error" class="alert alert-danger">{{error}}</div>
              </div>
            </div>
          </div>

          <div class="w3_info2">
            <!--<span class="pull-right"> © {{currentyear}}  Fleetly</span>-->
            <span class="pull-right mr-1">
              © {{currentyear }}

              <span class="copytextInfo pull-right ml-1"> </span>
              </span>
              <!--  <span class="pull-right copytextInfo"> © {{currentyear}} </span>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

