import { Component, OnInit } from '@angular/core';
import { NgForm, FormsModule, FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from '../services/authentication.service';
import { Title } from "@angular/platform-browser";
import { environment } from '../../environments/environment';
import { first } from 'rxjs/operators';
import { NgIf, NgClass } from '@angular/common';
import { MustMatch } from '../helpers/must-match.validator'
import { ToastrService } from 'ngx-toastr';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule, NgIf, NgClass, ReactiveFormsModule, FormsModule],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  ResetPasswordForm: FormGroup;
  EnterOtpForm: FormGroup;
  EnterNewPasswordForm: FormGroup;
  selectClass = "far fa-comment-alt-dots";
  selectedOtpType: any;
  errorMessage: any;
  maxTime: any;
  hidetimervalue: boolean = false;
  error: string;
  loading = false;
  cntryCode = "91";
  loginSection: boolean = true;
  notSelected: boolean = true;
  loginCounter: number = 0;
  counter: any;
  submitted: boolean = false;
  loadingReset = false;
  returnUrl: string;
  ResetpasswordSection: boolean = false;
  EnterOtpSection: boolean = false;
  EnterNewPasswordSection: boolean = false;
  userAuthenticationData: any;
  CountriesList: any;
  currentyear: number = (new Date()).getFullYear();

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private titleService: Title, private toaster: ToastrService,
   
  ) {
    this.titleService.setTitle("Driver Risk Resolva | Login");
  }

  ngOnInit() {
    //this.GetCountriesList();
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.ResetPasswordForm = this.formBuilder.group({
      username: ['', Validators.required],
      otptype: ['', Validators.required],
      CountryCode: [''],
      otpdata: ['', Validators.required]
    });
    this.EnterOtpForm = this.formBuilder.group({
      otpvalue: ['', Validators.required]
    });
    this.EnterNewPasswordForm = this.formBuilder.group({
      newpassword: [null,
        [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d](?=.*?[^\w\s]).{8,12}$/)]],
      confirmpassword: ['', Validators.required]
    },
      {
        validator: MustMatch('newpassword', 'confirmpassword')
      });
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }
  get r() { return this.ResetPasswordForm.controls; }
  get o() { return this.EnterOtpForm.controls; }
  get rp() { return this.EnterNewPasswordForm.controls; }
 // lgnError: boolean = false;


  onSubmit() {
    this.submitted = true;
   // this.lgnError = false;
    localStorage.setItem("lockscreen", "false");
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
   //this.error = '';
    this.loading = true;
    this.authenticationService.login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          this.userAuthenticationData = data;
          this.UserData(data);

        },
        error => {
          this.loading = false;
          //this.error = "Invalid credentials,Please try with valid credentials.";
          var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'No-Auth': 'True' });
          return this.http.get(environment.API_URL + "users/GetuserbyusernameNoAuth/" + this.f.username.value + "/", { headers: reqHeader })
            .subscribe(
              (data: any) => {
                if (data === null) {
                  //this.toaster.error("Invalid credentials,Please try with valid credentials.");
                 // this.lgnError = true;
                  this.toaster.error("Invalid credentials,Please try with valid credentials.");
                } else {
                 // this.lgnError = true;
                  if (data.StatusId === 5) {
                    this.toaster.error("Your account has been locked after few attempts of invalid credentials. Please retry after 15 minutes or reset password.");
                  } else if (data.StatusId === 6) {
                    this.toaster.error("your account is has been disabled,please contact adminstrator");
                  } else {
                    this.toaster.error("Invalid credentials,Please try with valid credentials.");
                  }
                }
              },
            );
          //this.loading = false;
          console.log(error);

        });
  }
  //getting the userdata
  UserData(data_) {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + data_.body.access_token
    });
    return this.http.get(environment.API_URL + "users/Byusername", { headers: reqHeader })
      .subscribe(
        (data: any) => {
          localStorage.setItem("userdata", data);
          localStorage.setItem("token", data_.body.access_token);
          localStorage.setItem("DeviceId", data.DeviceId);
          localStorage.setItem("UserName", data.UserName);
          localStorage.setItem("FleetId", data.FleetGroupid);
          localStorage.setItem("userid", data.Id);
          localStorage.setItem("profile", data.blobUserProfileUrl);
          localStorage.setItem("fname", data.FirstName);
          localStorage.setItem("lname", data.LastName);
          localStorage.setItem("rolename", data.RoleName);
          localStorage.setItem("roledisplayname", data.RoleDisplayName);
          localStorage.setItem("UserTimeZone", data.UserTimeZone);
          if (data.RoleName === "FNOL_Handler") {
            this.router.navigate(['/home']);
          }
          else {
            this.toaster.error("You doesn't have permission to access.");
            this.loading = false;
           
          }
        },
        error => {
          console.log("Error", error);
        }
      );
  }
  //reset password functionality
  ResetPassword(): void {
    this.submitted = false;
    this.loginSection = false;
    this.loadingReset = false;
    this.ResetpasswordSection = true;
    this.ResetPasswordForm.reset();
    this.selectedOtpType = "";
  };
  ResetPasswordBack(): void {
    this.loginSection = true;
    this.notSelected = true;
   // this.lgnError = false;
    this.ResetpasswordSection = false;
  };
  ResetPasswordOtpTypeChange(ev) {
    this.notSelected = false;
    console.log(ev.target.value);
    var val = ev.target.value;
    if (val == "mobileNumber") {
      this.selectClass = "fas fa-phone-alt";
    } else if (val == "email") {
      this.selectClass = "far fa-envelope";
    }
    this.selectedOtpType = this.r.otptype.value;
    this.r.otpdata.reset();

    if (this.selectedOtpType == 'mobileNumber') {
      this.GetCountriesList();
    }
    //debugger;
  };

  //get countris list 
  GetCountriesList() {
    // debugger;
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'No-Auth': 'True' });
    return this.http.get(environment.API_URL + "Countries/", { headers: reqHeader })
      .subscribe(
        (data: any) => {
          console.log(data);
          this.CountriesList = data;
        },
        error => {
          console.log("Error", error);
        }
      );
  };
  //submit verificatio details for the reset password
  ResetPasswordFormSubmit() {
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'No-Auth': 'True' });
    this.submitted = true;
    if (this.ResetPasswordForm.invalid) {
      this.loadingReset = false;
      return;
    }
    else {
      this.loadingReset = true;
      let otpinput = null;
      if (this.r.otptype.value === "email") {
        otpinput = this.r.otpdata.value;
      } else {
        otpinput = this.r.CountryCode.value + '-' + this.r.otpdata.value;
      }
      return this.http.get(environment.API_URL + "users/VerifyUser/" + this.r.otptype.value + "/" + otpinput + "/" + this.r.username.value + "/" + "null" + "/"+ false, { headers: reqHeader })
        .subscribe(
          (data: any) => {
            this.loadingReset = false;
            if (data !== null) {
              // debugger;
              console.log(data);
              this.EnterOtpSection = true;
              this.SaveOtpInSession(data);
              this.ResetpasswordSection = false;
              this.submitted = false;
              this.EnterOtpForm.reset();
              this.StartTimer();
              this.hidetimervalue = false;
              this.maxTime = 60;
            } else {
              this.toaster.error( "Please check entered details..!")
            }
          },
          error => {
            this.toaster.error("Something went wrong with provided data, Please check once.");
            this.loadingReset = false;
            console.log("Error", error);
          }
        );
    }
  };

  //Timer function 
  StartTimer() {
    setTimeout(x => {
      if (this.maxTime <= 0) {
        this.hidetimervalue = false;
      }
      this.maxTime -= 1;
      if (this.maxTime > 0) {
        this.hidetimervalue = false;
        this.StartTimer();
      }

      else {
        this.hidetimervalue = true;
        //this.ResetOtpInSession();
      }

    }, 1000);


  }
  //save otp in session
  SaveOtpInSession(OTP) {
    return this.http.get("/api/SaveOtpInSession/" + OTP)
      .subscribe(
        (data: any) => {
          //
        },
        error => {
          console.log("Error", error);
        }
      );
  };
  //Enter otp form submit
  //otpError: boolean = false;
  EnterOtpSubmit() {
    this.loading = true;
    return this.http.get("/api/VerifyOtpInSession/" + this.o.otpvalue.value)
      .subscribe(
        (data: any) => {
          this.loading = false;
          if (data === true) {
            this.EnterOtpSection = false;
            this.EnterNewPasswordSection = true;
            this.EnterNewPasswordForm.reset();
            //this.error = "";
          } else {
            this.toaster.error("Please enter valid OTP..!");
           // this.otpError = true;
          }
        },
        error => {
          this.loading = false;
          console.log("Error", error);
        }
      );
  };
  //submit new password
  EnterNewPasswordSubmit() {

    this.submitted = true;
    if (this.EnterNewPasswordForm.invalid) {
      this.loading = false;
      return;
    }
    else {
      this.loading = true;
      var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'No-Auth': 'True' });
      return this.http.get(environment.API_URL + "users/ResetPassword/" + this.r.username.value + "/" + this.rp.newpassword.value, { headers: reqHeader })
        .subscribe(
          (data: any) => {
            this.loading = false;
            this.submitted = false;
            this.EnterNewPasswordSection = false;
            this.loginSection = true;
            if (data !== null) {
              console.log(data);
              //this.error = "";
              
            } else {
              this.toaster.error("Please check entered details..!");
            }
          },
          error => {
            this.loading = false;
            this.submitted = false;
            //this.error = "";
          }
        );
    }
  };


}
