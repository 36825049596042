import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { BnNgIdleService } from 'bn-ng-idle';
import { AuthenticationService } from './services/authentication.service';
import { CommonModule } from '@angular/common';
import { NgIf, NgClass } from '@angular/common';
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [NgIf, NgClass,RouterOutlet, CommonModule],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  cssUrl: any;
  obj: any = {};
  constructor(
    private bnIdle: BnNgIdleService,
    public sanitizer: DomSanitizer,
    private authenticationService: AuthenticationService) {
    this.authenticationService.GetWhitelabelStylesheet().subscribe((data: any) => {
      console.log(data);
      this.obj = data;
      this.cssUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.obj.contentResultValue);
      localStorage.setItem("FnolFavIcon", this.obj.url);
    }, error => {
      console.log(error);
    });
    this.authenticationService.GetAppSettings();

    //this.bnIdle.startWatching(900).subscribe((res) => {
    //  if (res) {
    //    this.authenticationService.logout();
    //  }
    //})
  }




}
