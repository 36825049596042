import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpRequest } from '@angular/common/http';
import { NgForm, FormsModule, FormBuilder, FormGroup, Validators, FormControl, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, formatDate } from '@angular/common';
import { environment } from '../../environments/environment';
import { Title, DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { NgIf, NgClass } from '@angular/common';
import { SearchFilterPipe } from '../customPipes/searchPipe/search-filter.pipe';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ng2-tooltip-directive';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
@Component({
  selector: 'app-pendingrequests',
  standalone: true,
  imports: [NgIf, NgClass, CommonModule, ReactiveFormsModule, FormsModule, SearchFilterPipe
    , BsDatepickerModule, TooltipModule, AutocompleteLibModule, NgMultiSelectDropDownModule],
  templateUrl: './pendingrequests.component.html',
  styleUrls: ['./pendingrequests.component.css']
})
export class PendingrequestsComponent implements OnInit {
  createReqForm: FormGroup;
  selectedLevel: string = "1";
  RequestList = [];
  customdate: boolean = false;
  maxDate = new Date();
  customdate_;
  dateformat = 'dd-MM-yyyy';
  locale = 'en-US';
  reqStatus: string = "all";
  UserName: string = localStorage.getItem('UserName');
  reqStatusDropdown: any;
  reqStatusString: string;
  searchRequests: string;
  preloader: boolean = false;
  RequestType: any;
  createReqSec: boolean = false;
  reqData: any;
  reqsubmitted: boolean = false;
  submitManReq: boolean = false;
  viewReqSection: boolean = false;
  summarySec: boolean = false;
  selectedRequest: any;
  reqreceipient: string;
  driverlist: any;
  managerlist: any;

  reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('token')
  });
  eventStatusList: Array<Object> = [
    { id: 1, name: "Today" },
    { id: 2, name: "Yesterday" },
    { id: 3, name: "Current Week" },
    { id: 4, name: "Last Week" },
    { id: 5, name: "Custom" }
  ];
  constructor(private http: HttpClient,
    private formBuilder: FormBuilder,
    private titleService: Title,
    private toaster: ToastrService,
    private sanitizer: DomSanitizer) {
    this.titleService.setTitle("Driver Risk-Resolva | Requests");
  }

  ngOnInit() {

    this.getRequestList(this.selectedLevel, 1);
    this.createReqForm = this.formBuilder.group({
      ReqType: ['', Validators.required],
      ReqSubject: ['', Validators.required],
      reqreceipient: [''],
      ReceipientId: ['', Validators.required],
      ReqMessage: ['']
    });
  }
  //get default pending requests
  getRequestList(date, status) {
    this.preloader = true;
    let reqstatustemp = "";
    if (this.reqStatus == "all") {
      reqstatustemp = this.reqStatusString;
    }
    else {
      reqstatustemp = this.reqStatus;
    }
    return this.http.get(environment.API_URL + "GetRequestsList/" + this.UserName + "/" + JSON.parse(status) + "/" + date + "/" + reqstatustemp, { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          this.reqData = data;
          this.RequestList = data.RequestList;
          console.log(data);
          this.preloader = false;
          if (status == "1") {
            this.reqStatusDropdown = data.FIlters.RequestStatus;
            this.RequestType = data.FIlters.RequestType;
            this.reqStatusString = "";
            this.reqStatusDropdown.forEach(item => {
              this.reqStatusString += item.Id + ",";
            })
          }
        },
        error => {
          console.log("Error", error);
          this.preloader = false;
        }
      );
  };

  //get date change data
  changeDateFun(date_) {
    if (date_ != "5") {
      this.customdate = false;
      this.getRequestList(this.selectedLevel, 2);
    }
    else {
      this.customdate = true;
    }
  }
  //change request status 
  changeReqStatus(status) {
    if (this.reqStatus == "all") {
      this.reqStatusString = "";
      this.reqStatusDropdown.forEach(item => {
        this.reqStatusString += item.Id + ",";
      })
    }
    if (this.selectedLevel != "5") {
      this.getRequestList(this.selectedLevel, 2);
    } else {
      this.getRequestList(this.customdate_, 2);
    }
  };
  //custom date functionality
  customDateFun(date) {
    var from = date[0];
    var to = date[1];
    var date1 = new Date(from);
    var date2 = new Date(to);
    var diffTime = Math.abs(date2.getTime() - date1.getTime());
    var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (Date.parse(formatDate(to.toLocaleDateString(), 'yyyy-MM-dd', this.locale)) <= (Date.parse(formatDate(from.toLocaleDateString(), 'yyyy-MM-dd', this.locale)))) {
      this.toaster.error("End date should be greater than Start date");
    } else if (diffDays <= 7) {
      this.customdate_ = formatDate(from.toLocaleDateString(), 'yyyy-MM-dd', this.locale) + "," + formatDate(to.toLocaleDateString(), 'yyyy-MM-dd', this.locale);
      this.getRequestList(this.customdate_, 2);
    } else {
      this.toaster.error("Date range more than 7 days can't supported, Please select valid date range.");
    }
  };
  //create request
  get f() { return this.createReqForm.controls; }
  createManReq() {
    this.reqreceipient = "1";
    this.viewReqSection = false;
    this.createReqSec = true;
    this.selectedid = null;
    //getting manager names
    return this.http.get(environment.API_URL + "ManagerNames/" + localStorage.getItem('FleetId'), { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          this.managerlist = data;
        },
        error => {
          console.log("Error", error);
        }
      ),//getting Driver names
      this.http.get(environment.API_URL + "Devices/DriverList/" + localStorage.getItem('UserName') + "/", { headers: this.reqHeader })
        .subscribe(
          (data: any) => {
            this.driverlist = data;
          },
          error => {
            console.log("Error", error);
          }
        );
  }
  //submit create request form
  onSubmitReqForm() {
    debugger;
    // stop here if form is invalid
    this.reqsubmitted = true;
    if (this.createReqForm.invalid) {
      return;
    }
    this.submitManReq = true;
    let obj: any = {};
    obj.RequestTypeId = JSON.parse(this.f.ReqType.value);
    obj.ReceipientTypeId = JSON.parse(this.f.reqreceipient.value);
    obj.ReceipientId = this.f.ReceipientId.value;
    obj.Subject = this.f.ReqSubject.value;
    obj.Notes = this.f.ReqMessage.value;
    obj.RequestedBy = localStorage.getItem('userid');
    obj.UserName = localStorage.getItem('UserName');

    return this.http.post(environment.API_URL + "AddEvidenceRequest/", obj, { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          this.toaster.success("Request added successfully..!");
          this.createReqForm.reset();
          this.submitManReq = false;
          this.createReqSec = false;
          if (this.selectedLevel != "5") {
            this.getRequestList(this.selectedLevel, 2);
          } else {
            this.getRequestList(this.customdate_, 2);
          }
        },
        error => {
          console.log("Error", error);
        }
      )
  };
  //close create req
  closeCreateReq() {
    this.createReqSec = this.viewReqSection = false;
    this.createReqForm.reset();
  };
  //view request
  selectedid: string;
  selectedReqFileType: string;
  selectedReqFileUrl: any;
  EvidenceFileListCount: number;
  selectedRequestMediaLength: any;
  thumbSelectedReqFileUrl: any;
  viewRequest(req) {
    debugger;
    this.EvidenceFileListCount = 0;
    if (req.Media !== null) {
      if (req.Media.length != 0) {
        req.Media.forEach(x => {
          x.isChecked = false;
        });
      }
    }
    this.createReqSec = false;
    this.viewReqSection = true;
    this.selectedRequest = req;
    if (req.Media !== null) {
      if (req.Media[0].blobrequestfileUrl !== null && req.Status == 3) {
        this.thumbSelectedReqFileUrl = req.Media[0].blobrequestfileUrl;
        this.selectedReqFileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(req.Media[0].blobrequestfileUrl);
      }
      this.selectedRequestMediaLength = req.Media.length;
    }
    
    this.selectedid = req.Id;
    this.selectedReqFileType = req.RequestTypeId;
    this.EvidenceFileList = [];
    this.EvidenceFileString = "";
  };
  //view request file
  selectedReqFileData: any;
  ViewIncidentReqFile(req, item) {
    this.selectedReqFileData = req;
    this.selectedReqFileType = req.RequestTypeId;
    //this.selectedReqFileUrl = item.blobrequestfileUrl;
    this.thumbSelectedReqFileUrl = item.blobrequestfileUrl;
    this.selectedReqFileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(item.blobrequestfileUrl);
  };
  //complete task
  CompleteRequest() {
    this.http.get(environment.API_URL + "CompleteRequest/" + this.selectedRequest.Id + "/", { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          this.toaster.success('Successfully completed your task.');
          this.EvidenceFileList = [];
          this.EvidenceFileString = "";
          if (this.selectedLevel != "5") {
            this.getRequestList(this.selectedLevel, 2);
          } else {
            this.getRequestList(this.customdate_, 2);
          }
        },
        error => {
          this.toaster.error('Failed to complete your task.');
        }
      );
  };
  //submit view request
  EvidenceFileString: string;
  RequestMoveToEvidence() {
    if (this.EvidenceFileList.length == 0) {
      this.toaster.error("Please select files to move.");
      return false;
    } else {
      this.EvidenceFileList.forEach(x => {
        this.EvidenceFileString += x + ",";
      });
    }
    this.EvidenceFileString = this.EvidenceFileString.slice(0, -1);
    this.http.get(environment.API_URL + "RequestMoveToEvidence/" + this.selectedRequest.Id , { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          this.toaster.success('Successfully moved to evidence.');
          this.viewReqSection = false;
          this.EvidenceFileList = [];
          this.EvidenceFileString = "";
          if (this.selectedLevel != "5") {
            this.getRequestList(this.selectedLevel, 2);
          } else {
            this.getRequestList(this.customdate_, 2);
          }
        },
        error => {
          this.toaster.error('Failed while moving to evidence.');
          console.log("Error", error);
        }
      );
  };
  //add files to evidence list
  EvidenceFileList: Array<string> = [];
  AddToEvidenceFun(item) {
    if (item.isChecked === true) {
      this.EvidenceFileListCount++;
      this.EvidenceFileList.push(item.filename);
    } else {
      this.EvidenceFileList.splice(this.EvidenceFileList.indexOf(item.filename), 1);
      this.EvidenceFileListCount--;
    }
  };

}
