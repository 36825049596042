import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('currentUser')) {
      // logged in so return true
      return true;
    }

    //var curURL = getResolvedUrl(route);

    //if (curURL.endsWith('WhitelabelStylesheet',0)){
    //  return false;
    //}
    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }


}

//function getResolvedUrl(route: ActivatedRouteSnapshot): string {
//  return route.pathFromRoot
//    .map(v => v.url.map(segment => segment.toString()).join('/'))
//    .join('/');
//}
