import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NavMenuComponent } from '../nav-menu/nav-menu.component';


@Component({
  selector: 'app-home',
  standalone: true,
  imports: [RouterOutlet, CommonModule, NavMenuComponent],
  templateUrl: './home.component.html',
})
export class HomeComponent {
  constructor() { // initiate it in your component constructor
    
  }
}
