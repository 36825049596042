<div class="pre-loader" *ngIf="preloader==true">
  <div class="load-icon">
    <div class="ring-load">
      <div class="circle"></div>
      <!--<img src="assets/images/Favicon.svg" />-->
      <div class="pageload-icon"></div>
    </div>
  </div>
</div>


<div class="live Events_new ox_hidden">
  <div class="row m-0">
    <div class="col-lg-3 col-md-4 col-md-4 col-12 p-0 eventSection card-scroll_main " [ngClass]="{'mobile_left_none':!colp,'':colp}">
      <div class="lb-head">
        <div class="clearfix">
          <!--<h6 class="no-m pull-left">Incidents</h6>-->
          <h6 class="no-m pull-left">Events</h6>
          <i class="far fa-filter pull-right" data-toggle="modal" data-target="#filters" *ngIf="!ActivatedFilter" tooltip="Filters" placement="left" [hide-delay]="0"></i>
          <i class="fas fa-filter pull-right" data-toggle="modal" data-target="#filters" *ngIf="ActivatedFilter" tooltip="Filters" placement="left" [hide-delay]="0"></i>

          <!--<i class="far fa-sync-alt pull-right pr-3" (click)="changeStatus()" *ngIf="dateStatus!=CustomDateFunctionalityValue" tooltip="Refresh" placement="left" [hide-delay]="0"></i>-->
          <!--<i class="far fa-sync-alt pull-right pr-3" (click)="customDateFun(customdatefilter)" *ngIf="dateStatus==CustomDateFunctionalityValue" tooltip="Refresh" placement="left" [hide-delay]="0"></i>-->
        </div>
        <div class="row mt-2">
          <div class="col-6 pr-05">
            <select [(ngModel)]="dateStatus" (change)="changeStatus()" class="form-control" [hidden]="MyListIncidents==1||MyListIncidents==3">
              <option *ngFor="let item of eventStatusList; index as i" value="{{item.id}}">{{item.name}}</option>
            </select>
            <!--<input type="text" class="form-control" value="All" disabled="disabled" [hidden]="MyListIncidents==2">-->
          </div>
          <div class="col-6 pl-0">
            <select class="form-control" [(ngModel)]="status" (change)="changeStatus()">
              <option *ngFor="let item of eventCategory; index as i" value="{{item.id}}">{{item.name}}</option>
            </select>
          </div>
        </div>
        <div class="row mt-2">
          <div class="form-group col-5 pr-05">
            <div class="checkspan">
              <span class="checkbox">
                <label class="d-flex">
                  <input type="checkbox" class="form-control checkbox_coustom" [(ngModel)]="AllVehicles" (change)="AllVehicleschange(AllVehicles)">
                  <span class="checkbox-material"><span class="check"></span></span>All Vehicles
                </label>
              </span>
            </div>
          </div>
          <!--<select class="form-control" [(ngModel)]="selectedVehicle" (change)="MultiVehicles(selectedVehicle)">
            <option [ngValue]="null" selected>Select Vehicle</option>
            <option *ngFor="let item of vehiclesByFleet" [ngValue]="item">{{item.VRN}}</option>
          </select>
          <div class="panel-group  all_vehicles_panel_main" *ngIf="selectedVehicleList.length!=0">
            <div class="panel panel-default all_vehicles_panel">
              <div class="panel-body append_value all_vehicles_ddl card-scroll-min" id="items">
                <p *ngFor="let item of selectedVehicleList">{{item.VRN}}<i class="fas fa-times pull-right" (click)="removeSelectedVrn(item.DeviceIdentifier)"></i></p>
              </div>
            </div>
          </div>-->
          <div class="form-group col-7 pl-0" [ngClass]="{'d-none': AllVehicles}">
            <ng-multiselect-dropdown [placeholder]="'Select Vehicle'"
                                     [data]="vehiclesByFleet"
                                     [(ngModel)]="selectedVehicle"
                                     [settings]="dropdownSettings"
                                     (onSelect)="onItemSelect($event)"
                                     (onDeSelect)="onItemDeSelect($event)">
            </ng-multiselect-dropdown>
          </div>


        </div>
        <div class="row">
          <div class="col-6 pr-05" *ngIf="customdate==true">
            <input type="text" style="font-size:11px!important"
                   placeholder="Date"
                   class="form-control"
                   [(ngModel)]="eventsCustomDate"
                   [maxDate]="maxDate"
                   bsDaterangepicker
                   [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY',isAnimated: true,showWeekNumbers:false}"
                   (bsValueChange)="customDateFun($event)">
          </div>
          <div class="" [ngClass]="{'col-12':customdate==false,'col-6':customdate==true,'pl-0':customdate==true}">
            <input type="text" class="col form-control" placeholder="Search" [(ngModel)]="searchEvents">

          </div>
          <!--<div class="col-5 pl-0">
            <input type="text"
                   placeholder="To"
                   class="form-control"
                   [(ngModel)]="eventstoDate"
                   [maxDate]="maxDate"
                   bsDatepicker
                   [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY',isAnimated: true,showWeekNumbers:false}">
          </div>-->
          <!--<div class="col-2 col-centered-all">
            <i class="fad fa-forward" (click)="customDateFun(eventsCustomDate)" tooltip="Search" placement="left"></i>
          </div>-->
          <!--<div class="col-5">
              <label class="checkbox">
                <input type="checkbox" [(ngModel)]="MyListIncidents" (change)="mylistCategory(MyListIncidents)">
                <span>Assigned list</span>
              </label>
          </div>-->
        </div>


      </div>
      <!--<div class="card-scroll">-->
      <div class="card-scroll">
        <div *ngIf="loadingmsg==true">
          <h4 class="loading text-center">Loading</h4>
        </div>
        <div *ngIf="loadingmsg==false">
          <div *ngIf="IncidentsList?.length==0&&MyListIncidents==2" class="tab-alert no-events">
            <h5>The vehicle has <span style="color:red"> no events</span> recorded for selected date</h5>
          </div>
          <div *ngIf="IncidentsList?.length!=0">
            <div>
              <div *ngFor="let fleets of IncidentsList |  paginate:{
          itemsPerPage:itemsPerPage,
          currentPage:page,
          totalItems:totalLength };index as i">
                <div class="fleetNamesSection">
                  <a class="row no-m" data-toggle="collapse" href="#coll{{i+1}}" aria-expanded="true">
                    <div class="col-12 p-0">
                      <h6>{{fleets.FleetName}}</h6>
                      <div class="col-xs-1 pull-right">
                        <span class="collapsed"><i class="far fa-angle-right"></i></span>
                        <span class="expanded"><i class="far fa-angle-down"></i></span>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="collapse show carder" id="coll{{i+1}}">
                  <div *ngFor="let incident of fleets.EventsList | searchFilter: { EventDisplayName:searchEvents, Speed: searchEvents,Classification:searchEvents,VRN:searchEvents};">
                    <div class="brick">
                      <div class="row no-m brick-header" [ngClass]="{'brick-active':selectedid==incident.EventId}">
                        <h6 class="col-11 no-p">
                          <i class="far fa-user" *ngIf="incident.CreatedBy!='System'"></i> {{incident.VRN}}
                          <span [ngClass]="{'highSeverityText':incident.Severity==3,'mediumSeverityText':incident.Severity==2,'lowSeverityText':incident.Severity==1}"> : {{incident.EventDisplayName}}</span>
                        </h6>
                        <a class="text-right col-1 no-p" (click)="colp=!colp;viewEventMap(incident)">
                          <i class="far fa-arrow-alt-circle-right view_icon" tooltip="View" placement="left"></i>
                        </a>
                      </div>
                      <div class="brick-body">
                        <div class="time-count">
                          <span><i class="far fa-clock"></i> {{incident.EventDate}} | <span title="Review Time" *ngIf="incident.ReviewTimeStr!= null"> <i class="far fa-clock"></i> {{incident.ReviewTimeStr}} | </span> <i class="far fa-tachometer"></i> {{incident.Speed}}</span>
                          <span class="pull-right"> {{incident.Classification}}</span>
                        </div>

                        <div class="text-limit">
                          <i class="far fa-map-marker-alt"></i>
                          <span class="address-field">
                            {{incident.Address}}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <pagination-controls class="events_pagination" [maxSize]="5"  previousLabel="" nextLabel="" (pageChange)="pageChanged(page = $event)"></pagination-controls>
          </div>
        </div>
      </div>
      <div class="livefoot row no-gutters" style="position: absolute; bottom: 0px; width: 100%;">
        <div class="col-8" *ngIf="MyListIncidents==1">Max limit 10</div>
        <label class="checkbox col-8" *ngIf="MyListIncidents==2">
          <input type="checkbox" [checked]="defaultAssigned" [(ngModel)]="defaultAssigned">
          <span>Auto assign</span>
        </label>
        <!--<div class="col-4 d-flex justify-content-end">
          <a class="btn btn-success pull-right" (click)="createManInc()">Incident</a>
        </div>-->
      </div>
    </div>


    <div class="col-lg-9 col-md-8 col-sm-8 col-12 p-0 lap2 Events_new_right" *ngIf="colp">
      <div class="card-scroll-full bod_left">
        <!--summary section-->
        <div class="summarySection">
          <div class="row no-m">
            <div class="lb-head-single col-12">
              <!--<h6>Incidents statistics</h6>-->
              <h6>Event Statistics</h6>
            </div>
            <div class="col-lg-8 col-md-6 col-sm-6 col-6">
              <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-12 col-12 no-p" *ngFor="let fleets of FleetListCount;index as i">
                  <div class="panel p-3">
                    <div class="fad fa-bells panelbg panel-blue"> </div>
                    <div class="panel-info">
                      <h4 class="panel-count">{{fleets?.TotalPageCount}}</h4>
                      <h4 class="panel-title">{{fleets?.FleetName}}</h4>
                    </div>
                  </div>
                </div>
                <!--<div class="col-6 no-p">
                  <div class="panel p-3">
                    <div class="fad fa-user-check panelbg panel-blue"> </div>
                    <div class="panel-info">
                      <h4 class="panel-count">{{AssignedEvents?.length}}</h4>
                      <h4 class="panel-title">My List</h4>
                    </div>
                  </div>
                </div>-->
              </div>
            </div>



            <div class="col-lg-4 col-md-6 col-sm-6 col-6 no-p audit_main_div">
              <div class="audit_header">
                <div class="row">
                  <div class="col-lg-5 col-md-12 col-sm-12 col-12">
                    <div class="panel p-3 audit_txt">
                      <h5 class="Heading_h5">Audit</h5>
                    </div>
                  </div>
                  <div class="col-lg-7 col-md-12 col-sm-12 col-12 pl-0">
                    <div class="audit_search">
                      <input type="text" class="form-control" placeholder="Search" [(ngModel)]="searchEvents1">
                    </div>
                  </div>
                </div>
              </div>

              <div class="audit_body card-scroll pb-5" style="height: calc(100vh - 92px);">
                <div class="audit_info_txt">
                  <span class="audit_user mr-1 audit_div_txt font_8 color_gray">The last 7 days of event updates and audit of compliances changes are shown below.</span>
                </div>
                <div *ngIf="eventAuditList.length==0">
                  <div class="no-auudit">
                    <div><i class="far fa-list-alt"></i> </div>
                    <h5><span style="color:red"> No Audit</span> Messages</h5>
                  </div>
                </div>
                <div>
                  <div class="panel p-3 audit_inner_msg" *ngFor="let eventAudit of eventAuditList | searchFilter: { EventDateStr:searchEvents1, VRN: searchEvents1, CreatedDateStr:searchEvents1, EventTypeName:searchEvents1, UserName: searchEvents1} | paginate:{
         id:'pagination1', itemsPerPage:itemsPerPage,
          currentPage:auditPage,
          totalItems:eventAuditList.length}">
                    <div>
                      <div class="audit_tnr_div mb-04">
                        <span class="audit_vrn mr-1 audit_div_txt font_11"><i class="far fa-car"></i> {{eventAudit.VRN}} </span>
                        <span class="mt-03 float-right audit_div_txt font_9 color_gray"> <i class="far fa-clock font_8"></i> {{eventAudit.CreatedDateStr}} </span>
                      </div>
                      <div class="audit_eventtype_div mb-04">
                        <span class="mr-1 audit_div_txt font_11"><i class="fal fa-bells"></i> {{eventAudit.EventTypeName}} </span>
                        <span class="mt-02 mr-1 audit_div_txt float-right font_9 color_gray"><i class="font_8 far fa-calendar-edit"></i> {{eventAudit.EventDateStr}} </span>
                      </div>
                      <div class="audit_des_div mb-04 txt_overflow">
                        <span class="font_weight4 mr-1 audit_div_txt font_11"> <i class="far fa-comment-lines"></i> {{eventAudit.Description}} </span>
                      </div>
                      <div class="audit_user_div">
                        <span class="audit_user mr-1 audit_div_txt font_8 color_gray"> <i class="font_weight4 fas fa-user font_11"></i> {{eventAudit.UserName}} </span>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="livefoot row no-gutters" style="position: absolute; bottom: 0px; width: 100%;">
                <pagination-controls class="h22 m-auto" id="pagination1" (pageChange)="auditPage = $event"></pagination-controls>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
    <div class="col-lg-9 col-md-8 col-sm-8 col-12 pl-0 pr-0" *ngIf="!colp">
      <app-view-map [viewMap]="videoEventId" [eventStatusValue]="statusData" [eventDateValue]="dateData" (getEventVideoData)="getEventVideo($event)" (calpVal)="viewValueMap($event)">
      </app-view-map>
      <!--<router-outlet></router-outlet>-->
    </div>

  </div>
</div>

<div class="modal fade modal-custom" id="analyze" tabindex="-1" role="dialog" *ngIf="incidensection==true&&viewManInc==false&&summarysection==false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title">Analyze</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body event_analyze_section" style="height: calc(100vh - 80px);">
        <div class="row no-m">
          <div class="col-6 no-p card-scroll-single" style="height: calc(100vh - 100px);">
            <div class="col-12 pl-0">
              <ul class="nav nav-tabs col-8 p-0" role="tablist">
                <li class="nav-item active"><a class="nav-link active" data-toggle="tab" href="#analyzevideos">Videos</a></li>
                <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#analyzeimages">Images</a></li>
              </ul>
              <div class="tab-content">
                <div class="tab-pane fade in active show" id="analyzevideos" role="tabpanel">
                  <div class="" *ngIf="selectedIncidentObj.VideoEnabled==true">
                    <div id="carousel" class="slide">
                      <div class="carousel-inner">
                        <div class="tab-alert no-videos" *ngIf="incidentServercommands?.length==0">
                          <h5>No videos are available.</h5>
                        </div>
                        <div *ngIf="incidentServercommands?.length==1">
                          <div class="item active" *ngIf="incidentServercommands[0].Status=='ReceivedFromDevice'">
                            <video controls [src]="incidentServercommands[0].Media[0].BlobUrl" class="media-responsive">
                              Your browser does not support this type of video.
                            </video>
                          </div>
                          <span *ngIf="incidentServercommands[0].Status=='Raised'">Command Sent</span>
                          <span *ngIf="incidentServercommands[0].Status=='SentToDevice'">InProgress</span>
                          <span *ngIf="incidentServercommands[0].Status=='Signalled'">Signalled</span>
                          <span *ngIf="incidentServercommands[0].Status=='PostProcessingReboot'">PostProcessing Reboot</span>
                          <span *ngIf="incidentServercommands[0].Status=='ProcessingError'">
                            <span class="badge badge-danger" style="font-size:11px;">
                              <strong>Declined !</strong>
                            </span>
                          </span>
                        </div>
                        <div *ngIf="incidentServercommands?.length>1">
                          <div class="row no-gutters">
                            <select [(ngModel)]="selectedCommand" (change)="changeCommandAnalyze(selectedCommand)" class="form-control col-3 d-flex justify-content-end">
                              <option *ngFor="let item of incidentServercommands; index as i" value="{{item.CommandData}}">{{item.Media[0]?.ChannelName}}</option>
                            </select>
                          </div>
                          <div *ngIf="selectServercmd.Status=='ReceivedFromDevice'">
                            <div *ngFor="let item of selectServercmd.Media; index as i">
                              <video controls id="video" src="{{item.BlobUrl}}" class="media-responsive"></video>
                            </div>
                          </div>
                          <span *ngIf="selectServercmd.Status=='Raised'">Command Sent</span>
                          <span *ngIf="selectServercmd.Status=='SentToDevice'">InProgress</span>
                          <span *ngIf="selectServercmd.Status=='Signalled'">Signalled</span>
                          <span *ngIf="selectServercmd.Status=='PostProcessingReboot'">PostProcessing Reboot</span>
                          <div class="col-centered-all pt-5" *ngIf="selectServercmd.Status=='ProcessingError'">
                            <span class="badge badge-danger" style="font-size:11px;">
                              <strong>Declined !</strong>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="analyzeimages" role="tabpanel">
                  <div class="" *ngIf="selectedIncidentObj.SnapshotEnabled==true">
                    <div [hidden]="incidetData?.imageList?.length!=0">
                      <div class="tab-alert no-images">
                        <h6>No images are available.</h6>
                      </div>
                    </div>
                    <div id="carousel" class="slide" [hidden]="incidetData?.imageList?.length==0">
                      <div class="carousel-inner">
                        <div class="item active">
                          <img [src]="selectedImage" class="media-responsive">
                          <button class="btn-left" (click)="prevImageClick()" [ngClass]="{'no_event':imageindex==0}"><i class="fal fa-chevron-circle-left"></i></button>
                          <button class="btn-right" (click)="nextImageClick()" [ngClass]="{'no_event':(imageindex+1)==incidetData?.imageList?.length}"><i class="fal fa-chevron-circle-right"></i></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 no-p">
              <h5>Gsensor Graph</h5>
              <div class="row">
                <div class="col-3">
                  <label class="checkbox">
                    <input type="checkbox" [(ngModel)]="Gsensor_x" (change)="gsensorgraph(Gsensor_x,Gsensor_y,Gsensor_z)">
                    <span>GX</span>
                  </label>
                </div>
                <div class="col-3">
                  <label class="checkbox">
                    <input type="checkbox" [(ngModel)]="Gsensor_y" (change)="gsensorgraph(Gsensor_x,Gsensor_y,Gsensor_z)">
                    <span>GY</span>
                  </label>
                </div>
                <div class="col-3">
                  <label class="checkbox">
                    <input type="checkbox" [(ngModel)]="Gsensor_z" (change)="gsensorgraph(Gsensor_x,Gsensor_y,Gsensor_z)">
                    <span>GZ</span>
                  </label>
                </div>
              </div>
              <google-chart #chart
                            [type]="gtype"
                            [data]="gdata"
                            [columns]="gcolumns"
                            [options]="goptions">
              </google-chart>
            </div>
            <div class="col-12 no-p">
              <h5>Speed Graph</h5>
              <google-chart #chart
                            [type]="stype"
                            [data]="sdata"
                            [columns]="scolumns"
                            [options]="soptions">
              </google-chart>
            </div>
          </div>
          <div class="col-6 no-p">
            <h5>Map</h5>
            <!--<google-map [streetViewControl]="false" (mouseOver)="onMouseOver(infoWindow, $event)" (mouseOut)="onMouseOut(infoWindow, $event)">
              <map-marker *ngFor="let item of AnalyzeListPoints;let i = index;" [position]="{lat: item.Latitude, lng: item.Longitude}"
                          [options]="{zoom: 18, scrollwheel: true, mapTypeId: 'hybrid',bounds: AnalyzeBounds}" [iconUrl]="analyzeicon">

              </map-marker>
            </google-map>-->

            <google-map [zoom]="18" (mouseOver)="onMouseOver(infoWindow, $event)" (mouseOut)="onMouseOut(infoWindow, $event)">
              <map-marker #markerElem="mapMarker"
                          *ngFor="let item of AnalyzeListPoints;let i = index;"
                          [position]="{lat: item?.Latitude, lng: item?.Longitude}"
                          [options]="{mapTypeId: 'hybrid',  scrollwheel: true, icon: analyzeicon}"
                          (mapClick)="openInfoWindow(markerElem)">

              </map-marker>
            </google-map>
          </div>
        </div>
      </div>
      <div class="livefoot">
        <a href="" class="btn btn-charcol pull-right mr-1" data-dismiss="modal" aria-label="Close">Close</a>
      </div>
    </div>
  </div>
</div>
<div class="modal fade modal-custom-sm" id="filters" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog width_60p_events_filters" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title">Filters</h6>
        <span *ngIf="ActivatedFilter" (click)="ClearFilters()" class="clear-filter cler_filters">
          <i class="fal fa-times"></i> Clear Filters
        </span>
        <a class="ml-05 close close_f" data-dismiss="modal" aria-label="Close" tooltip="Close" placement="left" [hide-delay]="0" [z-index]="2000" #IncidentFileterCloseButton>
          <!--<span aria-hidden="true" tooltip="Close" placement="left" [z-index]="1000" [hide-delay]="0">&times;</span>-->
          <span aria-hidden="true">&times;</span>
        </a>
      </div>
      <div class="modal-body card-scroll filters">
        <!--<div class="col-4 no-p">
          <ng-multiselect-dropdown [placeholder]="'Select Fleet'"
                                   [data]="filters.FleetListFilter"
                                   class="form-control"
                                   [(ngModel)]="selectedFleets"
                                   [settings]="dropdownSettings"
                                   (onSelect)="onItemSelect($event)"
                                   (onSelectAll)="onSelectAll($event)">
          </ng-multiselect-dropdown>
        </div>-->
        <!--<h5>Record Type</h5>
        <div *ngFor="let record of filters.EventRecordType">
          <label class="checkbox">
            <input type="checkbox" [(ngModel)]="record.status">
            <span>{{record.Name}}</span>
          </label>
        </div>-->
        <h5>Severity</h5>
        <div class="row">
          <div class="col-3" *ngFor="let severity of filters.SeverityFilter">
            <label class="checkbox">
              <input type="checkbox" [(ngModel)]="severity.FilterChecked">
              <span>{{severity.Name}}</span>
            </label>
          </div>
        </div>

        <h5>Classifications</h5>
        <div class="row">
          <div class="col-3" *ngFor="let clsf of filters?.clasificationList">
            <label class="checkbox">
              <input type="checkbox" [(ngModel)]="clsf.FilterChecked">
              <span>{{clsf.Classification}}</span>
            </label>
          </div>
        </div>

        <h5>Event Groups</h5>
        <div class="row">
          <div class="col-12" *ngFor="let group of filters?.EventTypesFilter;index as i">
            <div class="fleetNamesSection border-0 mb-1 filter_fleetNamesSection">
              <label class="checkbox">
                <input type="checkbox" [(ngModel)]="group.AllChecked" (change)="EventTypesCheckAll(group.EventTypeGroupId)" [disabled]="group.status==false">
                <span>{{group.EventTypeGroupName}}</span>
              </label>
              <span class="material-switch mb-1 float-right">
                <input id="switch-lable{{i+1}}" name="someSwitchOption001" type="checkbox" [(ngModel)]="group.FilterChecked" (change)="EventGroupTypeChange(group)" />
                <label for="switch-lable{{i+1}}" class="bg-primary"></label>
              </span>
            </div>
            <div class="row no-m">
              <div class="py-2 col-4" *ngFor="let type of group.Types">
                <label class="checkbox">
                  <input type="checkbox" [(ngModel)]="type.FilterChecked" [disabled]="group.FilterChecked==false" (change)="optionToggled(group)">
                  <span>{{type.Name}}</span>
                </label>
                <span class="float-right">
                  <i class="far fa-bell" *ngIf="type?.ClassificationTypeId==1" tooltip="Event" placement="left" [hide-delay]="0"></i>
                  <i class="far fa-info-circle" *ngIf="type?.ClassificationTypeId==2" tooltip="Info" placement="left" [hide-delay]="0"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="livefoot">
        <div class="col no-p">
          <button class="btn btn-success pull-right" [disabled]="filterload" (click)="changeStatus()" *ngIf="dateStatus!=CustomDateFunctionalityValue">Apply</button>
          <button class="btn btn-success pull-right" [disabled]="filterload" (click)="customDateFun(customdatefilter)" *ngIf="dateStatus==CustomDateFunctionalityValue">Apply</button>
          <img src="../../assets/images/ButtonProcessing.gif" *ngIf="filterload" />
        </div>
      </div>
    </div>
  </div>
</div>
<!--event audit window-->
<div class="modal right fade" id="EventAuditactions" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog no-m" style="width:27%">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title">Event Review</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" #CloseEventAuditModal>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <form name="form" (ngSubmit)="eventAuditSubmit()" [formGroup]="eventAuditForm">
        <div class="modal-body card-scroll">
          <div class="form-group">
            <label for="auditClassification">Status</label>
            <select class="form-control" name="AuditClassification" formControlName="AuditClassification" [ngClass]="{'disabled':audited }" required (change)="getAuditClassification()">
              <option value="">Select</option>
              <option *ngFor="let item of Classifications; index as i" value="{{item.Id}}">{{item.Classification}}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="FaultCategory">Fault Category</label>
            <select class="form-control" name="FaultCategory" formControlName="FaultCategory" (change)="getFaultReasons()" [ngClass]="{'disabled':audited||auditfundisable}">
              <option value="">Select</option>
              <option *ngFor="let item of FaultCategories" value="{{item.Id}}">{{item.Category}}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="FaultReasons">Fault Reasons</label>
            <select class="form-control" name="FaultReasons" formControlName="FaultReasons" [ngClass]="{'disabled':audited||auditfundisable}">
              <option value="">Select</option>
              <option *ngFor="let item of FaultReasons" value="{{item.Id}}">{{item.Reason}}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="Notes">Notes</label>
            <textarea rows="3" cols="30" class="form-control" placeholder="Type here" name="Notes" formControlName="Notes" [ngClass]="{'disabled':audited }" required></textarea>
          </div>
        </div>
        <div class="livefoot row no-gutters">
          <div class="col">
            <button class="btn btn-success float-right mr-3" [disabled]="!eventAuditForm.valid||incidetData?.eventReviewStatus!=null">Submit</button>
          </div>
        </div>
      </form>
    </div><!-- modal-content -->
  </div><!-- modal-dialog -->
</div>
<!--request on incident-->
<div class="modal right fade" id="RequestOnIncident" tabindex="-1" role="dialog" aria-hidden="true" style="z-index: 1;">
  <div class="modal-dialog no-m" style="width:27%">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title">Add Request</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" #CloseRequestModal>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form name="createreqform" (ngSubmit)="onSubmitReqForm()" [formGroup]="createReqForm" class="">
        <div class="modal-body card-scroll">
          <div class="form-group">
            <label>Request type</label>
            <select name="ReqType" class="form-control" formControlName="ReqType" required>
              <option *ngFor="let item of evidencetypes; index as i" value="{{item.Id}}">{{item.Name}}</option>
            </select>
            <div *ngIf="reqsubmitted && rf.ReqType.errors" class="invalid-feedback">
              <div *ngIf="rf.ReqType.errors.required">Request type is required</div>
            </div>
          </div>
          <div style="display:flex;">
            <div class="mb-1 ddl_request">
              <input id="Manager" type="radio" value="1" formControlName="reqreceipient" (change)="requestonItemChange(1)" required>
              <label for="Manager" class="">Manager</label>
            </div>
            <div class="mb-1 ml-1 ddl_request">
              <input id="Driver" type="radio" value="2" formControlName="reqreceipient" (change)="requestonItemChange(2)" required>
              <label for="Driver">Driver</label>
            </div>
            <!--<div class="mb-1">
              <input type="radio" name="reqreceipient" [(ngModel)]="reqreceipient" [checked]="reqreceipient =='1'" (change)="requestonItemChange(1)" />
              <label>Manager</label>
            </div>
            <div class="mb-1 ml-1">
              <input type="radio" name="reqreceipient" [(ngModel)]="reqreceipient" (change)="requestonItemChange(2)" />
              <label>Driver</label>
            </div>-->
          </div>
          <div class="form-group" *ngIf="reqreceipient =='2'">
            <label>Select Driver</label>
            <!--<select class="form-control" name="ReceipientId" [(ngModel)]="ReceipientId" formControlName="ReceipientId">
              <option *ngFor="let item of driverlist; index as i" value="{{item.Id}}">{{item.Name}}-{{item.PhoneNumber}}-{{item.FleetName}}</option>
            </select>-->
            <div class="ng-autocomplete col-12 no-p">
              <ng-autocomplete [data]="AllDrivers"
                               [searchKeyword]="'Name'"
                               [itemTemplate]="itemTemplate1"
                               [notFoundTemplate]="notFoundTemplate1"
                               (selected)='selectReceipient($event)'
                               [formControlName]="ReceipientId">
              </ng-autocomplete>

              <ng-template #itemTemplate1 let-item>
                <a [innerHTML]="item.Name"></a>
              </ng-template>

              <ng-template #notFoundTemplate1 let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
            </div>
          </div>
          <div class="form-group" *ngIf="reqreceipient=='1'">
            <label>Select Manager</label>

            <!--<select class="form-control" name="ReceipientId" [(ngModel)]="ReceipientId" formControlName="ReceipientId">
              <option *ngFor="let item of managerlist; index as i" value="{{item.Id}}">{{item.FirstName}} {{item.LastName}}</option>
            </select>-->
            <div class="ng-autocomplete col-12 no-p">
              <ng-autocomplete [data]="AllManagers"
                               [searchKeyword]="'FirstName'"
                               [itemTemplate]="itemTemplate2"
                               [notFoundTemplate]="notFoundTemplate2"
                               (selected)='selectReceipient($event)'
                               [formControlName]="ReceipientId">
              </ng-autocomplete>

              <ng-template #itemTemplate2 let-item>
                <a [innerHTML]="item.FirstName"></a>
              </ng-template>

              <ng-template #notFoundTemplate2 let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
            </div>
          </div>
          <label for="RequestDueDate">Due Date</label>

          <div class="form-group row mx-0">
            <input type="date" class="form-control col-4 mr-1" name="RequestDueDate" formControlName="RequestDueDate" />

            <select class="form-control col" name="Request_hours" formControlName="Request_hours">
              <option value="">HH</option>
              <option value="00">00</option>
              <option value="01">01</option>
              <option value="02">02</option>
              <option value="03">03</option>
              <option value="04">04</option>
              <option value="05">05</option>
              <option value="06">06</option>
              <option value="07">07</option>
              <option value="08">08</option>
              <option value="09">09</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16">16</option>
              <option value="17">17</option>
              <option value="18">18</option>
              <option value="19">19</option>
              <option value="20">20</option>
              <option value="21">21</option>
              <option value="22">22</option>
              <option value="23">23</option>
            </select> <span class="timeColon">:</span>
            <select class="form-control col" name="Request_min" formControlName="Request_min">
              <option value="">MM</option>
              <option value="00">00</option>
              <option value="01">01</option>
              <option value="02">02</option>
              <option value="03">03</option>
              <option value="04">04</option>
              <option value="05">05</option>
              <option value="06">06</option>
              <option value="07">07</option>
              <option value="08">08</option>
              <option value="09">09</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16">16</option>
              <option value="17">17</option>
              <option value="18">18</option>
              <option value="19">19</option>
              <option value="20">20</option>
              <option value="21">21</option>
              <option value="22">22</option>
              <option value="23">23</option>
              <option value="24">24</option>
              <option value="25">25</option>
              <option value="26">26</option>
              <option value="27">27</option>
              <option value="28">28</option>
              <option value="29">29</option>
              <option value="30">30</option>
              <option value="31">31</option>
              <option value="32">32</option>
              <option value="33">33</option>
              <option value="34">34</option>
              <option value="35">35</option>
              <option value="36">36</option>
              <option value="37">37</option>
              <option value="38">38</option>
              <option value="39">39</option>
              <option value="40">40</option>
              <option value="41">41</option>
              <option value="42">42</option>
              <option value="43">43</option>
              <option value="44">44</option>
              <option value="45">45</option>
              <option value="46">46</option>
              <option value="47">47</option>
              <option value="48">48</option>
              <option value="49">49</option>
              <option value="50">50</option>
              <option value="51">51</option>
              <option value="52">52</option>
              <option value="53">53</option>
              <option value="54">54</option>
              <option value="55">55</option>
              <option value="56">56</option>
              <option value="57">57</option>
              <option value="58">58</option>
              <option value="59">59</option>
            </select> <span class="timeColon">:</span>
            <select class="form-control col" name="Request_sec" formControlName="Request_sec">
              <option value="">SS</option>
              <option value="00">00</option>
              <option value="01">01</option>
              <option value="02">02</option>
              <option value="03">03</option>
              <option value="04">04</option>
              <option value="05">05</option>
              <option value="06">06</option>
              <option value="07">07</option>
              <option value="08">08</option>
              <option value="09">09</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16">16</option>
              <option value="17">17</option>
              <option value="18">18</option>
              <option value="19">19</option>
              <option value="20">20</option>
              <option value="21">21</option>
              <option value="22">22</option>
              <option value="23">23</option>
              <option value="24">24</option>
              <option value="25">25</option>
              <option value="26">26</option>
              <option value="27">27</option>
              <option value="28">28</option>
              <option value="29">29</option>
              <option value="30">30</option>
              <option value="31">31</option>
              <option value="32">32</option>
              <option value="33">33</option>
              <option value="34">34</option>
              <option value="35">35</option>
              <option value="36">36</option>
              <option value="37">37</option>
              <option value="38">38</option>
              <option value="39">39</option>
              <option value="40">40</option>
              <option value="41">41</option>
              <option value="42">42</option>
              <option value="43">43</option>
              <option value="44">44</option>
              <option value="45">45</option>
              <option value="46">46</option>
              <option value="47">47</option>
              <option value="48">48</option>
              <option value="49">49</option>
              <option value="50">50</option>
              <option value="51">51</option>
              <option value="52">52</option>
              <option value="53">53</option>
              <option value="54">54</option>
              <option value="55">55</option>
              <option value="56">56</option>
              <option value="57">57</option>
              <option value="58">58</option>
              <option value="59">59</option>
            </select>
          </div>
          <div class="form-group">
            <label>Subject</label>
            <input class="form-control" placeholder="" type="text" name="ReqSubject" formControlName="ReqSubject" required>
            <div *ngIf="reqsubmitted && rf.ReqSubject.errors" class="invalid-feedback">
              <div *ngIf="rf.ReqSubject.errors.required">Subject is required</div>
            </div>
          </div>
          <div class="form-group">
            <label>Notes</label>
            <textarea rows="4" cols="40" class="form-control" placeholder="Type here" name="ReqMessage" formControlName="ReqMessage"></textarea>
          </div>
        </div>
        <div class="livefoot row no-gutters">
          <div class="col">
            <button type="submit" class="btn btn-success float-right mr-3" [disabled]="!createReqForm.valid">Submit</button>
            <img src="../../assets/images/ButtonProcessing.gif" *ngIf="submitManReq" class="float-right" />
          </div>
        </div>
      </form>
    </div><!-- modal-content -->
  </div><!-- modal-dialog -->
</div>

<div class="modal right fade" id="addAdditionalInfo" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog no-m" style="width:27%">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title">Add addtional details</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" #AddAdditionalDetailsModal>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <form name="form" (ngSubmit)="additionalEvidenceData()" [formGroup]="additionalEvidenceForm">
        <div class="modal-body card-scroll">
          <div class="form-group">
            <label for="FleetId">Evidence Type</label>
            <select class="form-control" name="EvidenceRequestId" (change)="evidenceReqType($event.target.value)" formControlName="EvidenceRequestId" [ngClass]="{ 'is-invalid': evidencesubmitted && e.EvidenceRequestId.errors }" required>
              <option *ngFor="let item of evidencetypes; index as i" value="{{item.Id}}">{{item.Name}}</option>
            </select>
            <div *ngIf="evidencesubmitted && e.EvidenceRequestId.errors" class="invalid-feedback">
              <div *ngIf="e.EvidenceRequestId.errors.required">Evidence Type is required</div>
            </div>
          </div>
          <div class="form-group">
            <label for="Name">Name</label>
            <input type="text" class="form-control" name="Name" formControlName="Name" [ngClass]="{ 'is-invalid': evidencesubmitted && e.Name.errors }" required />
            <div *ngIf="evidencesubmitted && e.Name.errors" class="invalid-feedback">
              <div *ngIf="e.Name.errors.required">Name is required</div>
            </div>
          </div>
          <div class="form-group">
            <label for="ContactNumber">Contact Number</label>
            <input type="text" class="form-control" name="ContactNumber" formControlName="ContactNumber" [ngClass]="{ 'is-invalid': evidencesubmitted && e.ContactNumber.errors }" required />
            <div *ngIf="evidencesubmitted && e.ContactNumber.errors" class="invalid-feedback">
              <div *ngIf="e.ContactNumber.errors.required">ContactNumber is required</div>
            </div>
          </div>
          <div class="form-group">
            <label for="ContactNumber">EvidenceFile</label>
            <input type="file" class="form-control" name="EvidenceFile" formControlName="EvidenceFile" (change)="handleFileInput($event.target.files)" accept="{{evidenceFileAccept}}" [ngClass]="{ 'is-invalid': evidencesubmitted && e.EvidenceFile.errors }" required />
            <div *ngIf="evidencesubmitted && e.EvidenceFile.errors" class="invalid-feedback">
              <div *ngIf="e.EvidenceFile.errors.required">File is required</div>
            </div>
            <img src="../../assets/images/ButtonProcessing.gif" *ngIf="additionalfile" />
          </div>
          <div class="form-group">
            <label for="Address">Notes</label>
            <textarea rows="3" cols="30" class="form-control" placeholder="Type here" name="Address" formControlName="Address" [ngClass]="{ 'is-invalid': evidencesubmitted && e.Address.errors }" required></textarea>
            <div *ngIf="evidencesubmitted && e.Address.errors" class="invalid-feedback">
              <div *ngIf="e.Address.errors.required">Address is required</div>
            </div>
          </div>
        </div>
        <div class="livefoot row no-gutters">
          <div class="col">
            <button class="btn btn-success float-right" [disabled]="!additionalEvidenceForm.valid||additionalfile==true">Add</button>
            <img src="../../assets/images/ButtonProcessing.gif" *ngIf="submitEvidenceLoader" />
          </div>
        </div>
      </form>
    </div><!-- modal-content -->
  </div><!-- modal-dialog -->
</div>

<div class="modal right fade" id="AddNotes" tabindex="-1" role="dialog" aria-hidden="true" style="z-index: 1;">
  <div class="modal-dialog no-m" style="width:27%">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title">Add Notes</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" #AddNotesModal>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form class="form addNotesForm" (ngSubmit)="saveAddNotes()" [formGroup]="saveAddNotesForm">
        <div class="modal-body card-scroll" style="height:calc(100vh - 80px);">
          <div class="form-group">
            <label>Type of Note</label>
            <select class="form-control" formControlName="Type" required>
              <option value="1">Call</option>
              <option value="2">Email</option>
            </select>
          </div>
          <div class="form-group">
            <label for="uname1">Title</label>
            <input type="text" class="form-control" formControlName="Title" required>
          </div>
          <div class="form-group">
            <label>Description</label>
            <textarea class="form-control" rows="6" id="text" formControlName="Description" required></textarea>
          </div>
        </div>
        <div class="livefoot row no-gutters">
          <div class="col">
            <button class="btn btn-success float-right" [disabled]="!saveAddNotesForm.valid">Save</button>
            <img src="../../assets/images/ButtonProcessing.gif" *ngIf="saveAddNotesLoader" class="float-right" />
          </div>
        </div>
      </form>
      <!--<div class="livefoot">
        <button type="button" class="btn btn-success float-right" id="btnLogin">Save</button>
        <a href="" class="btn btn-charcol pull-right mr-1" data-dismiss="modal" aria-label="Close">Close</a>
      </div>-->
    </div>
  </div>
</div>
<!--Existing  video multichannel view -->
<div class="modal fade modal-custom max" id="evidencevideo" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog video_modal_popup  no-m" style="width: 94%;" [ngClass]="{'video_max_popup': maxClass,'video_modal_popup' :!maxClass}">
    <div class="modal-content">
      <div class="modal-header">
        <div class="video_multichannel_tabs">
          <h6 class="modal-title">Video channel section</h6>
          <span class="pull-right mr-1 ml-2" *ngIf="DeviceChannelCount>1&&selectedevidencevideo?.length>1">
            <i class="fa fa-square mr-1" (click)="VideoChannelView(1)"></i>
            <i class="fa fa-th-large" (click)="VideoChannelView(2)"></i>
            <!--<i class="fal fa-th-large" *ngIf="DeviceChannelCount>=4" (click)="VideoChannelView(4)"></i>
            <i class="fal fa-th-large" *ngIf="DeviceChannelCount>=9" (click)="VideoChannelView(9)"></i>-->
          </span>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div class="modal-body p-05">
        <div *ngIf="DeviceChannelCount==1">
          <div class="video" *ngFor="let item of selectedevidencevideo; index as i">
            <div *ngIf="item?.BlobUrl!=null">
              <video #videoPlayer (dblclick)="fullScreen()" (ended)="vidEnded()" [src]="item?.BlobUrl" (drop)="drop('drop1')" class="media-responsive">
                Browser not supported
              </video>
            </div>
            <div *ngIf="item?.BlobUrl==null" class="bg_black_vd">
              <p>Video still in processing...!</p>
              <video [src]="item?.BlobUrl"></video>
            </div>
          </div>
        </div>
        <div *ngIf="DeviceChannelCount>1">
          <!--<div *ngIf="selectedevidencevideo.length!=0">
            <a class="btn btn-success mr-1" (click)="video_play()" *ngIf="PlayButton">Play</a>
            <a class="btn btn-success mr-1" (click)="video_pause()" *ngIf="!PlayButton">Pause</a>
            <span *ngIf="SkipButtons">
              <a class="btn btn-success mr-1" (click)="videoBackward(5)"><i class="far fa-backward"></i></a>
              <a class="btn btn-success mr-1" (click)="videoForward(5)"><i class="far fa-forward"></i></a>
              <a class="btn btn-success"><i class="fas fa-expand"></i></a>
            </span>
          </div>-->
          <div class=" row no-m">
            <div class="col-md-10 no-p card-scroll">
              <div class="row no-m">
                <div class="video p-03" *ngIf="(channelcountchange==1||channelcountchange==2)" [ngClass]="{'col-md-6':(channelcountchange==2)&&selectedevidencevideo?.length<=4,'col-md-12':channelcountchange==1,'col-md-4':(channelcountchange==2)&&selectedevidencevideo?.length>=6}">
                  <div *ngIf="selectedevidencevideo[0]?.BlobUrl!=null" class="text-center">
                    <label id="drop1" class="channel_txt">{{selectedevidencevideo[0]?.ChannelName}}</label>
                    <video [src]="selectedevidencevideo[0]?.BlobUrl" id="video1" (dblclick)="fullScreen()" (ended)="vidEnded()" (drop)="drop('drop1')" (dragover)="allowDrop('drop1','video1')" class="media-responsive video_player">
                      Your browser does not support HTML5 video.
                    </video>
                  </div>
                  <div *ngIf="selectedevidencevideo[0]?.BlobUrl==null" class="bg_black_vd modal_video_bigbox" style="width: 100%; height: 98.5%;">
                    <p class="channel_txt bg_black">{{selectedevidencevideo[0]?.ChannelName}}</p>
                    <div class="videoprogress">
                      <i class="fa fa-video"></i>
                      <p class="channel_txt">Videos Still Pending</p>
                    </div>
                    <video [src]="selectedevidencevideo[0]?.BlobUrl" type="video/mp4" style="width:100%; height:100%;"></video>
                  </div>
                </div>
                <div class="video p-03 text-center" *ngIf="(channelcountchange==2)&&selectedevidencevideo?.length>=2" [ngClass]="{'col-md-4':(channelcountchange==2)&&selectedevidencevideo?.length>=6,'col-md-6':(channelcountchange==2)&&selectedevidencevideo?.length<=4}">
                  <div *ngIf="selectedevidencevideo[1]?.BlobUrl!=null">
                    <label id="drop2" class="channel_txt">{{selectedevidencevideo[1]?.ChannelName}}</label>
                    <video [src]="selectedevidencevideo[1]?.BlobUrl" id="video2" (dblclick)="fullScreen()" (ended)="vidEnded()" (drop)="drop('drop2')" (dragover)="allowDrop('drop2','video2')" class="media-responsive video_player">
                      Your browser does not support HTML5 video.
                    </video>
                  </div>
                  <div *ngIf="selectedevidencevideo[1]?.BlobUrl==null" class="bg_black_vd modal_video_bigbox" style="width: 100%; height: 98.5%;">
                    <p class="channel_txt bg_black">{{selectedevidencevideo[1]?.ChannelName}}</p>
                    <div class="videoprogress">
                      <i class="fa fa-video"></i>
                      <p class="channel_txt">Videos Still Pending</p>
                    </div>
                    <video [src]="selectedevidencevideo[1]?.BlobUrl" type="video/mp4" style="width:100%; height:100%;"></video>
                  </div>
                </div>
                <div class="video p-03 text-center" *ngIf="(channelcountchange==2)&&selectedevidencevideo?.length>=3" [ngClass]="{'col-md-4':(channelcountchange==2)&&selectedevidencevideo?.length>=6,'col-md-6':(channelcountchange==2)&&selectedevidencevideo?.length<=4}">
                  <div *ngIf="selectedevidencevideo[2]?.BlobUrl!=null">
                    <label id="drop3" class="channel_txt">{{selectedevidencevideo[2]?.ChannelName}}</label>
                    <video [src]="selectedevidencevideo[2]?.BlobUrl" id="video3" (dblclick)="fullScreen()" (ended)="vidEnded()" (drop)="drop('drop3')" (dragover)="allowDrop('drop3','video3')" class="media-responsive video_player">
                      Your browser does not support HTML5 video.
                    </video>
                  </div>
                  <div *ngIf="selectedevidencevideo[2]?.BlobUrl==null" class="bg_black_vd modal_video_bigbox" style="width: 100%; height: 98.5%;">
                    <p class="channel_txt bg_black">{{selectedevidencevideo[2]?.ChannelName}}</p>
                    <div class="videoprogress">
                      <i class="fa fa-video"></i>
                      <p class="channel_txt">Videos Still Pending</p>
                    </div>
                    <video [src]="selectedevidencevideo[2]?.BlobUrl" type="video/mp4" style="width:100%; height:100%;"></video>
                  </div>
                </div>
                <div class="video p-03  text-center" *ngIf="(channelcountchange==2)&&selectedevidencevideo?.length>=4" [ngClass]="{'col-md-4':(channelcountchange==2)&&selectedevidencevideo?.length>=6, 'col-md-6':(channelcountchange==2)&&selectedevidencevideo?.length<=4}">
                  <div *ngIf="selectedevidencevideo[3]?.BlobUrl!=null">
                    <label id="drop4" class="channel_txt">{{selectedevidencevideo[3]?.ChannelName}}</label>
                    <video [src]="selectedevidencevideo[3]?.BlobUrl" id="video4" (dblclick)="fullScreen()" (ended)="vidEnded()" (drop)="drop('drop4')" (dragover)="allowDrop('drop4','video4')" class="media-responsive video_player">
                      Your browser does not support HTML5 video.
                    </video>
                  </div>
                  <div *ngIf="selectedevidencevideo[3]?.BlobUrl==null" class="bg_black_vd modal_video_bigbox" style="width: 100%; height: 98.5%;">
                    <p class="channel_txt bg_black">{{selectedevidencevideo[3]?.ChannelName}}</p>
                    <div class="videoprogress">
                      <i class="fa fa-video"></i>
                      <p class="channel_txt">Videos Still Pending</p>
                    </div>
                    <video [src]="selectedevidencevideo[3]?.BlobUrl" type="video/mp4" style="width:100%; height:100%;"></video>
                  </div>
                </div>
                <div class="video p-03 text-center" *ngIf="(channelcountchange==2) && selectedevidencevideo?.length>=5" [ngClass]="{'col-md-4':(channelcountchange==2)&&selectedevidencevideo?.length>=6}">
                  <div *ngIf="selectedevidencevideo[4]?.BlobUrl!=null">
                    <label id="drop5" class="channel_txt">{{selectedevidencevideo[4]?.ChannelName}}</label>
                    <video [src]="selectedevidencevideo[4]?.BlobUrl" id="video5" (dblclick)="fullScreen()" (ended)="vidEnded()" (drop)="drop('drop5')" (dragover)="allowDrop('drop5','video5')" class="media-responsive video_player">
                      Your browser does not support HTML5 video.
                    </video>
                  </div>
                  <div *ngIf="selectedevidencevideo[4]?.BlobUrl==null" class="bg_black_vd modal_video_bigbox" style="width: 100%; height: 98.5%;">
                    <p class="channel_txt bg_black">{{selectedevidencevideo[4]?.ChannelName}}</p>
                    <div class="videoprogress">
                      <i class="fa fa-video"></i>
                      <p class="channel_txt">Videos Still Pending</p>
                    </div>
                    <video [src]="selectedevidencevideo[4]?.BlobUrl" type="video/mp4" style="width:100%; height:100%;"></video>
                  </div>
                </div>
                <div class="video p-03 text-center" *ngIf="(channelcountchange==2) && selectedevidencevideo?.length>=6" [ngClass]="{'col-md-4':(channelcountchange==2)&&selectedevidencevideo?.length>=6}">
                  <div *ngIf="selectedevidencevideo[5]?.BlobUrl!=null">
                    <label id="drop6" class="channel_txt">{{selectedevidencevideo[5]?.ChannelName}}</label>
                    <video [src]="selectedevidencevideo[5]?.BlobUrl" id="video6" (dblclick)="fullScreen()" (ended)="vidEnded()" (drop)="drop('drop6')" (dragover)="allowDrop('drop6','video6')" class="media-responsive video_player">
                      Your browser does not support HTML5 video.
                    </video>
                  </div>
                  <div *ngIf="selectedevidencevideo[5]?.BlobUrl==null" class="bg_black_vd modal_video_bigbox" style="width: 100%; height: 98.5%;">
                    <p class="channel_txt bg_black">{{selectedevidencevideo[5]?.ChannelName}}</p>
                    <div class="videoprogress">
                      <i class="fa fa-video"></i>
                      <p class="channel_txt">Videos Still Pending</p>
                    </div>
                    <video [src]="selectedevidencevideo[5]?.BlobUrl" type="video/mp4" style="width:100%; height:100%;"></video>
                  </div>
                </div>
                <div class="video p-03 text-center" *ngIf="(channelcountchange==2)&&selectedevidencevideo.length>=7" [ngClass]="{'col-md-4':(channelcountchange==2)&&selectedevidencevideo?.length>=6}">
                  <div *ngIf="selectedevidencevideo[6]?.BlobUrl!=null">
                    <label id="drop7" class="channel_txt">{{selectedevidencevideo[6]?.ChannelName}}</label>
                    <video [src]="selectedevidencevideo[6]?.BlobUrl" id="video7" (dblclick)="fullScreen()" (ended)="vidEnded()" (drop)="drop('drop7')" (dragover)="allowDrop('drop7','video7')" class="media-responsive video_player">
                      Your browser does not support HTML5 video.
                    </video>
                  </div>
                  <div *ngIf="selectedevidencevideo[6]?.BlobUrl==null" class="bg_black_vd modal_video_bigbox" style="width: 100%; height: 98.5%;">
                    <p class="channel_txt bg_black">{{selectedevidencevideo[6]?.ChannelName}}</p>
                    <div class="videoprogress">
                      <i class="fa fa-video"></i>
                      <p class="channel_txt">Videos Still Pending</p>
                    </div>
                    <video [src]="selectedevidencevideo[6]?.BlobUrl" type="video/mp4" style="width:100%; height:100%;"></video>
                  </div>
                </div>
                <div class="video p-03 text-center" *ngIf="(channelcountchange==2)&&selectedevidencevideo?.length>=8" [ngClass]="{'col-md-4':(channelcountchange==2)&&selectedevidencevideo?.length>=6}">
                  <div *ngIf="selectedevidencevideo[7]?.BlobUrl!=null">
                    <label id="drop8" class="channel_txt">{{selectedevidencevideo[7]?.ChannelName}}</label>
                    <video [src]="selectedevidencevideo[7]?.BlobUrl" id="video8" (dblclick)="fullScreen()" (ended)="vidEnded()" (drop)="drop('drop8')" (dragover)="allowDrop('drop8','video8')" class="media-responsive video_player">
                      Your browser does not support HTML5 video.
                    </video>
                  </div>
                  <div *ngIf="selectedevidencevideo[7]?.BlobUrl==null" class="bg_black_vd modal_video_bigbox" style="width: 100%; height: 98.5%;">
                    <p class="channel_txt bg_black">{{selectedevidencevideo[7]?.ChannelName}}</p>
                    <div class="videoprogress">
                      <i class="fa fa-video"></i>
                      <p class="channel_txt">Videos Still Pending</p>
                    </div>
                    <video [src]="selectedevidencevideo[7]?.BlobUrl" type="video/mp4" style="width:100%; height:100%;"></video>
                  </div>
                </div>
                <div class="video p-03 text-center" *ngIf="(channelcountchange==2)&&selectedevidencevideo?.length>=9" [ngClass]="{'col-md-4':(channelcountchange==2)&&selectedevidencevideo?.length>=6}">
                  <div *ngIf="selectedevidencevideo[8]?.BlobUrl!=null">
                    <label id="drop9" class="channel_txt">{{selectedevidencevideo[8]?.ChannelName}}</label>
                    <video [src]="selectedevidencevideo[8]?.BlobUrl" id="video9" (dblclick)="fullScreen()" (ended)="vidEnded()" (drop)="drop('drop9')" (dragover)="allowDrop('drop9','video9')" class="media-responsive video_player">
                      Your browser does not support HTML5 video.
                    </video>
                  </div>
                  <div *ngIf="selectedevidencevideo[8]?.BlobUrl==null" class="bg_black_vd modal_video_bigbox" style="width: 100%; height: 98.5%;">
                    <p class="channel_txt bg_black">{{selectedevidencevideo[8]?.ChannelName}}</p>
                    <div class="videoprogress">
                      <i class="fa fa-video"></i>
                      <p class="channel_txt">Videos Still Pending</p>
                    </div>
                    <video [src]="selectedevidencevideo[8]?.BlobUrl" type="video/mp4" style="width:100%; height:100%;"></video>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2 pr-0 pt-05 pl-05 pb-05 videoside_section card-scroll">
              <div id="thumbcarousel" class="carousel slide" data-interval="false">
                <div class="carousel-inner">
                  <div class="item active" *ngFor="let item of selectedevidencevideo; index as i">
                    <div *ngIf="item?.BlobUrl!=null">
                      <div class="thumb  hvr-grow-shadow incident_thumb" id="drag{{i}}">
                        <span class="channelCount channel_txt">{{item?.ChannelName}}</span>
                        <video id="video{{i}}" class="thumb_dimen no-p media-responsive" [src]="item?.BlobUrl" draggable="true" (dragstart)="drag(item)"></video>
                      </div>
                    </div>a
                    <div *ngIf="item?.BlobUrl==null">
                      Ur
                      <div class="thumb  hvr-grow-shadow incident_thumb" id="drag{{i}}">


                        <!--<span class="channelCount channel_txt">{{item?.ChannelName}}</span>-->
                        <p class="channel_txt bg_charcoal">{{item?.ChannelName}}</p>
                        <label class="channel_name2">Videos Still Pending</label>
                        <video id="video{{i}}" class="thumb_dimen no-p media-responsive" [src]="item?.BlobUrl" draggable="true" (dragstart)="drag(item)"></video>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer modal_footer_position">
        <!--<div class="m-auto">
          <div *ngIf="selectedevidencevideo?.length!=0">
            <a class="btn btn-success mr-1" (click)="video_play()" *ngIf="PlayButton">Play</a>
            <a class="btn btn-success mr-1" (click)="video_pause()" *ngIf="!PlayButton">Pause</a>
            <span *ngIf="SkipButtons">
              <a class="btn btn-success  mr-1" (click)="videoBackward(5)"><i class="far fa-backward"></i></a>
              <a class="btn btn-success  mr-1" (click)="videoForward(5)"><i class="far fa-forward"></i></a>
              <a class="btn btn-success"><i class="fas fa-expand"></i></a>
            </span>
          </div>
        </div>-->
        <div class="m-auto">
          <!-- <div *ngIf="selectedevidencevideo?.length!=0">-->
          <a class="btn btn-success mr-1">testing</a>
          <a class="btn btn-success mr-1" (click)="video_play()" *ngIf="PlayButton">Play</a>
          <a class="btn btn-success mr-1" (click)="video_pause()" *ngIf="!PlayButton">Pause</a>
          <a class="btn btn-success mr-1" (click)="videoBackward(5)"><i class="far fa-backward"></i></a>
          <a class="btn btn-success mr-1" (click)="videoForward(5)"><i class="far fa-forward"></i></a>
          <a class="btn btn-success mr-1 ml-05" *ngIf="maxVideo" (click)="maxmize()"><i class="fas fa-expand"></i></a>
          <a class="btn btn-success mr-1 ml-05" *ngIf="!maxVideo" (click)="minimize()"><i class="fas fa-expand"></i></a>
          <!-- </div>  -->
        </div>
      </div>
    </div>
  </div>
</div>
<!--New video multichannel view -->
<!--<div class="modal fade modal-custom" id="evidencevideo" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog no-m">
    <div class="modal-content">
      <div class="modal-header">
        <div class="col-xs-5">
          <h5 class="modal-title" style="margin-top: -12px;">Overview</h5>
        </div>
        <div class="col-xs-6">
          <div class="row">
            <div class="col-xs-6">
              <h5 class="modal-title" style="margin-top: -12px;margin-right: 20px;">Video channel section</h5>
            </div>
            <div class="col-xs-6">
              <span class="pull-right mr-1" *ngIf="DeviceChannelCount>1&&selectedevidencevideo.length>1">
                <i class="fal fa-square pr-05" (click)="VideoChannelView(1)"></i>
                <i class="fal fa-dice-two pr-05" (click)="VideoChannelView(2)"></i>
                <i class="fal fa-square pr-05"></i>
                <i class="fal fa-dice-two pr-05"></i>
                <i class="fal fa-th-large" *ngIf="DeviceChannelCount>=4" (click)="VideoChannelView(4)"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="col-xs-1">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div class="modal-body">
        <div style="height: calc(105vh - 90px) !important;">
          <div *ngIf="DeviceChannelCount==1">
            <div *ngIf="selectedevidencevideo.length!=0">
              <a class="btn btn-charcol" (click)="video_play()" *ngIf="PlayButton">Play</a>
              <a class="btn btn-charcol" (click)="video_pause()" *ngIf="!PlayButton">Pause</a>
              <span *ngIf="SkipButtons">
                <a class="btn btn-charcol" (click)="videoBackward(5)"><i class="far fa-backward"></i></a>
                <a class="btn btn-charcol" (click)="videoForward(5)"><i class="far fa-forward"></i></a>
              </span>
            </div>
            <div class="video" *ngFor="let item of selectedevidencevideo; index as i">
              <div *ngIf="item.Url!=null">
                <video #videoPlayer (ended)="vidEnded()" [src]="item.BlobUrl" class="media-responsive">
                  Browser not supported
                </video>
              </div>
              <div *ngIf="item.Url==null">
                <p>Video still in processing...!</p>
              </div>
            </div>
          </div>
          <div *ngIf="DeviceChannelCount==2">

            <!--single & double video multichannel view -->
<!--9screen video multichannel view ends-->
<!--</div>
        </div>
      </div>
    </div>
  </div>
</div>-->
<div class="modal fade modal-custom" id="map" tabindex="-1" role="dialog">
  <div class="modal-dialog no-m">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title">Map</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body selectlocation p-0">
        <div class="mx-auto p-1 col-6">
          <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()" [(ngModel)]="searchControl" placeholder="Search Location" #search_ autocomplete="off">
        </div>
        <!--<google-map [zoom]="zoom">
          <map-marker (dragEnd)="markerDragEnd($event)"
                      [position]="{lat: latitude_map, lng: longitude_map}"
                      [options]="{zoom: 18, draggable: true}"></map-marker>

        </google-map>-->

        <google-map [zoom]="zoom">
          <map-marker #markerElem="mapMarker"
                      [position]="{lat: latitude_map, lng: longitude_map}"
                      [options]="{draggable: true}"
                      (dragEnd)="markerDragEnd($event)"
                      (mapClick)="openInfoWindow(markerElem)">

          </map-marker>
        </google-map>
        <div class="row no-gutters d-flex justify-content-center p-2">

          <div class="col-4 text-center">
            <!--<h5>Address: {{address}}</h5>-->
            <h6>Latitude: {{latitude_map}}</h6>
          </div>
          <div class="col-4 text-center">
            <h6>Longitude: {{longitude_map}}</h6>
          </div>
        </div>
      </div>
      <div class="livefoot">
        <a href="" class="btn btn-success pull-right" data-dismiss="modal" aria-label="Close">Save</a>
        <a href="" class="btn btn-charcol pull-right mr-1" data-dismiss="modal" aria-label="Close">Close</a>
      </div>
    </div><!-- modal-content -->
  </div><!-- modal-dialog -->
</div>

<div class="modal modal-custom right fade" id="gallery" tabindex="-1" role="dialog" aria-hidden="true" *ngIf="incidensection==true&&viewManInc==false&&summarysection==false">
  <div class="modal-dialog no-m">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title">Gallery</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body product-slider card-scroll-single">
        <div class="tab" *ngIf="selectedIncidentObj.SnapshotEnabled==true&&selectedIncidentObj.VideoEnabled==true">
          <ul class="nav nav-tabs col-12 justify-content-center incidenttab_ul" role="tablist">
            <li class="nav-item" [ngClass]="{'active':MediaGalleryActiveTab=='images'}">
              <a class="nav-link" data-toggle="tab" href="#images" *ngIf="selectedIncidentObj.SnapshotEnabled==true" [ngClass]="{'active':MediaGalleryActiveTab=='images'}">
                <span><i class="fad fa-images"></i></span>
              </a>
            </li>
            <!--<li class="nav-item" [ngClass]="{'active':MediaGalleryActiveTab=='video'}">
              <a class="nav-link" data-toggle="tab" href="#videos" *ngIf="selectedIncidentObj.VideoEnabled==true" [ngClass]="{'active':MediaGalleryActiveTab=='video'}">
                <span><i class="fad fa-video"></i></span>
              </a>
            </li>-->
          </ul>
          <div class="tab-content no-p">
            <div class="tab-pane fade in" id="images" role="tabpanel" *ngIf="selectedIncidentObj.SnapshotEnabled==true" [ngClass]="{'active':MediaGalleryActiveTab=='images','show':MediaGalleryActiveTab=='images'}">
              <div class="tab-alert no-images" *ngIf="incidetData?.imageList?.length == 0">
                <h6>No images are available.</h6>
              </div>
              <div *ngIf="incidetData?.imageList?.length != 0">
                <div id="carousel" class="slide">
                  <div class="carousel-inner">
                    <div class="item active">
                      <button class="btn no-p no-background" (click)="prevImageClick()" [ngClass]="{'no_event':imageindex==0}"><i class="fal fa-chevron-circle-left"></i></button>
                      <img src="{{selectedImage}}" class="media-responsive">
                      <button class="btn no-p no-background" (click)="nextImageClick()" [ngClass]="{'no_event':(imageindex+1)==incidetData?.imageList?.length}"><i class="fal fa-chevron-circle-right"></i></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="videos" role="tabpanel" *ngIf="selectedIncidentObj.VideoEnabled==true" [ngClass]="{'active':MediaGalleryActiveTab=='video','show':MediaGalleryActiveTab=='video'}">
              <div class="collapse show" id="collapseOne" aria-labelledby="headingOne" data-parent="#accordionExample">
                <div id="carousel" class="slide">
                  <div class="carousel-inner">
                    <div class="tab-alert no-videos" *ngIf="commandVideoList?.length==0">
                      <h5>No videos are available.</h5>
                    </div>
                    <div *ngIf="incidentServercommands?.length==1">
                      <div class="item active" *ngIf="incidentServercommands[0].Status=='ReceivedFromDevice'&&incidentServercommands[0].Media[0].BlobUrl!=null">
                        <div *ngFor="let x of commandVideoList">
                          <video [src]="x.BlobUrl" controls class="media-responsive">
                            Your browser does not support HTML5 video.
                          </video>
                        </div>
                      </div>
                      <div class="item active" *ngIf="incidentServercommands[0].Status=='ReceivedFromDevice'&&incidentServercommands[0].Media[0].BlobUrl==null">
                        <p>Video still in processing...!</p>
                      </div>
                      <span *ngIf="incidentServercommands[0].Status=='Raised'">Command Sent</span>
                      <span *ngIf="incidentServercommands[0].Status=='SentToDevice'">InProgress</span>
                      <span *ngIf="incidentServercommands[0].Status=='Signalled'">Signalled</span>
                      <span *ngIf="incidentServercommands[0].Status=='PostProcessingReboot'">PostProcessing Reboot</span>
                      <div class="col-centered-all" *ngIf="incidentServercommands[0].Status=='ProcessingError'">
                        <span class="badge badge-danger">
                          <strong>Declined !</strong>
                        </span>
                      </div>
                    </div>
                    <div *ngIf="incidentServercommands?.length>1">
                      <div class="row no-m justify-content-center">
                        <select [(ngModel)]="selectedCommand" (change)="changeCommandAnalyze(selectedCommand)" class="form-control col-3 m-2">
                          <option *ngFor="let item of incidentServercommands; index as i" value="{{item.CommandData}}">Video{{i+1}}</option>
                        </select>
                      </div>
                      <div *ngIf="selectServercmd.Status=='ReceivedFromDevice'">
                        <div *ngFor="let item of selectServercmd.Media; index as i">
                          <video controls id="video" src="{{item.BlobUrl}}" class="media-responsive"></video>
                        </div>
                      </div>
                      <span *ngIf="selectServercmd.Status=='Raised'">Command Sent</span>
                      <span *ngIf="selectServercmd.Status=='SentToDevice'">InProgress</span>
                      <span *ngIf="selectServercmd.Status=='Signalled'">Signalled</span>
                      <span *ngIf="selectServercmd.Status=='PostProcessingReboot'">PostProcessing Reboot</span>
                      <div class="col-centered-all" *ngIf="selectServercmd.Status=='ProcessingError'">
                        <span class="badge badge-danger">
                          <strong>Declined !</strong>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--incident request files-->
<div class="modal modal-custom left fade" id="IncidentRequestFileModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog no-m">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title">Overview</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="selectedReqFileType=='1'">
          <video controls id="video" [src]="selectedReqFileUrl" class="media-responsive"></video>
        </div>
        <div *ngIf="selectedReqFileType=='2'">
          <img [src]="selectedReqFileUrl" class="media-responsive">
        </div>
        <div *ngIf="selectedReqFileType=='3'||selectedReqFileType=='4'">
          <iframe [class.thumbnail]="thumbnail" [src]="selectedReqFileUrl" width="100%" height="1000" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
</div>

<!--view request view-->
<div class="modal right fade" id="ViewRequestModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog no-m" style="width: 71.9%!important">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title">View Request</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" #CloseCompleteRequestModal>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body card-scroll">
        <div class="row subject-sec">
          <div class="col-6">
            <h5>You : </h5>
            <p>{{selectedRequestNotes}}</p>
          </div>
          <div class="col-6">
            <h5>Message from {{selectedRequestReceipient}} ({{selectedRequestReceipientId}})</h5>
            <p>{{selectedRequestMessage}}</p>
          </div>
        </div>
        <div id='carousel-custom' class="carousel slide">
          <ol class='carousel-top'>
            <li *ngFor="let item of selectedRequestMedia; index as i">
              <div class="thumbnail" *ngIf="selectedRequestRequestTypeId==1" [ngClass]="{'active':item.blobrequestfileUrl==ActiveSelectedThumNailImage}">
                <span class="check-label">
                  <label class="checkbox">
                    <input type="checkbox" [(ngModel)]="item.isChecked" (ngModelChange)="AddToEvidenceFun(item)">
                    <span>Video{{i+1}}</span>
                  </label>
                </span>
                <div class="thumbnail-item">
                  <video id="" [src]="item.blobrequestfileUrl" class="item-img"></video>
                  <div class="item-info" (click)="SelectedThumbNailImg(item)">
                    <i class="fad fa-expand"></i>
                  </div>
                </div>
              </div>
              <div class="thumbnail" *ngIf="selectedRequestRequestTypeId==2" [ngClass]="{'active':item.blobrequestfileUrl==ActiveSelectedThumNailImage}">
                <span class="check-label">
                  <label class="checkbox">
                    <input type="checkbox" [(ngModel)]="item.isChecked" (ngModelChange)="AddToEvidenceFun(item)">
                    <span>Image{{i+1}}</span>
                  </label>
                </span>
                <div class="thumbnail-item">
                  <img [src]="item.blobrequestfileUrl" class="item-img" />
                  <div class="item-info" (click)="SelectedThumbNailImg(item)">
                    <i class="fad fa-expand"></i>
                  </div>
                </div>
              </div>
              <div class="thumbnail" *ngIf="selectedRequestRequestTypeId==3" [ngClass]="{'active':item.blobrequestfileUrl==ActiveSelectedThumNailImage}">
                <span class="check-label">
                  <label class="checkbox">
                    <input type="checkbox" [(ngModel)]="item.isChecked" (ngModelChange)="AddToEvidenceFun(item)">
                    <span>Document{{i+1}}</span>
                  </label>
                </span>
                <div class="thumbnail-item">
                  <img src="../../assets/images/doc.png" class="item-img contain-fit" />
                  <div class="item-info" (click)="SelectedThumbNailImg(item)">
                    <i class="fad fa-expand"></i>
                  </div>
                </div>
              </div>
              <div class="thumbnail" *ngIf="selectedRequestRequestTypeId==4" [ngClass]="{'active':item.blobrequestfileUrl==ActiveSelectedThumNailImage}">
                <span class="check-label">
                  <label class="checkbox">
                    <input type="checkbox" [(ngModel)]="item.isChecked" (ngModelChange)="AddToEvidenceFun(item)">
                    <span>Other{{i+1}}</span>
                  </label>
                </span>
                <div class="thumbnail-item">
                  <img src="../../assets/images/docs.png" class="item-img contain-fit" />
                  <div class="item-info" (click)="SelectedThumbNailImg(item)">
                    <i class="fad fa-expand"></i>
                  </div>
                </div>
              </div>
            </li>
          </ol>

          <div class='carousel-inner'>
            <div class='carousel-item active'>
              <div *ngIf="selectedRequestRequestTypeId==1">
                <video controls id="video" [src]="SelectedThumbNailImage" class="thumb-img"></video>
              </div>
              <div *ngIf="selectedRequestRequestTypeId==2">
                <img [src]="SelectedThumbNailImage" class="thumb-img">
              </div>
              <div *ngIf="selectedRequestRequestTypeId==3">
                <iframe [src]="SelectedThumbNailImage" height="1000" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen class="thumb-img"></iframe>
              </div>
              <div *ngIf="selectedRequestRequestTypeId==4">
                <iframe [src]="SelectedThumbNailImage" height="1000" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen class="thumb-img"></iframe>
              </div>
            </div>
            <!--<a class="carousel-control-prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>-->
          </div>
        </div>
      </div>

      <div class="livefoot row no-gutters">
        <div class="col">
          <button class="btn btn-warning" (click)="CompleteRequest()">Complete Task</button>
        </div>
        <div class="col-4 d-flex justify-content-end">
          <span class="check_count">Selected <span class="text-success">{{EvidenceFileListCount}}</span> / {{selectedRequestMediaLength}}</span>
          <button class="btn btn-success" (click)="RequestMoveToEvidence()">Move to incident</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!--view request view-->
<div class="modal right fade" id="ViewRequestModal2" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog no-m" style="width: 71.9%!important">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title">View Request</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" #CloseRequestModal2>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body card-scroll">
        <div class="row subject-sec">
          <div class="col-12">
            <h5>You : </h5>
            <p>{{selectedRequestNotes}}</p>
          </div>
        </div>
        <div id='carousel-custom' class="carousel slide">
          <ol class='carousel-top'>
            <li *ngFor="let item of selectedRequestMedia; index as i">
              <div class="thumbnail" *ngIf="selectedRequestRequestTypeId==1" [ngClass]="{'active':item.blobrequestfileUrl==ActiveSelectedThumNailImage}">
                <div class="thumbnail-item">
                  <video id="" [src]="item.blobrequestfileUrl" class="item-img"></video>
                  <div class="item-info" (click)="SelectedThumbNailImg(item)">
                    <i class="fad fa-expand"></i>
                  </div>
                </div>
              </div>
              <div class="thumbnail" *ngIf="selectedRequestRequestTypeId==2" [ngClass]="{'active':item.blobrequestfileUrl==ActiveSelectedThumNailImage}">
                <div class="thumbnail-item">
                  <img [src]="item.blobrequestfileUrl" class="item-img" />
                  <div class="item-info" (click)="SelectedThumbNailImg(item)">
                    <i class="fad fa-expand"></i>
                  </div>
                </div>
              </div>
              <div class="thumbnail" *ngIf="selectedRequestRequestTypeId==3" [ngClass]="{'active':item.blobrequestfileUrl==ActiveSelectedThumNailImage}">
                <div class="thumbnail-item">
                  <img src="../../assets/images/doc.png" class="item-img contain-fit" />
                  <div class="item-info" (click)="SelectedThumbNailImg(item)">
                    <i class="fad fa-expand"></i>
                  </div>
                </div>
              </div>
              <div class="thumbnail" *ngIf="selectedRequestRequestTypeId==4" [ngClass]="{'active':item.blobrequestfileUrl==ActiveSelectedThumNailImage}">
                <div class="thumbnail-item">
                  <img src="../../assets/images/docs.png" class="item-img contain-fit" />
                  <div class="item-info" (click)="SelectedThumbNailImg(item)">
                    <i class="fad fa-expand"></i>
                  </div>
                </div>
              </div>
            </li>
          </ol>

          <div class='carousel-inner'>
            <div class='carousel-item active'>
              <div *ngIf="selectedRequestRequestTypeId==1">
                <video controls id="video" [src]="SelectedThumbNailImage" class="thumb-img"></video>
              </div>
              <div *ngIf="selectedRequestRequestTypeId==2">
                <img [src]="SelectedThumbNailImage" class="thumb-img">
              </div>
              <div *ngIf="selectedRequestRequestTypeId==3">
                <iframe [src]="SelectedThumbNailImage" height="1000" class="thumb-img"></iframe>
              </div>
              <div *ngIf="selectedRequestRequestTypeId==4">
                <iframe [src]="SelectedThumbNailImage" height="1000" class="thumb-img"></iframe>
              </div>
            </div>
            <!--<a class="carousel-control-prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
