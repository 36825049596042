import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpRequest } from '@angular/common/http';
import { NgForm, FormsModule, FormBuilder, FormGroup, Validators, FormControl, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, formatDate } from '@angular/common';
import { environment } from '../../environments/environment';
import { combineAll } from 'rxjs/operators';
import * as $ from 'jquery';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { Title } from "@angular/platform-browser";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { ToastrService } from 'ngx-toastr';
import { NgIf, NgClass } from '@angular/common';
//import pdfmake
import pdfMake from 'src/pdfmake/pdfmake.js';
import pdfFonts from 'src/pdfmake/vfs_fonts.js';
import { style } from '@angular/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ng2-tooltip-directive';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SearchFilterPipe } from '../customPipes/searchPipe/search-filter.pipe';
@Component({
  selector: 'app-reports',
  standalone: true,
  imports: [NgIf, NgClass, CommonModule, ReactiveFormsModule, FormsModule, SearchFilterPipe
    , BsDatepickerModule, TooltipModule, AutocompleteLibModule, NgMultiSelectDropDownModule],
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  viewReportForm: FormGroup;
  ReportsList: any;
  reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('token')
  });
  reportCategories: any;
  activeReportName: string;
  searchReports: string;
  reportStructure: any;
  fleets: any;
  ReportSummarySection: boolean = true;
  ViewReportSection: boolean = false;
  preloader: boolean = true;
  maxDate = new Date();
  StartDate = new Date();
  EndDate = new Date();
  AllFleets: string = "AllFleets";
  AllVehicles: boolean = true;
  MultipleVehicles: boolean = true;
  usertimezone: string = localStorage.getItem('UserTimeZone');
  TimeZones: any;
  vehicles: any;
  reportData: any = [];
  reportName: string;
  reporttype: string;
  selectedVehicle: string = "";
  EventType: string = "AllEvents";
  Severity: string = "AllSeverity";
  EventTypeList = [];
  fleetheathreport:any = [];
  base64Data: any;
  retrieveResonse: any;
  retrievedFile: any;
    base64Response: any;
    renderer: any;
    elementRef: any;
  fleetIdLocal = localStorage.getItem('FleetId');
   
  constructor(private http: HttpClient,
    private formBuilder: FormBuilder,
    private toaster: ToastrService,
    private titleService: Title) {
    this.titleService.setTitle("Driver Risk-Resolva | Reports");
  }

  ngOnInit() {
    this.StartDate.setDate(this.StartDate.getDate() - 1);
    this.viewReportForm = this.formBuilder.group({
      FleetId: [''],
      SelectVehicle: [''],
      AllVehicles: [''],
      StartDate: [''],
      EndDate: [''],
      EventType: [''],
      Severity: [''],
      MultipleVehicles: [''],
      MultipleVehiclesSelect: [''],
      Date: ['', Validators.required],
      TimeZone: ['', Validators.required],
      fleetheathreport:['']
    });
    //get categories
    this.getCategories();
    //console.log(location.origin);

    this.getVehicles(this.fleetIdLocal);
    this.getFleets();
    //get time zones
    return this.http.get(environment.API_URL + "TimeZones", { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          this.TimeZones = data;
        },
        error => {
          console.log("Error", error);
        }
      ),
      //get event types
      this.http.get(environment.API_URL + "GetEventTypes", { headers: this.reqHeader })
        .subscribe(
          (data: any) => {
            this.EventTypeList = data.data;
          },
          error => {
            console.log("Error", error);
          }
        );
  }
  //get reports
  //getReports() {
  //  return this.http.get(environment.API_URL + "GetFnolReports", { headers: this.reqHeader })
  //    .subscribe(
  //      (data: any) => {
  //        this.ReportsList = data;
  //      },
  //      error => {
  //        console.log("Error", error);
  //      }
  //    );
  //};
  //get report categories
  //get reports
  getCategories() {
    return this.http.get(environment.API_URL + "GetFnolCategory/6", { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          this.reportCategories = data;
          this.getReports(data[0].Id, data[0].Name);
        },
        error => {
          console.log("Error", error);
        }
      );
  };
  //view selected report
  selectedid: string;
  getReports(id, name) {
    this.selectedid = id;
    this.ReportSummarySection = true;
    this.ViewReportSection = false;
    this.activeReportName = name;
    return this.http.get(environment.API_URL + "GetReport/" + id, { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          this.ReportsList = data;
        },
        error => {
          console.log("Error", error);
        }
      );
  };
  //view report
  get f() { return this.viewReportForm.controls; }
  viewReport(id, url, name) {
    this.selectedVehicleList = [];
    this.AllVehicles = true;
    this.AllFleets = "AllFleets";
    this.selectedVehicle = "";
    this.StartDate = new Date();
    this.StartDate.setDate(this.StartDate.getDate() - 1);
    this.EndDate = new Date();

    this.EventType == "AllEvents"
    this.Severity == "AllSeverity"

    this.preloader = true;
    this.reporttype = url;
    this.reportName = name;
    return this.http.get(environment.API_URL + "GetFnolReportParameters/" + id, { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          this.reportStructure = data.reportFormStructure;//reportData
          this.ReportSummarySection = false;
          this.ViewReportSection = true;
          this.getReport();
        },
        error => {
          console.log("Error", error);
        }
      );

  };
  //get fleets
  getFleets() {
    return this.http.get(environment.API_URL + "getfleetsbyfleetid/" + localStorage.getItem('UserName') + "/", { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          this.fleets = data;
        },
        error => {
          console.log("Error", error);
        }
      );
  };
  //get vehicles
  getVehicles(fleetIdLocal: any) {
    return this.http.get(environment.API_URL + "devices/" + fleetIdLocal + "/" + localStorage.getItem('UserName') + "/", { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          this.preloader = false;
          this.vehicles = data;
        },
        error => {
          console.log("Error", error);
        }
      );
  };

  onFleetChange(selectedFleetId: any) {
    if (selectedFleetId === "AllFleets") {
      this.getVehicles(this.fleetIdLocal);
    } else {
      this.getVehicles(selectedFleetId);
    }
  }

  //select multi vehicles
  selectedVehicleList: any = [];
  MultiVehicles(item) {
    this.selectedVehicleList.push(item);
  };
  removeSelectedVrn(deviceid) {
    this.selectedVehicleList.forEach((item, i) => {
      if (item.DeviceIdentifier == deviceid) {
        this.selectedVehicleList.splice(i, 1);
        return;
      }
    });
  };


  //get report
  getReport() {
    this.preloader = true;
    this.reportData = [];


    if (this.EndDate <= this.StartDate) {
      this.toaster.error("End date should be greater than the start date");
      this.EndDate = new Date();
      this.preloader = false;
      return;
    }
    //health report
    if (this.reporttype == "/Report/FleetHealth") {
      let obj: any = {};
      obj.FleetId = "";
      obj.DeviceIdentifier = "";

      if (this.AllFleets == "AllFleets") {
        this.fleets.forEach(item => {
          obj.FleetId += item.Id + ",";
        });
      } else {
        obj.FleetId = this.AllFleets;
      }
      if (this.AllVehicles == true) {
        this.vehicles.forEach(item => {
          obj.DeviceIdentifier += item.DeviceIdentifier + ",";
        });
      } else if (this.selectedVehicleList.length != 0) {
        this.selectedVehicleList.forEach(item => {
          obj.DeviceIdentifier += item.DeviceIdentifier + ",";
        });
      } else {
        this.toaster.error("Please select vehicle");
        this.preloader = false;
        return;
      }
      obj.StartDate = formatDate(this.StartDate, 'yyyy-MM-dd', 'en-US');
      obj.EndDate = formatDate(this.EndDate, 'yyyy-MM-dd', 'en-US');
      return this.http.post(environment.API_URL + "Report/FnolfleetHealth", obj, { headers: this.reqHeader })
        .subscribe(
          (data: any) => {
            this.preloader = false;
            this.reportData = data;
          },
          error => {
            this.preloader = false;
            console.log("Error", error);
          }
        );
    }
    //event summary report
    else if (this.reporttype == "/Report/EventSummary") {
      let obj: any = {};
      obj.FleetId = "";
      obj.DeviceIdentifier = "";
      obj.EventType = "";
      obj.EventType = "";
      obj.SeverityType = "";

      if (this.AllFleets == "AllFleets") {
        this.fleets.forEach(item => {
          obj.FleetId += item.Id + ",";
        });
      } else {
        obj.FleetId = this.AllFleets;
      }
      if (this.AllVehicles == true) {
        this.vehicles.forEach(item => {
          obj.DeviceIdentifier += item.DeviceIdentifier + ",";
        });
      } else if (this.selectedVehicleList.length != 0) {
        this.selectedVehicleList.forEach(item => {
          obj.DeviceIdentifier += item.DeviceIdentifier + ",";
        });
      } else {
        this.toaster.error("Please select vehicle");
        this.preloader = false;
        return;
      }
      if (this.EventType == "AllEvents") {
        this.EventTypeList.forEach(item => {
          obj.EventType += item.id + ",";
        });
      } else {
        obj.EventType = this.EventType;
      }
      if (this.Severity == "AllSeverity") {
        obj.SeverityType = "1,2,3";
      } else {
        obj.SeverityType = this.Severity;
      }

      obj.StartDate = formatDate(this.StartDate, 'yyyy-MM-dd', 'en-US');
      obj.EndDate = formatDate(this.EndDate, 'yyyy-MM-dd', 'en-US');

      return this.http.post(environment.API_URL + "Report/FnolEventSummary", obj, { headers: this.reqHeader })
        .subscribe(
          (data: any) => {
            this.preloader = false;
            this.reportData = data;
          },
          error => {
            this.preloader = false;
            console.log("Error", error);
          }
        );
    }
    //event detail report
    else if (this.reporttype == "/Report/Events") {
      let obj: any = {};
      obj.FleetId = "";
      obj.DeviceIdentifier = "";
      obj.EventType = "";
      obj.EventType = "";
      obj.SeverityType = "";

      if (this.AllFleets == "AllFleets") {
        this.fleets.forEach(item => {
          obj.FleetId += item.Id + ",";
        });
      } else {
        obj.FleetId = this.AllFleets;
      }
      if (this.AllVehicles == true) {
        this.vehicles.forEach(item => {
          obj.DeviceIdentifier += item.DeviceIdentifier + ",";
        });
      } else if (this.selectedVehicleList.length != 0) {
        this.selectedVehicleList.forEach(item => {
          obj.DeviceIdentifier += item.DeviceIdentifier + ",";
        });
      } else {
        this.toaster.error("Please select vehicle");
        this.preloader = false;
        return;
      }
      if (this.EventType == "AllEvents") {
        this.EventTypeList.forEach(item => {
          obj.EventType += item.id + ",";
        });
      } else {
        obj.EventType = this.EventType;
      }
      if (this.Severity == "AllSeverity") {
        obj.SeverityType = "1,2,3";
      } else {
        obj.SeverityType = this.Severity;
      }

      obj.StartDate = formatDate(this.StartDate, 'yyyy-MM-dd', 'en-US');
      obj.EndDate = formatDate(this.EndDate, 'yyyy-MM-dd', 'en-US');

      return this.http.post(environment.API_URL + "Report/FnolEvents", obj, { headers: this.reqHeader })
        .subscribe(
          (data: any) => {
            this.preloader = false;
            this.reportData = data;
            console.log(data);
          },
          error => {
            this.preloader = false;
            console.log("Error", error);
          }
        );
    }
    //event emergency report
    else if (this.reporttype == "/Report/EmergencyEvents") {
      let obj: any = {};
      obj.FleetId = "";
      obj.DeviceIdentifier = "";
      obj.EventType = "";

      if (this.AllFleets == "AllFleets") {
        this.fleets.forEach(item => {
          obj.FleetId += item.Id + ",";
        });
      } else {
        obj.FleetId = this.AllFleets;
      }
      if (this.AllVehicles == true) {
        this.vehicles.forEach(item => {
          obj.DeviceIdentifier += item.DeviceIdentifier + ",";
        });
      } else if (this.selectedVehicleList.length != 0) {
        this.selectedVehicleList.forEach(item => {
          obj.DeviceIdentifier += item.DeviceIdentifier + ",";
        });
      } else {
        this.toaster.error("Please select vehicle");
        this.preloader = false;
        return;
      }
      this.EventType = "4";
      this.Severity = "3"
      obj.EventType = this.EventType;
      obj.SeverityType = this.Severity;

      obj.StartDate = formatDate(this.StartDate, 'yyyy-MM-dd', 'en-US');
      obj.EndDate = formatDate(this.EndDate, 'yyyy-MM-dd', 'en-US');

      return this.http.post(environment.API_URL + "Report/FnolEvents", obj, { headers: this.reqHeader })
        .subscribe(
          (data: any) => {
            this.preloader = false;
            this.reportData = data;
          },
          error => {
            this.preloader = false;
            console.log("Error", error);
          }
        );
    }
    //trip score detail report
    else if (this.reporttype == "/Report/TripScoreDetail") {
      let obj: any = {};
      obj.FleetId = "";
      obj.DeviceIdentifier = "";

      if (this.AllFleets == "AllFleets") {
        this.fleets.forEach(item => {
          obj.FleetId += item.Id + ",";
        });
      } else {
        obj.FleetId = this.AllFleets;
      }
      if (this.AllVehicles == true) {
        this.vehicles.forEach(item => {
          obj.DeviceIdentifier += item.DeviceIdentifier + ",";
        });
      } else if (this.selectedVehicleList.length != 0) {
        this.selectedVehicleList.forEach(item => {
          obj.DeviceIdentifier += item.DeviceIdentifier + ",";
        });
      } else {
        this.toaster.error("Please select vehicle");
        this.preloader = false;
        return;
      }
      obj.StartDate = formatDate(this.StartDate, 'yyyy-MM-dd', 'en-US');
      obj.EndDate = formatDate(this.EndDate, 'yyyy-MM-dd', 'en-US');

      return this.http.post(environment.API_URL + "Report/FnolTripScoreDetail", obj, { headers: this.reqHeader })
        .subscribe(
          (data: any) => {
            this.preloader = false;
            this.reportData = data;
          },
          error => {
            this.preloader = false;
            console.log("Error", error);
          }
        );
    }
    //trip score summary report
    else if (this.reporttype == "/Report/TripScore") {
      let obj: any = {};
      obj.FleetId = "";
      obj.DeviceIdentifier = "";

      if (this.AllFleets == "AllFleets") {
        this.fleets.forEach(item => {
          obj.FleetId += item.Id + ",";
        });
      } else {
        obj.FleetId = this.AllFleets;
      }
      if (this.AllVehicles == true) {
        this.vehicles.forEach(item => {
          obj.DeviceIdentifier += item.DeviceIdentifier + ",";
        });
      } else if (this.selectedVehicleList.length != 0) {
        this.selectedVehicleList.forEach(item => {
          obj.DeviceIdentifier += item.DeviceIdentifier + ",";
        });
      } else {
        this.toaster.error("Please select vehicle");
        this.preloader = false;
        return;
      }
      obj.StartDate = formatDate(this.StartDate, 'yyyy-MM-dd', 'en-US');
      obj.EndDate = formatDate(this.EndDate, 'yyyy-MM-dd', 'en-US');

      return this.http.post(environment.API_URL + "Report/TripScoreSummary", obj, { headers: this.reqHeader })
        .subscribe(
          (data: any) => {
            this.preloader = false;
            this.reportData = data;
            console.log(data);
          },
          error => {
            this.preloader = false;
            console.log("Error", error);
          }
        );
    }
    //incident report
    else if (this.reporttype == "/Report/Incidents" || this.reporttype == "/Report/Accidents" || this.reporttype == "/Report/Dismissed" || this.reporttype == "/Report/Intervention" || this.reporttype == "/Report/FalsePositive" || this.reporttype == "/Report/DriverFalult") {
      debugger;
      let obj: any = {};
      obj.FleetId = "";
      obj.DeviceIdentifier = "";
      obj.EventType = "";
      obj.SeverityType = "";

      if (this.AllFleets == "AllFleets") {
        this.fleets.forEach(item => {
          obj.FleetId += item.Id + ",";
        });
      } else {
        obj.FleetId = this.AllFleets;
      }
      if (this.AllVehicles == true) {
        this.vehicles.forEach(item => {
          obj.DeviceIdentifier += item.DeviceIdentifier + ",";
        });
      } else if (this.selectedVehicleList.length != 0) {
        this.selectedVehicleList.forEach(item => {
          obj.DeviceIdentifier += item.DeviceIdentifier + ",";
        });
      } else {
        this.toaster.error("Please select vehicle");
        this.preloader = false;
        return;
      }
      if (this.EventType == "AllEvents") {
        this.EventTypeList.forEach(item => {
          obj.EventType += item.id + ",";
        });
      } else {
        obj.EventType = this.EventType;
      }
      if (this.Severity == "AllSeverity") {
        obj.SeverityType = "1,2,3";
      } else {
        obj.SeverityType = this.Severity;
      }
      if (this.reporttype == "/Report/Incidents") {
        obj.ClassificationId = "1";
      }
      else if (this.reporttype == "/Report/Accidents") {
        obj.ClassificationId = "2";
      } else if (this.reporttype == "/Report/Dismissed") {
        obj.ClassificationId = "3";
      } else if (this.reporttype == "/Report/Intervention") {
        obj.ClassificationId = "4";
      } else if (this.reporttype == "/Report/FalsePositive") {
        obj.FaultCategoryId = "3";
      } else if (this.reporttype == "/Report/DriverFalult") {
        obj.FaultCategoryId = "2";
      }
      obj.StartDate = formatDate(this.StartDate, 'yyyy-MM-dd', 'en-US');
      obj.EndDate = formatDate(this.EndDate, 'yyyy-MM-dd', 'en-US');

      return this.http.post(environment.API_URL + "Report/FnolEventsByTypes", obj, { headers: this.reqHeader })
        .subscribe(
          (data: any) => {
            this.preloader = false;
            this.reportData = data;
            console.log(data);
          },
          error => {
            this.preloader = false;
            console.log("Error", error);
          }
        );
    }
  };
  //download csv report
  downloadCSV() {
    new ngxCsv(this.reportData, 'My Report');
  };

  downloadPDF(pdfTable) {
    let data = document.getElementById('pdfTable');
    html2canvas(data).then(canvas => {
      //let fileWidth = 100;
      //let fileHeight = canvas.height * fileWidth / canvas.width;
      //const FILEURI = canvas.toDataURL('image/png')
      //let PDF = new jsPDF();
      //let position = 0;
      //PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight)
      //PDF.save(this.reportName);
      let doc = new jsPDF();

      doc.addImage(canvas.toDataURL("image/jpeg"), "JPEG", 0, 0,
        doc.internal.pageSize.width, data.offsetHeight / 5);
      doc.save(this.reportName);
    });
  };
  //close view report
  closeViewReport() {
    this.ReportSummarySection = true;
    this.ViewReportSection = false;
  };

  pdfDispatchDataTable(data, reporttype) {
    debugger;
    if (reporttype =="/Report/FleetHealth") {
      var head = ['VRN', 'DeviceIdentifier', 'LastUpdated', 'DeviceName', 'Is GPS', 'Recording', 'Media', 'Address'];
      var body = ['VRN', 'DeviceIdentifier', 'LastUpdatedDate', 'ModelName', 'GpsValid', 'Recording', 'Media', 'Address'];
      this.downloadPDFFile(data, 'sample1', head, body);
    }
    else if (reporttype == "/Report/EventSummary") {
      var head = ['EventType', 'Count'];
      var body = ['EventType', 'Count'];
      this.downloadPDFFile(data, 'sample1', head, body);
    }
    else if (reporttype == "/Report/EmergencyEvents") {
      var head = ['Time','Type', 'Speed', 'Severity','Address'];
      var body = ['EventDateString', 'EventTypeString', 'spd', 'SeverityTypeString','LocationAddress'];
      this.downloadPDFFile(data, 'sample1', head, body);
    }
    else if (reporttype == "/Report/Events") {
      var head = ['Time', 'Type', 'Speed', 'Severity', 'Address'];
      var body = ['EventDateString', 'EventTypeString', 'spd', 'SeverityTypeString', 'LocationAddress'];
      this.downloadPDFFile(data, 'sample1', head, body);
    }
    else if (reporttype == "/Report/TripScoreDetail") {
      var head = ['VRN', 'Start Loc', 'End Loc', 'Travel Time', 'Acc Score', 'Speed Score', 'Break Score', 'Turn Score','Total Score'];
      var body = ['VRN', 'StartLocation', 'EndLocation', 'TripsDurationMin', 'AccelScore', 'SpeedScore', 'BrakeScore', 'TurningScore','TotalTripScore'];
      this.downloadPDFFile(data, 'sample1', head, body);
    }
    else if (reporttype == "/Report/TripScoreDetail") {
      var head = ['VRN', 'Start Loc', 'End Loc', 'Travel Time', 'Acc Score', 'Speed Score', 'Break Score', 'Turn Score', 'Total Score'];
      var body = ['VRN', 'StartLocation', 'EndLocation', 'TripsDurationMin', 'AccelScore', 'SpeedScore', 'BrakeScore', 'TurningScore', 'TotalTripScore'];
      this.downloadPDFFile(data, 'sample1', head, body);
    }
    else if (reporttype == "/Report/TripScore") {
      var head = ['FleetName', 'Driver', 'Travel Time', 'Travel Time', 'Acc Score', 'Speed Score', 'Break Score', 'Turn Score', 'Total Score'];
      var body = ['FleetName', 'DriverName', 'TripsDurationMin', 'TripsDurationMin', 'AccelScore', 'SpeedScore', 'BrakeScore', 'TurningScore', 'TotalTripScore'];
      this.downloadPDFFile(data, 'sample1', head, body);
    }
    else if (reporttype == "/Report/Incidents") {
      var head = ['Date', 'Type', 'Speed', 'FaultCategory', 'FaultReason', 'AuditBy', 'Address'];
      var body = ['EventDateString', 'EventTypeString', 'spd', 'FalutCategory', 'FaultReason', 'AuditBy', 'LocationAddress'];
      this.downloadPDFFile(data, 'sample1', head, body);
    }
    else if (reporttype == "/Report/Accidents") {
      var head = ['Date', 'Type', 'Speed', 'FaultCategory', 'FaultReason', 'AuditBy', 'Address'];
      var body = ['EventDateString', 'EventTypeString', 'spd', 'FalutCategory', 'FaultReason', 'AuditBy', 'LocationAddress'];
      this.downloadPDFFile(data, 'sample1', head, body);
    }
    else if (reporttype == "/Report/Dismissed") {
      var head = ['Date', 'Type', 'Speed',  'AuditBy', 'Address'];
      var body = ['EventDateString', 'EventTypeString', 'spd', 'AuditBy', 'LocationAddress'];
      this.downloadPDFFile(data, 'sample1', head, body);
    }
    else if (reporttype == "/Report/Intervention") {
      var head = ['Date', 'Type', 'Speed', 'FaultCategory', 'FaultReason', 'AuditBy', 'Address'];
      var body = ['EventDateString', 'EventTypeString', 'spd', 'FalutCategory', 'FaultReason', 'AuditBy', 'LocationAddress'];
      this.downloadPDFFile(data, 'sample1', head, body);
    }
    else if (reporttype == "/Report/Intervention") {
      var head = ['Date', 'Type', 'Speed', 'FaultCategory', 'FaultReason', 'AuditBy', 'Address'];
      var body = ['EventDateString', 'EventTypeString', 'spd', 'FalutCategory', 'FaultReason', 'AuditBy', 'LocationAddress'];
      this.downloadPDFFile(data, 'sample1', head, body);
    }
    else if (reporttype == "/Report/FalsePositive") {
      var head = ['Date', 'Type', 'Speed', 'FaultCategory', 'FaultReason', 'AuditBy', 'Address'];
      var body = ['EventDateString', 'EventTypeString', 'spd', 'FalutCategory', 'FaultReason', 'AuditBy', 'LocationAddress'];
      this.downloadPDFFile(data, 'sample1', head, body);
    }
  };

  downloadPDFFile(data, filename, head, body) {
    console.log(data);
   var csvData = this.ConvertToPDF(data, head, body);
    //var finalstring = JSON.stringify();
    var blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    var dwldLink = document.createElement("a");
    var url = URL.createObjectURL(blob);
    var isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  };


  ConvertToPDF(objArray, headerList, dataList) {
    let doc = new jsPDF();
    //employees.forEach(function (employee, i) {
    //  doc.text("First Name: " + employee.firstName +
    //    "Last Name: " + employee.lastName, 20, 10 + (i * 10));
    //});
    //doc.save('Test.pdf');
    var array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    var str = '';
    var row = 'S.No,';
    debugger;
    for (var index in headerList) {
      row += headerList[index] + ',';
    }
    //row = row.slice(0, -1);
    str += row + '\r\n';
    for (var i = 0; i < array.length; i++) {
      var line = (i + 1) + '';
      for (var index1 in dataList) {
        var head = dataList[index1];
        line += ',' + array[i][head];
      }
      str += line + '\r\n';
    }
    return str;
   // str = JSON.stringify(str);
    //let jsonobj = JSON.parse(str);
   // return jsonobj;
    //this.retrieveResonse = objArray;
    //this.base64Data = this.retrieveResonse;
    //this.retrievedFile = "data:application/pdf;base64," + this.base64Data;
    //var testingfile = "sample1";
    ////const jsonString = JSON.stringify(objArray
    ////return btoa(JSON.stringify(jsonString))
    //debugger;
    
  };


}
