import { Component, OnInit, OnChanges, ViewChild, ElementRef, SimpleChanges, Output, Input, NgZone, EventEmitter, HostListener } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpRequest } from '@angular/common/http';
import { NgForm, FormsModule, FormBuilder, FormGroup, Validators, FormControl, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, formatDate, DatePipe } from '@angular/common';
import { environment } from '../../environments/environment';
import { combineAll, debounce } from 'rxjs/operators';
//import { MapsAPILoader } from '@agm/core';
import { Title, DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UUID } from 'angular2-uuid';
import { ConstantsService } from '../services/constants.service';
//import { VideosComponent} from "src/app/videos/videos.component"
//import { EventMapService } from '../services/event-map.service'
declare var jQuery: any;
import { NgIf, NgClass } from '@angular/common';
import { GoogleMapsModule, GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { SearchFilterPipe } from '../customPipes/searchPipe/search-filter.pipe';
import { GoogleChartsModule } from 'angular-google-charts';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ng2-tooltip-directive';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-view-map',
  standalone: true,
  imports: [NgIf, NgClass, CommonModule, ReactiveFormsModule, FormsModule, GoogleMapsModule, SearchFilterPipe, GoogleChartsModule
    , BsDatepickerModule, TooltipModule, AutocompleteLibModule, NgMultiSelectDropDownModule],
  templateUrl: './view-map.component.html',
  styleUrls: ['./view-map.component.css']
})
export class ViewMapComponent implements OnInit {
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow | undefined;

  pipe = new DatePipe('en-US');
  isdissable: boolean = false;
  address1: any;
  today = new Date();
  eventStatusChange: boolean = false;
  currentData: any;
  dd: any;
  dateValue: any;
  statusValue: any;
  mm: any; //January is 0 so need to add 1 to make it 1!
  yyyy: any;
  auditEventData: any;
  maxClass: boolean = false;
  mapOptions: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    zoomControl: false,
    scrollwheel: true,
    disableDoubleClickZoom: true,
  };
  expand: boolean = false;
  maxVideo: boolean = true;
  print: boolean = false;
  colp: boolean = true;
  saveHide: boolean = true;
  readOnlyData: boolean = false;
  ComplianceIncidentTasks = [];
  mapZoom = 16;
  elem;
  toggleClass = 'ft-maximize';
  //  @Output() mapView = new EventEmitter<string>();
  @Input('viewMap') set setViewMap(value) {
    if (value) {
      console.log(value, "ViewMap")
      this.viewIncident(value);
    }
  }
  @Input('eventStatusValue') set setEventStatus(value) {
    if (value) {
      console.log(value, "ViewMap")
      this.statusValue = value;
    }
  }
  @Input('eventDateValue') set setEventDate(value) {
    if (value) {
      console.log(value, "ViewMap")
      this.dateValue = value;
    }
  }
  //@Input('callVal') set setValue(value) {
  //  if (value) {
  //    this.colp = value;
  //  }
  //}
  @Output() calpVal = new EventEmitter<any>();
  @Output() getVideoData = new EventEmitter<any>();
  @Output() getEventVideoData = new EventEmitter<any>();
  exp: boolean = false;
  driverBehaviors: any = []
  driverBehaviorTotalLenght: any;
  initionLength: any;
  accLength: any;
  alarmVibrationLength: any;
  tabChange: string = "overview";
  dateStatus: any = "1";
  status: any = 3;
  ReqType: any;
  overSpeedLength: any;
  lowBatteryLength: any;
  createManualIncidentForm: FormGroup;
  createReqForm: FormGroup;
  saveAddNotesForm: FormGroup;
  eventsForm: FormGroup;
  submitted = false;
  error = '';
  zoom: number;
  address: string;
  //ComplianceDriverId: string = "";
  //ManagerId: string = "";
  //DriverMateId: string = "";
  //ManagerNotes: string = "";
  //DriverNotes: string = "";
  //DriverMateNotes: string = "";
  //StatusId: number = 1;
  private geoCoder;
  public searchControl: FormControl;
  str: any = 10
  additionalEvidenceForm: FormGroup;
  evidencesubmitted = false;
  gSchockLenght: any;
  eventAuditForm: FormGroup;
  auditsubmitted = false;
  searchRequests: string;
  audituserdata: any = {};
  evidenceFileAccept: string;
  selectedCommand: any;
  selectServercmd: any = [];
  maninctimeerror = false;
  submitManIncLoader = false;
  EvidenceList: any;
  IncidentsList: any;
  AssignedEvents: any = [];
  Devicedata: any;
  vehicle: any = {};
  selectedSingleVideo = {};
  driver: any = {};
  date: string;
  addRes: boolean = true;
  viewManInc: boolean = false;
  summarysection: boolean = true;
  incidensection: boolean = false;
  ananyzeData: any;
  eventlat: number = 0;
  eventlng: number = 0;
  private centerSubject = new BehaviorSubject<google.maps.LatLngLiteral>({ lat: 0, lng: 0 });
  center$ = this.centerSubject.asObservable();
  EventVehicle_lat;
  EventVehicle_lng;
  loadingmsg: boolean = false;
  searchEvents: string;
  selectedLevel: string = "1";
  CustomDateFunctionalityValue: string = "9";
  selectedClassification: string = "0";
  selectedCategory: string = "0";
  selectedReason: string = "0";
  filters: any = {};
  MediaGalleryActiveTab: string;
  filterload: boolean = false;
  customdate: boolean = false;
  fleetFilter: string;
  classificationFilter: string;
  eventtypeFilter: string;
  severityFilter: string;
  defaultAssigned: boolean = false;
  assignedLimitText: boolean = false;
  ActivatedFilter: boolean = false;
  reqsubmitted: boolean = false;
  submitManReq: boolean = false;
  createReqSec: boolean = false;
  managerlist: any;
  driverlist: any;
  reqreceipient: string;
  selectedFleets = [];
  incidentServercommands: any = [];
  commandVideoList: any = [];
  MatchedRoadData: boolean = false;
  MapDataStatus: boolean = false;
  dropdownSettings = {};
  maxDate = new Date();
  minDate = new Date();
  dateformat = 'yyyy-MM-dd';
  locale = 'en-US';
  reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('token')
  });
  display: any;
  onMapReady(map) {
    map.setOptions({
      streetViewControl: false
    });
  }


  moveMap(event: google.maps.MapMouseEvent) {
    //if (event.latLng != null) this.center$ = (event.latLng.toJSON());
    this.centerSubject.next(event.latLng.toJSON());
  }
  move(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.display = event.latLng.toJSON();
  }

  FaultCategories: any;
  Classifications: any;
  //Classifications: Array<Object> = [
  //  { Id: 1, Classification: "Incident" },
  //  { Id: 2, Classification: "Accident" },
  //  { Id: 3, Classification: "Dismissed" },
  //  { Id: 4, Classification: "Intervention" }
  //];
  //FaultCategories: Array<Object> = [
  //  { Id: 1, Category: "Device Malfunction" },
  //  { Id: 2, Category: "Driver Fault" },
  //  { Id: 3, Category: "False Positive" },
  //  { Id: 4, Category: "Misc/Other" }
  //];
  RequestsFilterModal: string = "0";
  RequestFilters: Array<Object> = [
    { Id: 1, Name: "New" },
    { Id: 3, Name: "Completed" },
    { Id: 4, Name: "Archive" }
  ];

  displayGoogleMaps = false;
  FaultCategoryReasons;
  FaultCategory;
  FaultReasons = [];
  incidetData: any = {};
  eventicon;
  isDisplay = false;
  analyzeicon;
  analyzeData;
  customdate_;
  selectedImage: any;
  SelectedThumbNailImage;
  imageindex = 0;
  preloader = false;
  vehiclesByFleet;
  fleetload = false;
  model: any = {};
  DeviceChannelCount;
  channelcountchange = 2;
  evidencetypes: any;
  evidenceList = [];
  latitude_map: number = 0;
  longitude_map: number = 0;
  submitEvidenceLoader = false;
  audited = false;
  saveAddNotesLoader: boolean = false;
  selectedIncidentId;
  addnoteslist = [];
  public show: boolean = false;
  public icon: any = 'fa-eye';
  createManIncFleetId: string = "";
  vehicleService: any = {};
  Requests: any = [];
  Lattitude;
  Longitude;
  // FleetId;
  EventType;
  EventDate;
  DeviceIdentifier;
  event_hours;
  event_min;
  event_sec;
  severity;
  videostatus = false;
  selectedevidencevideo = [];
  droppedData: string;
  selectedEventReviewObj: any = {};
  polyline = [];
  AnalyzeListPoints = [];
  Viewmap_Str: string;
  //gsensor chart
  gtype = 'AreaChart';
  gdata = [];
  gcolumns = ['Year', 'GX', "GY", 'GZ'];
  goptions = {};
  Gsensor_x: boolean = false;
  Gsensor_y: boolean = false;
  Gsensor_z: boolean = false;
  //gsensor chart
  //speed chart
  stype = 'AreaChart';
  sdata = [];
  scolumns = ['Speed', 'Speed'];
  sgoptions = {
    title: ' ',
    hAxis: { title: 'Speed', titleTextStyle: { color: '#333' } },
    vAxis: { minValue: 0 }
  };
  eventStatusList: Array<Object> = [
    { id: 1, name: "Today" },
    { id: 2, name: "Yesterday" },
    { id: 3, name: "Current Week" },
    { id: 4, name: "Last Week" },
    { id: 9, name: "Custom" }
  ];

  @ViewChild('search_', { static: true }) searchElementRef: ElementRef;
  @ViewChild('videoPlayer') evidenceVideoPlayer: ElementRef;
  @ViewChild('evidenceFile_') evidenceFile_: ElementRef;
  @ViewChild('CloseEventAuditModal', { static: true }) CloseEventAuditModal: ElementRef;
  @ViewChild('CloseRequestModal', { static: true }) CloseRequestModal: ElementRef;
  @ViewChild('CloseCompleteRequestModal', { static: true }) CloseCompleteRequestModal: ElementRef;
  @ViewChild('AddAdditionalDetailsModal', { static: true }) AddAdditionalDetailsModal: ElementRef;
  @ViewChild('AddNotesModal', { static: true }) AddNotesModal: ElementRef;
  @ViewChild('IncidentFileterCloseButton', { static: true }) IncidentFileterCloseButton: ElementRef;
  @ViewChild('fullScreen1') divRef;
  customdatefilter: any;
  AllUsersAssignedEvents: any = [];
  page: number = 1;
  polylineOptions: google.maps.PolylineOptions = {
    strokeColor: '#2196f3',
    strokeOpacity: 1.0,
    strokeWeight: 3,
  };
  path: google.maps.LatLngLiteral[] = [];
  constructor(private http: HttpClient,
    //private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private formBuilder: FormBuilder,
    private titleService: Title,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private constants: ConstantsService,
    // private videocomp: VideosComponent,
    //  private eventService: EventMapService,
    private router: Router, private toaster: ToastrService) {
    this.titleService.setTitle("Driver Risk-Resolva | Incidents");
  }
  ngOnInit() {
    this.elem = document.documentElement;
    this.currentDate();

    this.colp = true;
    this.Viewmap_Str = this.constants.Viewmap_Str;
    // this.closeManInc();
    this.setCurrentLocation();
    this.geoCoder = new google.maps.Geocoder;
    let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
      types: ["address"]
    });
    autocomplete.addListener("place_changed", () => {
      this.ngZone.run(() => {
        //get the place result
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();
        //verify result
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }
        //set latitude, longitude and zoom
        this.latitude_map = place.geometry.location.lat();
        this.longitude_map = place.geometry.location.lng();
        this.zoom = 12;
        this.Lattitude = this.latitude_map;
        this.Longitude = this.longitude_map;
      });
    });
    this.loadingmsg = true;
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'FleetId',
      textField: 'FleetName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.createManualIncidentForm = this.formBuilder.group({
      FleetId: [''],
      EventType: ['', Validators.required],
      EventDate: ['', Validators.required],
      Lattitude: ['', Validators.required],
      DeviceIdentifier: ['', Validators.required],
      Longitude: ['', Validators.required],
      event_hours: ['', Validators.required],
      event_min: ['', Validators.required],
      severity: ['', Validators.required],
      event_sec: ['', Validators.required],
      videostatus: [false]
    });
    this.additionalEvidenceForm = this.formBuilder.group({
      EvidenceRequestId: ['', Validators.required],
      Name: ['', Validators.required],
      ContactNumber: ['', Validators.required],
      EvidenceFile: [''],
      Address: ['', Validators.required]
    });

    this.saveAddNotesForm = this.formBuilder.group({
      Type: ['', Validators.required],
      Title: ['', Validators.required],
      Description: ['', Validators.required]
    });

    this.eventsForm = this.formBuilder.group({
      AuditClassification: ['', Validators.required],
      FaultCategory: ['', Validators.required],
      FaultReasons: ['', Validators.required],
      Notes: ['', Validators.required]
    });

    this.createReqForm = this.formBuilder.group({
      ReqType: ['', Validators.required],
      ReqSubject: ['', Validators.required],
      reqreceipient: [''],
      ReceipientId: [''],
      RequestDueDate: ['', Validators.required],
      Request_hours: ['', Validators.required],
      Request_min: ['', Validators.required],
      Request_sec: ['', Validators.required],
      ReqMessage: ['']
    });
    //getting the incidents data
    var UserName = localStorage.getItem('UserName');
    //this.getAllEvents(this.dateStatus, this.status);
    this.getFilters();
    this.getDriverBeaviourCount();
  }
  getDriverBeaviourCount() {
    this.http.get(environment.API_URL + "GetFnolEventGroupConfig", { headers: this.reqHeader }).subscribe(data => {
      console.log(data);
    })
  }
  currentDate() {

    this.dd = this.today.getDate();
    this.mm = this.today.getMonth() + 1; //January is 0 so need to add 1 to make it 1!
    this.yyyy = this.today.getFullYear();

    this.currentData = this.yyyy + '-' + 0 + this.mm + '-' + this.dd;
    //document.write(this.currentData)
    // document.getElementById('date').setAttribute("min", this.currentData);
  }
  getFilters() {
    this.http.get(environment.API_URL + 'Device/GetEventFilters/' + localStorage.getItem("FleetId") + "/" + "null", { headers: this.reqHeader }).subscribe((data: any) => {
      console.log(data, "getFilters Data");
      this.filters = data;
      this.changeStatus();
    })
  }


  toggle() {
    this.show = !this.show;
    if (this.show)
      this.icon = "fa-eye-slash";
    else
      this.icon = "fa-eye";
  }
  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude_map = position.coords.latitude;
        this.longitude_map = position.coords.longitude;
        this.zoom = 8;
      });
    }
  }
  //marker drag
  markerDragEnd($event: google.maps.MapMouseEvent) {
    this.latitude_map = $event.latLng.lat();
    this.longitude_map = $event.latLng.lng();
  }
  //get address
  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          this.address = 'No results found';
        }
      } else {
        this.toaster.error('Geocoder failed due to: ' + status);
      }
    });
  }
  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  //get events list
  dropdownfleetlist: any;
  incidentListlngth: number = 0;
  getAllEvents(date, status) {
    console.log(date);
    // debugger;
    this.summarysection = true;
    this.viewManInc = false;
    this.incidensection = false;
    date = date.toString();
    this.loadingmsg = this.preloader = true;
    var UserName = localStorage.getItem('UserName');
    console.log(status);
    this.http.post(environment.API_URL + "GetFnolEvents/",
      {
        "status": status,
        "date": date,
        "eventfilter": this.eventtypeFilter,
        "classfilter": this.classificationFilter,
        "severityfilter": this.severityFilter
      }, { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          console.log("POST Request is successful ", data);
          this.loadingmsg = this.preloader = false;
          this.IncidentsList = data;
          this.incidentListlngth = this.IncidentsList.length;
          console.log(this.IncidentsList, "IncidentsList");
          /*this.ActivatedFilter = true;*/
          //this.AllUsersAssignedEvents = data.AllUsersAssignedEvents;
          //if (data.FIlters != null) {
          //  this.filters = data.FIlters;
          //  this.dropdownfleetlist = this.filters.FleetListFilter;
          //}
          //console.log(this.AllUsersAssignedEvents, "AllUsersAssignedEvents");

          //let Gschock = this.AllUsersAssignedEvents.filter((value: any) => {
          //  return value.EventDisplayName == 'GShock';
          //});
          //let overSpeed = this.AllUsersAssignedEvents.filter((value: any) => {
          //  console.log(value);
          //  return value.EventDisplayName == 'Over Speed';
          //});
          //let lowBattery = this.AllUsersAssignedEvents.filter((value: any) => {
          //  console.log(value);
          //  return value.EventDisplayName == 'Low Battery';
          //});
          //let inition = this.AllUsersAssignedEvents.filter((value: any) => {
          //  console.log(value);
          //  return value.EventDisplayName == 'Ignition On';
          //});
          //let alarmVibration = this.AllUsersAssignedEvents.filter((value: any) => {
          //  console.log(value);
          //  return value.EventDisplayName == 'Vibration Alarm';
          //});
          //let acc = this.AllUsersAssignedEvents.filter((value: any) => {
          //  console.log(value);
          //  return value.EventDisplayName == 'Geofence-Entry';
          //});
          //this.initionLength = inition.length;
          //this.accLength = acc.length,
          //  this.overSpeedLength = overSpeed.length;
          //this.lowBatteryLength = lowBattery.length;
          //this.gSchockLenght = Gschock.length;
          //this.alarmVibrationLength = alarmVibration.length;
          // this.driverBehaviorTotalLenght = this.AllUsersAssignedEvents.length;
          //console.log('driverBehaviorTotalLenght', this.driverBehaviorTotalLenght)
          //console.log(Gschock, "gSchockLenght");
          //console.log(this.lowBatteryLength, "lowBatteryLength");
          //console.log(this.overSpeedLength, "overSpeedLength")
          //redirecting to selected incident in dashboard
          //this.route.queryParams
          //  .subscribe(params => {
          //    if (params.id) {
          //      var x = this.AssignedEvents.find(x => x.EventId === params.id);
          //      this.MyListIncidents = 1;
          //      this.viewIncident(x);
          //    }
          //  });
          //if (data.AssignedEvents !== null) {
          //  this.AssignedEvents = data.AssignedEvents;
          //  console.log('AssignedEvents', this.AssignedEvents )
          //  var evnetid = sessionStorage.getItem('id');
          //  if (evnetid != undefined && evnetid != null) {
          //    var x = this.AssignedEvents.find(x => x.EventId === evnetid);
          //    this.MyListIncidents = 1;
          //    this.viewIncident(x);
          //    sessionStorage.removeItem('id');
          //  }
          //}
        },
        error => {
          this.loadingmsg = this.preloader = false;
          console.log("Error", error);
        }
      );
  }
  //incident list categories
  eventCategory: Array<Object> = [
    { id: 1, name: "Assigned to me" },
    { id: 2, name: "Unassigned" },
    { id: 3, name: "All Events" }
  ];
  MyListIncidents: number = 2;
  mylistCategory(status) {
    this.MyListIncidents = status;
    this.selectedIncidentId = null;
    this.ActivatedFilter = false;
    if (this.dateStatus != this.CustomDateFunctionalityValue) {
      this.getAllEvents(this.dateStatus, 1);
    } else {
      this.getAllEvents(this.customdate_, 1);
    }
  }
  //clear filters
  ClearFilters() {
    this.ActivatedFilter = false;
    this.selectedFleets = [];
    //if (this.dateStatus != this.CustomDateFunctionalityValue) {
    //  this.getAllEvents(this.dateStatus, 1);
    //} else {
    //  this.getAllEvents(this.customdate_, 1);
    //}
    this.getFilters();
  }
  userAssigned() {
    this.assignedLimitText = true;
    this.defaultAssigned = true;
    this.GetEventaudituser(this.selectedIncidentId);
    //this.defaultAssigned = false;
  }
  userUnAssigned() {
    this.selectedEventReviewObj.IsAssigned = false;
    let obj: any = {};
    this.http.post(environment.API_URL + "ExistedUserUnAssigned", {
      "EventId": this.selectedIncidentId,
    }, { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          this.toaster.success('Existed user UnAssigned Successfully..!');
          this.defaultAssigned = false;
          //this.GetEventaudituser(this.incidetData.evetId);
        },
        error => {
          this.toaster.error("Error" + error);
        }
      );
  }
  //get audituser data
  GetEventaudituser(EventId) {
    console.log(this.defaultAssigned);
    this.http.get(environment.API_URL + "GetFnolEventByUserAudit/" + EventId + "/" + localStorage.getItem('UserName') + "/" + this.defaultAssigned, { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          this.audituserdata = data;
          console.log(data, "audituserdata")
          if (this.audituserdata.Accesslimit === true && this.assignedLimitText) {
            this.toaster.error("you have already reached your assigned limit." + ' ' + "Please remove auto-assigned.");
            this.assignedLimitText = false;
          } else {
            if (this.defaultAssigned === true) {
              this.selectedEventReviewObj.IsAssigned = true;
            }
          }
        },
        error => {
          console.log("Error", error);
        }
      );
  }
  //get Driver data
  DriverData(Id) {
    this.http.get(environment.API_URL + "devices/" + Id + "/driver", { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          this.driver = data;
          console.log(data, "DriverData")
          this.driver.Address = (this.driver.HouseNoName + ", " + this.driver.AddressLine2 + ", " + this.driver.AddressLine3 + ", " + this.driver.AddressLine1 + ", " + this.driver.Postcode);
          console.log(this.driver.Address);
        },
        error => {
          console.log("Error", error);
        }
      );
  }
  //get vehicle data
  VehicleData(Id) {
    this.http.get(environment.API_URL + "GetVehicleRemiders/" + Id + "/", { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          this.vehicle = data.Vehicle;//serviceReminders
          console.log(this.vehicle, "Vehicle Data");


          this.vehicleService = data.serviceReminders;
          console.log(this.vehicleService, "vehicleService Data")
        },
        error => {
          console.log("Error", error);
        }
      );
  }
  //get requests data
  GetRequestList(Filter) {
    this.preloader = true;
    this.http.get(environment.API_URL + "GetIncidentRequestsList/" + this.incidetData.evetId + "/" + Filter, { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          console.log(data);
          this.preloader = false;
          if (data !== null)
            this.Requests = data;//serviceReminders
        },
        error => {
          this.preloader = false;
          console.log("Error", error);
        }
      );
  }
  //get filters data

  //event group type change
  EventGroupTypeChange(data) {
    if (!data.status) {
      data.AllChecked = false;
      data.Types.forEach(x => {
        x.status = false;
      });
    }
  };
  EventTypesCheckAll(data) {
    //data.Types.forEach(x => {
    //  x.status = data.AllChecked;
    //});
    console.log(data);
    this.filters.EventTypesFilter.forEach((x: any, y) => {
      if (x.EventTypeGroupId == data && x.AllChecked) {
        x.Types.forEach((p: any, y) => {
          p.FilterChecked = true;
        })
      } else if (x.EventTypeGroupId == data && !x.AllChecked) {
        x.Types.forEach((p: any, y) => {
          p.FilterChecked = false;
        })
      }
    })
  };

  closeAdditionalForm() {
    this.additionalEvidenceForm.reset();
  }

  optionToggled(data) {
    data.AllChecked = data.Types.every(function (x) { return x.status; });
  };
  //custom date functionality
  customDateFun(customdate) {
    //this.toaster.close({ type: 'danger', content:'' });
    //if (customdate==undefined||customdate=="") {
    //  this.toaster.danger('Please Select Date.');
    //  return false;
    //}
    //debugger;
    // this.ActivatedFilter = true;
    console.log(customdate);
    this.customdatefilter = customdate;
    console.log(this.customdatefilter);
    var from = customdate[0];
    var to = customdate[1];
    var date1 = new Date(from);
    var date2 = new Date(to);
    console.log(date1, date2);
    var diffTime = Math.abs(date2.getTime() - date1.getTime());
    var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    console.log(diffDays);
    if (Date.parse(formatDate(to.toLocaleDateString(), 'yyyy-MM-dd', this.locale)) <= (Date.parse(formatDate(from.toLocaleDateString(), 'yyyy-MM-dd', this.locale)))) {
      this.toaster.error("End date should be greater than Start date");
      return;
    }
    else if (diffDays <= 7) {
      this.customdate_ = formatDate(from.toLocaleDateString(), 'yyyy-MM-dd', this.locale) + "," + formatDate(to.toLocaleDateString(), 'yyyy-MM-dd', this.locale);

      this.changeStatus();
    } else {
      this.toaster.error("Date range more than 7 days can't supported, Please select valid date range.");

    }
  };
  //close the infowindow when click on the new marker
  previous;
  clickedMarker(infowindow: any) {
    if (this.previous) {
      this.previous.close();
    }
    this.previous = infowindow;
  }

  openInfoWindow(marker: MapMarker, infoWindow: MapInfoWindow) {
   
    if (infoWindow != undefined) infoWindow.open(marker);
  }

  //get selected incident details
  getIncidentDetails(EventId) {
    this.GetEventClassifications();
    console.log(EventId);
    this.evidenceFileAccept = "";
    this.preloader = true;    //get incident details
    this.http.get(environment.API_URL + "/GetFnolEventById/" + EventId + "/" + localStorage.getItem('UserName') + "/", { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          //debugger;
          //console.log(data);
          if (data.evetId === null) {
            this.toaster.error("Something went wrong with the selected event !");
            return false;
          }
          this.summarysection = false;
          this.incidensection = true;
          this.preloader = false;
          this.incidetData = data;
          console.log(this.incidetData);
          this.GetRequestList(this.RequestsFilterModal);
          this.VehicleData(data.vehicleId);
          this.DriverData(data.vehicleId);
          this.getAllManagers(data.FleetId);
          this.getAllDrivers(data.FleetId);
          // eventReviewStatus
          this.selectedEventReviewObj = data.eventReviewStatus
          if (this.incidetData.servercommandVideoList !== null && this.incidetData.servercommandVideoList.length !== 0) {
            this.incidentServercommands = this.incidetData.servercommandVideoList;
            this.commandVideoList = this.incidetData.servercommandVideoList[0].Media;
          }
          else {
            this.incidentServercommands = [];
          }
          this.centerSubject.next({
            lat: data.lat,
            lng: data.lng
          });

          this.eventicon = {
            url: '../../assets/images/events/icon_event' + data.TypeId + data.severity + '.svg',
            scaledSize: {
              height: 40,
              width: 40
            }
          };
          if (this.incidetData.eventReviewStatus !== null) {
            this.audited = true;
            //this.eventAuditForm.controls['AuditClassification'].setValue(this.selectedIncidentObj.ClassificationId);
            //this.eventAuditForm.controls['FaultCategory'].setValue(this.selectedIncidentObj.FaultCategoryId);
            //this.eventAuditForm.controls['FaultReasons'].setValue(this.selectedIncidentObj.FaultReasonId);
            //this.eventAuditForm.controls['Notes'].setValue(this.selectedIncidentObj.ReviewNotes);
            this.getFaultReasons(this.selectedEventReviewObj.FaultCategoryId);
          } else {
            this.selectedEventReviewObj = {};
            this.selectedEventReviewObj.ManagerId = ""; this.selectedEventReviewObj.ComplianceDriverId = "";
            this.selectedEventReviewObj.DriverMateId = "";
            this.audited = false;
          }
          //this.GetEventClassifications();
          this.getEvidenceList(EventId);
          this.getAddedNotes();
          this.GetComplianceIncidentTaskType();

          if (this.incidetData.imageList != null && this.incidetData.imageList.length != 0) {
            this.selectedImage = this.incidetData.imageList[0].BlobUrl;
          }


          this.displayGoogleMaps = true;
        },
        error => {
          this.preloader = false;
          console.log("Error", error);
        }
      );
  }
  //get evidence list
  getEvidenceList(EventId) {
    this.http.get(environment.API_URL + "/GetEventDataAdditionalData/" + EventId + "/", { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          //evidence type table
          console.log(data);
          this.evidencetypes = data.RequestType;
          this.evidenceList = data.eventAdditionalData;
        },
        error => {
          console.log("Error", error);
        }
      );
  };
  //save add notes data
  get n() { return this.saveAddNotesForm.controls; }
  saveAddNotes() {
    this.saveAddNotesLoader = true;
    let obj: any = {};
    obj.UserName = localStorage.getItem('UserName');
    obj.EventId = this.incidetData.evetId;
    obj.NoteType = JSON.parse(this.n.Type.value);
    obj.Title = this.n.Title.value;
    obj.Description = this.n.Description.value;
    this.http.post(environment.API_URL + "Fnol/AddNotes", obj, { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          this.toaster.success('Notes added successfully');
          this.saveAddNotesLoader = false;
          this.saveAddNotesForm.reset();
          this.getAddedNotes();
          /*          this.getIncidentDetails(this.incidetData.evetId);*/
          this.AddNotesModal.nativeElement.click();
        },
        error => {
          this.toaster.error("Error" + error);
          this.saveAddNotesLoader = false;
        }
      );
  };
  //get added notes
  getAddedNotes() {
    this.http.get(environment.API_URL + "Fnol/GetNotes/" + this.incidetData.evetId + "/", { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          console.log(data);
          this.addnoteslist = data;
        },
        error => {
          console.log("Error" + error);
        }
      );
  };

  //view incident function
  selectedid;
  viewIncident(data) {
    this.tabChange = "overview";
    this.EvidenceFileListCount = 0;
    this.RequestsFilterModal = "0";
    this.IncidentFileterCloseButton.nativeElement.click();
    this.tripDataObj = {};
    this.polyline = [];
    this.models = [];
    this.MapDataStatus = false;
    this.selectedid = data.EventId;
    this.viewManInc = false;
    this.incidensection = true;
    this.summarysection = false;
    this.selectedIncidentId = data.EventId;
    this.DeviceChannelCount = data.ChannelCount;
    this.getIncidentDetails(data.EventId);
    this.GetEventaudituser(data.EventId);

    this.EvidenceFileList = [];
  };
  AllManagers: any = [];
  getAllManagers(FleetId) {
    this.http.get(environment.API_URL + "ManagerNames/" + FleetId, { headers: this.reqHeader }).subscribe((data) => {
      this.AllManagers = data;
    })
  }
  AllDrivers: any = [];
  getAllDrivers(FleetId) {
    this.http.get(environment.API_URL + "Fleet/GetAllDrivers/" + FleetId, { headers: this.reqHeader }).subscribe((data) => {
      this.AllDrivers = data;
    })
  }

  //open map window for select location
  openMapWindow() {
    this.searchElementRef.nativeElement.value = "";
    this.setCurrentLocation();
  };
  //event analyze slider
  EventAnalyzeSlider(e) {
    this.EventVehicle_lat = this.ananyzeData[e].SecData[0].stGps.dLat;
    this.EventVehicle_lng = this.ananyzeData[e].SecData[0].stGps.dLon;
  }
  updateDisplayTime(movieplayer) {
    console.log(movieplayer.currentTime);
  }
  //ngOnChanges(changes: SimpleChanges) {
  //  console.log(changes,"changes test")
  //  console.log("ngOnChanges test")
  ////  this.closeManInc();
  //}
  closeManInc() {
    // console.log("closeManInc test");
    this.summarysection = true;
    this.viewManInc = false;
    this.incidensection = false;
    this.constants.disableDiv();
    // this.router.navigate(['/home/events'])
    // this.router.navigate(['/home/videos']);
    // this.eventService.colp = true;
    this.calpVal.emit(this.colp);
    //  this.calpVal.emit()
    if (this.eventStatusChange == true) {
      this.getVideoData.emit(this.dateValue);
      this.getEventVideoData.emit({ date: this.dateValue, status: this.statusValue });
    }
    //this.videocomp.getAllEvents(this.incidetData.time)
    //= this.eventService.colp
  }
  closeForm() {
    this.createReqForm.reset();
  }
  //event analyze
  eventAnalyzeFun() {
    this.selectServercmd = [];
    this.selectedCommand = null;
    return this.http.get(environment.API_URL + "GetAnalyticFile/" + this.incidetData.EventAnalyticsFile + "/", { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          this.analyzeData = JSON.parse(data);
          this.AnalyzeListPoints = this.analyzeData.LocationPoints;
          console.log(this.analyzeData);
          this.analyzeicon = {
            url: '../../assets/images/events/EventAnalyzeIcon2.png',
            scaledSize: {
              height: 15,
              width: 15
            }
          };
          this.AnalyzeBounds = new window['google'].maps.LatLngBounds();
          for (let item of this.analyzeData.LocationPoints) {
            this.AnalyzeBounds.extend(new window['google'].maps.LatLng(item.Latitude, item.Longitude));
          }
          if (this.incidentServercommands.length > 1) {
            this.selectedCommand = this.incidentServercommands[0].CommandData;
            this.changeCommandAnalyze(this.selectedCommand);
          }
          if (this.incidetData.TypeId === 2) {
            this.Gsensor_x = true;
            this.Gsensor_y = false;
            this.Gsensor_z = false;
          } else if (this.incidetData.TypeId === 7) {
            this.Gsensor_x = false;
            this.Gsensor_y = true;
            this.Gsensor_z = false;
          } else {
            this.Gsensor_x = true;
            this.Gsensor_y = true;
            this.Gsensor_z = true;
          }
          this.gsensorgraph(this.Gsensor_x, this.Gsensor_y, this.Gsensor_z);
          this.speedgraph();
        },
        error => {
          console.log("Error", error);
        }
      );
  };
  speedgraph() {
    //speed data
    var tickslen = this.analyzeData.LocationPoints.length;
    var xAxisLabel = -Math.floor(tickslen / 2);
    var ticks = [];
    for (var i = 0; i < tickslen; i++) {
      ticks.push(JSON.stringify(xAxisLabel + i));
    }
    this.sdata = [];
    this.analyzeData.LocationPoints.forEach((val, i) => {
      var temp = [];
      temp.push(ticks[i]);
      temp.push(val.Speed);
      this.sdata.push(temp);
    });
  };
  //g-sensor graph
  gsensorgraph(Gx, Gy, Gz) {
    var Colors;

    if (Gx && Gy && Gz) {
      this.gcolumns = ['Event', 'Gx', 'Gy', 'Gz'];
      Colors = ['orange', 'blue', 'red'];
    } else if (Gx && Gy) {
      this.gcolumns = ['Event', 'Gx', 'Gy'];
      Colors = ['orange', 'blue'];
    } else if (Gx && Gz) {
      this.gcolumns = ['Event', 'Gx', 'Gz'];
      Colors = ['orange', 'red'];
    } else if (Gy && Gz) {
      this.gcolumns = ['Event', 'Gy', 'Gz'];
      Colors = ['blue', 'red'];
    } else if (Gx) {
      this.gcolumns = ['Event', 'Gx'];
      Colors = ['orange'];
    } else if (Gy) {
      this.gcolumns = ['Event', 'Gy'];
      Colors = ['blue'];
    } else if (Gz) {
      this.gcolumns = ['Event', 'Gz'];
      Colors = ['red'];
    }
    //gsensor data

    var tickslen = this.analyzeData.GSensors.length / 100;
    var datalength = Math.round(this.analyzeData.GSensors.length / tickslen);
    var pointlength = 1 / datalength;
    var xAxisLabel = -Math.floor(tickslen / 2);;
    this.gdata = [];
    var ticks = [];
    for (var i = 0; i < tickslen; i++) {
      ticks.push(xAxisLabel + i);
    }
    this.goptions = {
      title: ' ',
      hAxis: {
        title: 'Gsensor',
        titleTextStyle: { color: '#333' },
        viewWindow: {
          min: -4,
          max: 5
        },
        ticks: ticks
      },
      colors: Colors,
      vAxis: { minValue: 0 }
    };
    this.analyzeData.GSensors.forEach((val, i) => {
      var temp = [];
      temp.push(xAxisLabel);
      xAxisLabel = xAxisLabel + pointlength;
      if (Gx) {
        temp.push(val.GSensor.x);
      }
      if (Gy) {
        temp.push(val.GSensor.y);
      }
      if (Gz) {
        temp.push(val.GSensor.z);
      }
      this.gdata.push(temp);
    });
  };
  //change command in event analyze
  changeCommandAnalyze(data) {
    var obj = this.incidetData.servercommandVideoList.find(item => item.CommandData === data);
    this.selectServercmd = obj;
  };
  onMouseOver(infoWindow: any, $event: google.maps.MapMouseEvent) {
    infoWindow.open();
  }

  onMouseOut(infoWindow: any, $event: google.maps.MapMouseEvent) {
    infoWindow.close();
  }

  prevImageClick() {
    this.imageindex--;
    this.selectedImage = this.incidetData.imageList[this.imageindex].BlobUrl;
  }

  nextImageClick() {
    this.imageindex++;
    this.selectedImage = this.incidetData.imageList[this.imageindex].BlobUrl;
  }
  //get vehicle based on fleetId
  getVehicles(fleet) {
    this.createManIncFleetId = fleet.FleetId;
    this.fleetload = true;
    return this.http.get(environment.API_URL + "Devices/" + fleet.FleetId + "/" + localStorage.getItem('UserName') + "/", { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          this.fleetload = false;
          this.vehiclesByFleet = data;
        },
        error => {
          console.log("Error", error);
        }
      );
  };
  //create manual incident
  get f() { return this.createManualIncidentForm.controls; }
  onSubmitCreateManualIncident(status) {
    //debugger;
    // stop here if form is invalid
    this.submitted = true;
    if (this.createManualIncidentForm.invalid) {
      return;
    }
    this.submitManIncLoader = true;
    var obj: any = {};
    obj.Lattitude = this.f.Lattitude.value;
    obj.Longitude = this.f.Longitude.value;
    obj.DeviceIdentifier = this.f.DeviceIdentifier.value;
    obj.FleetId = this.createManIncFleetId;
    obj.EventType = JSON.parse(this.f.EventType.value);
    obj.Severity = this.f.severity.value;
    obj.Speed = 0;
    obj.Address = null;
    obj.Status = status;
    obj.EventDate = formatDate(this.f.EventDate.value, 'yyyy-MM-dd', this.locale) + "T" + this.f.event_hours.value + ":" + this.f.event_min.value + ":" + this.f.event_sec.value;
    obj.VideoStatus = this.f.videostatus.value;
    obj.ChannelCount = this.vehiclesByFleet.find(item => item.DeviceIdentifier == obj.DeviceIdentifier).ChannelCount;

    return this.http.post(environment.API_URL + "SaveEventData/", obj, { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          console.log(data);
          this.maninctimeerror = false;
          if (data == false) {
            this.maninctimeerror = true;
          } else {
            this.toaster.success("created successfully");
            //updating the list
            if (this.selectedLevel != this.CustomDateFunctionalityValue) {
              this.getAllEvents(this.dateStatus, 1);
            } else {
              this.getAllEvents(this.customdate_, 1);
            }
            //updating the list
            this.createManualIncidentForm.reset();
            this.closeManInc();
          }
          this.submitManIncLoader = false;
        },
        error => {
          console.log("Error", error);
          this.submitManIncLoader = false;
        }
      );
  }



  dragingurl: string;
  closeManIncWarn() {
    this.maninctimeerror = false;
  }
  VideoChannelView(cnt) {
    this.isDisplay = false;
    if (cnt === 1) {
      this.selectedSingleVideo = this.selectedevidencevideo.find(x => x.BlobUrl !== null);
    }
    this.channelcountchange = cnt;
    console.log(cnt);
    jQuery(".video_player").each(function () {

      this.currentTime = 0;
    })
    this.video_pause();
  };



  //play evidence video
  playevidencevideo(data) {
    console.log(data);
    //if (this.DeviceChannelCount < 9) {
    //  this.channelcountchange = this.DeviceChannelCount;
    //} else {
    //  this.channelcountchange = 9;
    //}
    //this.channelcountchange = this.DeviceChannelCount;
    this.videoModal = true;
    this.channelcountchange = 2;
    this.selectedevidencevideo = data.Media;
    this.selectedSingleVideo = this.selectedevidencevideo.find(x => x.BlobUrl !== null);
    this.selectedevidencevideo.forEach((x: any, y) => {
      x.Id = UUID.UUID();
    })
    console.log(this.selectedevidencevideo);
    this.PlayButton = true;
    this.isDisplay = false;
    jQuery('#inputSeek').val(0);

    document.getElementById("time").innerHTML = '00:00';
    document.getElementById("duration").innerHTML = '00:00';
  };
  drop(ev) {
    console.log(ev);
  }
  //fullScreen(id) {
  //  var myVideo: any = document.getElementById(id);
  //  myVideo.webkitRequestFullScreen();
  //  //jQuery(".video_player").each(function () {
  //  //  this.requestFullscreen();
  //  //})
  //}
  vidSelect = 0;
  fullScreen(id) {
    //var myVideo: any = document.getElementById("my_video");
    //myVideo.width = 960;

    //jQuery(".video_player").each(function () {
    //  this.requestFullscreen();
    //})
    if (this.vidSelect === 0) {
      jQuery("#" + id).get(0).requestFullscreen();
      this.vidSelect++;
    }
    else {
      jQuery(".max").each(function () {
      })
      this.vidSelect = 0;
    }

  }
  urlId = "";
  allowDrop(ev, Vid, url) {
    console.log(ev);
    //this.evidenceVideoPlayer.nativeElement.src = this.dragingurl;
    //document.getElementById(Vid).setAttribute("src", this.dragingurl);
    //console.log(document.getElementById(ev));
    //document.getElementById(url).innerHTML = this.channelLabel;
    //console.log(jQuery("#" + Vid));
    /*jQuery("#" + Vid).attr("src", this.dragingurl);*/
    jQuery("#selectedVideo" + Vid).attr("src", this.dragingurl);
    jQuery("#selectedVideo" + Vid).empty();
    document.getElementById(url).innerHTML = this.channelLabel;
    jQuery('#inputSeek').val(0);


    jQuery('.video_player').each(function () {
      var mediaVideo = jQuery("#" + this.id).get(0);
      mediaVideo.currentTime = 0;
    });


  }
  channelLabel = "";
  drag(ev: any) {
    this.dragingurl = ev.BlobUrl;
    this.channelLabel = ev.ChannelName;
    console.log(ev);
  }

  PlayButton: boolean = true;
  video_play() {
    console.log("play video");
    this.PlayButton = false;
    this.isDisplay = true;
    jQuery(".video_player").each(function () {
      this.play();
      this.onended = function () {
        var mediaVideo = jQuery("#" + this.id).get(0);
        mediaVideo.currentTime = 0;
        mediaVideo.pause();
      };
    })
    this.seekingfun();
  };

  vidEnded() {
    this.isDisplay = false;
    this.PlayButton = true;
  };

  video_pause() {
    console.log("pause video");
    this.PlayButton = true;
    this.isDisplay = false;
    jQuery(".video_player").each(function () {
      this.pause();
      this.PlayButton = true;
    })
    this.seekingfun();
  };
  videoBackward(value) {
    // this.evidenceVideoPlayer.nativeElement.currentTime -= value;
    jQuery(".video_player").each(function () {
      this.currentTime -= value;
    })
    this.seekingfun();
  };
  videoForward(value) {
    //this.evidenceVideoPlayer.nativeElement.currentTime += value;
    jQuery(".video_player").each(function () {
      this.currentTime += value;
    })
    this.seekingfun();
  };

  //seekingfun() {
  //  this.selectedevidencevideo.forEach((value, key) => {
  //    if (value.BlobUrl !== null) {
  //      this.seekingfun1("selectedVideo" + value.ChannelName);
  //    }
  //  });
  //};

  seekingfun = function () {
    this.seekingfun2("selectedVideo" + this.selectedSingleVideo.ChannelName)
    if (this.channelcountchange === 1) {
      this.seekingfun1("selectedVideo" + this.selectedSingleVideo.ChannelName);
    } else
      this.selectedevidencevideo.forEach((value, key) => {
        if (value.BlobUrl !== null) {
          this.seekingfun1("selectedVideo" + value.ChannelName);
        }
      });
  }

  seekingfun2 = function (id: any) {
    var inputSeek: any = document.getElementById("inputSeek");
    var myVideo: any = document.getElementById(id);
    myVideo.addEventListener("timeupdate", () => {
      var duration = myVideo.duration;
      var pct = (Math.round(myVideo.currentTime) / duration) * 100;
      if (myVideo.currentTime !== 0) {
        document.getElementById("time").innerHTML = this.timeConvert(myVideo.currentTime);
        document.getElementById("duration").innerHTML = this.timeConvert(myVideo.duration);
      } else {
        document.getElementById("time").innerHTML = '00:00';
        document.getElementById("duration").innerHTML = '00:00';
      }
      inputSeek.value = pct;
    });
    myVideo.addEventListener('ended', function () {
      this.onended(() => {
        console.log("it is reading after the video");
        this.PlayButton = true;
        jQuery('#inputSeek').val('0');
        document.getElementById("time").innerHTML = '0:00';
      });
    });

  };

  seekingfun1 = function (id: any) {
    var inputSeek: any = document.getElementById("inputSeek");
    var myVideo: any = document.getElementById(id);
    inputSeek.addEventListener("change", function () {
      var videoTime = inputSeek.value * myVideo.duration / 100;
      myVideo.currentTime = videoTime;
      this.seekingfun();
    });

  }


  //seekingfun1(id) {
  //  var myVideo: any = document.getElementById(id);
  //  var inputSeek: any = document.getElementById("inputSeek");
  //  myVideo.addEventListener("timeupdate", () => {
  //    var pct = (myVideo.currentTime / myVideo.duration) * 100;
  //    if (myVideo.currentTime !== 0) {
  //      document.getElementById("time").innerHTML = this.timeConvert(myVideo.currentTime);
  //      document.getElementById("duration").innerHTML = this.timeConvert(myVideo.duration);
  //    } else {
  //      document.getElementById("time").innerHTML = '00:00';
  //      document.getElementById("duration").innerHTML = '00:00';
  //    }
  //    inputSeek.value = pct;
  //  });

  //  inputSeek.addEventListener("change", function () {
  //    const videoTime = inputSeek.value * myVideo.duration / 100;
  //    myVideo.currentTime = videoTime;
  //    this.seekingfun();
  //  });
  //};


  timeConvert(n: any) {
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return rhours + ":" + rminutes;
  }
  showpopup: boolean = false;
  muteOptions = function () {
    this.showpopup = true;
  }

  muteOptionsClose = function () {
    this.showpopup = false;
  }

  muteButton = true;
  muteVideo1(chname) {
    this.selectedevidencevideo.forEach((vch, key) => {
      if (vch.BlobUrl !== null) {
        var id = "selectedVideo" + vch.ChannelName;
        var vid = document.getElementById(id) as HTMLMediaElement;

        if (vch.IsMuted && vch.ChannelName == chname) {
          vid.muted = false;
          vid.volume = 1;
          this.muteButton = false;
        }
        else {
          vch.IsMuted = false;
          vid.muted = true;
          this.muteButton = true;
        }
      }
    })
  }

  DownloadVideos(videos) {
    videos.forEach((value, key) => {
      var blob = new Blob([value.BlobUrl], { type: 'video/mp4' });
      var dwldLink = document.createElement("a");
      var url = URL.createObjectURL(blob);
      var isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
      if (isSafariBrowser) {
        dwldLink.setAttribute("target", "_blank");
      }
      dwldLink.setAttribute("href", url);
      dwldLink.setAttribute("download", value.channelName + '_' + value.Url);
      dwldLink.style.visibility = "hidden";
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);
    });
  };


  //evidence file restrictions
  evidenceReqType(type) {
    switch (type) {
      case "1":
        this.evidenceFileAccept = ".mp4,.mov,.3gp,.mpg,.flv,.ogg,.mpeg";
        break;
      case "2":
        this.evidenceFileAccept = "image/*";
        break;
      default:
        this.evidenceFileAccept = ".pdf,.doc,.docx";
    }
  };
  //evidence file upload
  fileToUpload: File = null;
  additionalfile: boolean = false;
  handleFileInput(files: FileList) {
    const kb = Math.round(files.item(0).size / 1024);
    const mb = kb / 1024;
    if (mb > 25) {
      this.toaster.error("Please select below 25MB");
      this.EvidenceFile = "";
      return;
    }
    this.additionalfile = true;
    const formdata: FormData = new FormData();
    formdata.append('EvidenceFile', files.item(0), files.item(0).name);
    return this.http.post("/api/upload", formdata)
      .subscribe(
        (data: any) => {
          this.fileToUpload = data.data;
          this.additionalfile = false;
        },
        error => {
          console.log("Error", error);
        }
      );
  }
  //additional evidences
  EvidenceFile: any;
  get e() { return this.additionalEvidenceForm.controls; }
  additionalEvidenceData() {
    this.isdissable = true;
    this.submitEvidenceLoader = true;
    //additionalEvidenceForm
    var obj: any = {};
    obj.EventId = this.incidetData.evetId;
    obj.EventType = this.incidetData.type;
    obj.EvidenceRequestId = this.e.EvidenceRequestId.value;
    obj.Name = this.e.Name.value;
    obj.ContactNumber = this.e.ContactNumber.value;
    obj.Url = this.fileToUpload;
    obj.Address = this.incidetData.LocationAddress;
    obj.Notes = this.e.Address.value;
    obj.UserName = localStorage.getItem('UserName');
    return this.http.post(environment.API_URL + "SaveEventAdditionalData/", obj, { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          this.toaster.success("Evidence Added Successfully...!");
          this.additionalEvidenceForm.reset();
          this.submitEvidenceLoader = false;
          this.isdissable = false;
          this.getEvidenceList(this.incidetData.evetId);
          this.AddAdditionalDetailsModal.nativeElement.click();
        },
        error => {
          console.log("Error", error);
          this.submitEvidenceLoader = false;
          this.isdissable = false;
        }
      );
  };
  //event audit functionality
  eventAuditFun1(classification) {

    this.audited = true;
    this.selectedEventReviewObj.ClassificationId = classification;
    this.selectedEventReviewObj.FaultCategoryId = 0;
    this.selectedEventReviewObj.FaultCategoryId = 0;
    this.selectedEventReviewObj.ReviewNotes = "Event audited";
    this.eventAuditSubmit();

    //else {
    //  this.audited = false;
    //  this.selectedEventReviewObj.ClassificationId = classification;
    //  this.selectedEventReviewObj.FaultCategoryId = this.FaultCategories[0].Id;
    //  this.getFaultReasons(this.selectedEventReviewObj.FaultCategoryId);
    //}

  }
  eventAuditFun() {
    this.audited = false;
    // incident audit functionality options
  }

  GetEventClassifications() {
    this.http.get(environment.API_URL + "Device/GetEventClassifications/", { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          this.Classifications = data;
        },
        error => {
          console.log("Error", error);
        }
      ),
      this.http.get(environment.API_URL + "Device/GetFaultCategories/", { headers: this.reqHeader })
        .subscribe(
          (data: any) => {
            this.FaultCategories = data;
          },
          error => {
            console.log("Error", error);
          }
        );
  }


  getFaultReasons(Id) {
    this.http.get(environment.API_URL + "Device/GetFaultCategoryReasons/" + Id + "/", { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          console.log(data);
          this.FaultReasons = data;
          if (this.selectedEventReviewObj.FaultReasonId === undefined || this.selectedEventReviewObj.FaultReasonId === null)
            this.selectedEventReviewObj.FaultReasonId = this.FaultReasons[0].Id;
        },
        error => {
          console.log("Error", error);
        }
      );
  };

  get a() { return this.eventsForm.controls }

  eventAuditSubmit() {
    this.eventStatusChange = true;
    //console.log(this.incidetData,"thisincidetData")
    var obj: any = {};
    obj.DeviceIdentifier = this.incidetData.DeviceIdentifier;
    obj.ClassificationId = this.selectedEventReviewObj.ClassificationId;
    obj.CategoryId = this.selectedEventReviewObj.FaultCategoryId;
    obj.ReasonId = this.selectedEventReviewObj.FaultReasonId;
    obj.ReviewNotes = this.selectedEventReviewObj.ReviewNotes;
    obj.DriverId = this.driver.Id;
    obj.VehicleId = this.vehicle.Id;
    obj.EventId = this.incidetData.evetId;
    obj.FleetId = this.incidetData.FleetId;
    this.http.post(environment.API_URL + "Device/SaveEventAudit/", obj, { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          this.auditEventData = data;
          //console.log(this.auditEventData, "eventAuditSubmit Details");
          this.eventStatusChange = false;
          this.eventsForm.reset();

          this.toaster.success("Event audited successfully");
          /* this.videocomp.getAllEvents(this.incidetData.time)*/
          jQuery("#EventAuditactions").modal('hide');
          this.getIncidentDetails(this.incidetData.evetId);
          //this.GetEventClassifications();

        },
        error => {
          console.log("Error", error);
          this.eventStatusChange = false;
        }
      );

    // console.log("eventAuditSubmit Listening here");

  };

  //view trip
  viewTrip() {
    debugger;
    this.preloader = true;
    let obj: any = {};
    //obj.DateTime = this.pipe.transform(this.incidetData.eventDate, 'yyyy-MM-dd HH:mm:ss');
    obj.DateTime = this.incidetData.eventDate;
    obj.deviceIdentifier = this.incidetData.DeviceIdentifier;
    this.http.post(environment.API_URL + "GetTripByEventTime/", obj, { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          //debugger;
          if (data != null) {
            this.gettripById(data);
          } else {
            this.toaster.success("The trip could not be found...!");
            this.preloader = false;
          }
        },
        error => {
          this.toaster.error("sorry for inconvenience something went wrong..!");
          this.preloader = false;
        }
      );
  };
  //open media gallery on trip
  OpenGalleryModal(status) {
    this.MediaGalleryActiveTab = status;
  };
  //get trip
  tripDataObj: any = {};
  starticon = {
    url: '../../assets/images/flag-start.png',
    scaledSize: {
      height: 30,
      width: 30
    }
  };
  endicon = {
    url: '../../assets/images/flag-end.png',
    scaledSize: {
      height: 30,
      width: 30
    }
  };
  bounds: any;
  AnalyzeBounds: any = {};
  models = [];
  gettripById(id) {
    this.MatchedRoadData = false;
    this.http.get(environment.API_URL + "GettripData/" + this.incidetData.DeviceIdentifier + "/" + id + "/" + localStorage.getItem('UserName') + "/", { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          debugger;
          console.log(data);
          this.preloader = false;
          this.polyline = data.selectedTrip.routePointslist;
          console.log(this.polyline, 'this.polyline')
          this.models = data.selectedTrip.routePointslist;
          this.setBounds();
          this.tripDataObj = data.selectedTrip;
          this.MapDataStatus = data.MapDataStatus;

          //for (const coordinate of coordinates) {
          // 
          //}
        },
        error => {
          this.toaster.error("sorry for inconvenience something went wrong..!");
          this.preloader = false;
        }
      );
  };
  drawPolyline(): void {
    new google.maps.Polyline({
      path: this.path,
      geodesic: true,
      strokeColor: '#2196f3',
      strokeOpacity: 1.0,
      strokeWeight: 3,
      map: this.map.googleMap,
    });
  };
  setBounds() {
    this.bounds = new google.maps.LatLngBounds();

    for (const item of this.models) {
      this.bounds.extend(new google.maps.LatLng(item.lat, item.lng));

      if (this.MatchedRoadData)
        this.path.push({ lat: item.mapdata.lat, lng: item.mapdata.lng });
      else
        this.path.push({ lat: item.lat, lng: item.lng });
    }
    this.drawPolyline();
  }
  //submit create request form
  get rf() { return this.createReqForm.controls; }
  CreateRequestForm() {
    this.selectedReceipientId = "";
    this.reqreceipient = "";
    //getting manager names
    //return this.http.get(environment.API_URL + "ManagerNames/" + localStorage.getItem('FleetId'), { headers: this.reqHeader })
    //  .subscribe(
    //    (data: any) => {
    //      this.managerlist = data;
    //    },
    //    error => {
    //      console.log("Error", error);
    //    }
    //  ),//getting Driver names
    //  this.http.get(environment.API_URL + "Devices/DriverList", { headers: this.reqHeader })
    //    .subscribe(
    //      (data: any) => {
    //        console.log(data);
    //        this.driverlist = data;
    //        console.log("driverlist", data)
    //      },
    //      error => {
    //        console.log("Error", error);
    //      }
    //    );
  }
  selectedReceipientId: any;
  formattedReqMessage: any;
  selectReceipient(data) {
    this.selectedReceipientId = data.Id;
  };

  formatToYYYYMMDD(date: Date): string {
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  reqMessageDate: any;
  today1: any;
  onSubmitReqForm() {
    //debugger;
    // stop here if form is invalid
    console.log(this.createReqForm);
    this.reqsubmitted = true;
    this.reqMessageDate = new Date(this.rf.RequestDueDate.value);
    this.formattedReqMessage = this.formatToYYYYMMDD(this.reqMessageDate);
    this.today1 = this.formatToYYYYMMDD(this.today);
    if (this.createReqForm.invalid) {
      //this.CloseRequestModal.nativeElement.click();
      this.toaster.error('Fill the Entire Details');
      // this.createReqForm.reset();
      /*alert("Fill the Entire Details and Submit");*/
      return;
    }
    if (this.formattedReqMessage < this.today1) {

      this.toaster.error('Selected date cannot be in the past.');
      return;
    }
    this.submitManReq = true;
    let obj: any = {};
    obj.RequestTypeId = JSON.parse(this.rf.ReqType.value);
    console.log(this.rf);
    obj.ReceipientTypeId = JSON.parse(this.rf.reqreceipient.value);
    obj.ReceipientId = this.selectedReceipientId;
    obj.Subject = this.rf.ReqSubject.value;
    obj.Notes = this.rf.ReqMessage.value;
    obj.IncidentId = this.selectedid;
    obj.RequestedBy = localStorage.getItem('userid');
    obj.ModifiedBy = localStorage.getItem('userid');
    obj.UserName = localStorage.getItem('UserName');
    obj.DueDate = formatDate(this.rf.RequestDueDate.value, 'yyyy-MM-dd', this.locale) + "T" + this.rf.Request_hours.value + ":" + this.rf.Request_min.value + ":" + this.rf.Request_sec.value;
    this.http.post(environment.API_URL + "AddEvidenceRequest/", obj, { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          this.toaster.success("A new request has been created and assigned..!");
          this.GetRequestList(this.RequestsFilterModal);
          this.CloseRequestModal.nativeElement.click();
          this.createReqForm.reset();
          this.submitManReq = false;
          this.createReqSec = false;
          this.toaster.success("submitted successfully");
        },
        error => {
          this.submitManReq = false;
          this.createReqSec = false;
          console.log("Error", error);
        }
      );
  };
  //view request
  selectedRequest: any;
  selectedRequestMediaLength: any;
  selectedRequestNotes: any;
  selectedRequestReceipient: any;
  selectedRequestMedia: any;
  selectedRequestMessage: any;
  selectedRequestRequestTypeId: any;
  selectedRequestReceipientId: any;
  selectedRequestSubject: any;
  viewRequest(req) {
    //debugger;
    this.EvidenceFileListCount = 0;
    if (req.Media.length != 0) {
      req.Media.forEach(x => {
        x.isChecked = false;
      });
    }
    this.selectedRequest = req;
    this.selectedRequestMedia = req.Media;
    if (req.Media[0].blobrequestfileUrl !== null) {
      this.ActiveSelectedThumNailImage = req.Media[0].blobrequestfileUrl;
      this.SelectedThumbNailImage = this.sanitizer.bypassSecurityTrustResourceUrl(req.Media[0].blobrequestfileUrl);
    }
    this.selectedRequestMediaLength = req.Media.length;
    this.selectedRequestMessage = req.Message;
    this.selectedRequestNotes = req.Notes;
    this.selectedRequestRequestTypeId = req.RequestTypeId;
    if (req.ManagerName != null) {
      this.selectedRequestReceipient = req.ManagerName;
    } else {
      this.selectedRequestReceipient = req.DriverName;
    }
    this.selectedRequestReceipientId = req.ReceipientTypeId;
    //this.selectedRequestSubject = req.Subject;
  };
  viewRequest2(req) {
    //debugger;
    this.EvidenceFileListCount = 0;
    if (req.Media.length != 0) {
      req.Media.forEach(x => {
        x.isChecked = false;
      });
    }
    this.selectedRequest = req;
    this.selectedRequestMedia = req.Media;
    if (req.Media[0].blobrequestfileUrl !== null) {
      this.ActiveSelectedThumNailImage = req.Media[0].blobrequestfileUrl;
      this.SelectedThumbNailImage = this.sanitizer.bypassSecurityTrustResourceUrl(req.Media[0].blobrequestfileUrl);
    }
    this.selectedRequestMediaLength = req.Media.length;
    this.selectedRequestNotes = req.Notes;
    this.selectedRequestRequestTypeId = req.EvidenceRequestId;
    this.selectedRequestReceipient = req.Name;
  };
  //selected thumb nail image
  ActiveSelectedThumNailImage: any;
  SelectedThumbNailImg(item) {
    this.ActiveSelectedThumNailImage = item.blobrequestfileUrl;
    this.SelectedThumbNailImage = this.sanitizer.bypassSecurityTrustResourceUrl(item.blobrequestfileUrl);
  };
  //view request file
  selectedReqFileType: string;
  selectedReqFileUrl: any;
  ViewIncidentReqFile(req, item) {
    if (req.RequestTypeId) {
      this.selectedReqFileType = req.RequestTypeId;
    } else if (req.EvidenceRequestId) {
      this.selectedReqFileType = req.EvidenceRequestId;
    }
    this.selectedReqFileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(item.blobrequestfileUrl);
  };
  //complete task
  CompleteRequest() {
    this.http.get(environment.API_URL + "CompleteRequest/" + this.selectedRequest.Id + "/", { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          this.CloseCompleteRequestModal.nativeElement.click();
          this.toaster.success('Successfully completed your task.');
          this.EvidenceFileList = [];
          this.GetRequestList(this.RequestsFilterModal);
        },
        error => {
          this.toaster.error('Failed to complete your task.');
        }
      );
  };
  //submit view request
  RequestMoveToEvidence() {
    if (this.EvidenceFileList.length == 0) {
      this.toaster.error("Please select files to move.");
      return false;
    }
    this.http.get(environment.API_URL + "RequestMoveToEvidence/" + this.selectedRequest.Id, { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          this.toaster.success('Successfully moved to evidence.');
          this.EvidenceFileList = [];
          this.GetRequestList(this.RequestsFilterModal);
          this.getEvidenceList(this.incidetData.evetId);
          this.CloseCompleteRequestModal.nativeElement.click();
        },
        error => {
          this.toaster.error('Failed while moving to evidence.');
          console.log("Error", error);
        }
      );
  };
  //add files to evidence list
  EvidenceFileList: Array<string> = [];
  EvidenceFileListCount: number;
  AddToEvidenceFun(item) {
    if (item.isChecked === true) {
      this.EvidenceFileListCount++;
      this.EvidenceFileList.push(item.filename);
    } else {
      this.EvidenceFileList.splice(this.EvidenceFileList.indexOf(item.filename), 1);
      this.EvidenceFileListCount--;
    }
  };
  @HostListener('document:fullscreenchange', ['$event'])
  @HostListener('document:webkitfullscreenchange', ['$event'])
  @HostListener('document:mozfullscreenchange', ['$event'])
  @HostListener('document:MSFullscreenChange', ['$event'])
  fullscreenmode() {

    if (this.toggleClass == 'ft-minimize') {
      this.toggleClass = 'ft-maximize';
    }
    else {
      this.toggleClass = 'ft-minimize';
    }
    console.log(this.toggleClass)
  }
  //openFullscreen() {
  //  if (this.elem.requestFullscreen) {
  //    this.elem.requestFullscreen();
  //    //this.toggleClass = 'ft-maximize';
  //  } else if (this.elem.mozRequestFullScreen) {
  //    /* Firefox */
  //    this.elem.mozRequestFullScreen();
  //    //this.toggleClass = 'ft-maximize';
  //  } else if (this.elem.webkitRequestFullscreen) {
  //    /* Chrome, Safari and Opera */
  //    this.elem.webkitRequestFullscreen();
  //    //this.toggleClass = 'ft-maximize';
  //  } else if (this.elem.msRequestFullscreen) {
  //    /* IE/Edge */
  //    this.elem.msRequestFullscreen();
  //    //this.toggleClass = 'ft-minimize';
  //  }
  //}

  openFullscreen() {
    // Use this.divRef.nativeElement here to request fullscreen
    const elem = this.divRef.nativeElement;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    }
  }
  closeFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }

  allowOnlyNo(event: any) {
    const pattern = /[0-9\+\-\: ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    };
  }

  eventsCustomDate: any;
  changeStatus() {
    console.log(this.filters);
    this.searchEvents = "";
    this.IncidentFileterCloseButton.nativeElement.click();
    //console.log(this.eventsCustomDate, this.customdate_);
    var eventtype = this.filters.EventTypesFilter.filter((item) => { return item.FilterChecked === true });
    var classification = this.filters.clasificationList.filter((item) => { return item.FilterChecked === true });
    var severity = this.filters.SeverityFilter.filter((item) => { return item.FilterChecked === true });
    var fleetString = "", eventString = "", classificationString = "", severityString = "";
    console.log('severity', severity);
    console.log('classification', classification);
    console.log('eventtype', eventtype);
    var Types = [];
    this.filters.clasificationList.forEach(item => {
      if (item.FilterChecked === true && classification != undefined) {
        classificationString += item.Id + ",";
      }
      //else if (classification == undefined) {
      //  classificationString += item.Id + ",";
      //}
    });
    //severity list
    this.filters.SeverityFilter.forEach(item => {
      if (item.FilterChecked === true && severity != undefined) {
        severityString += item.Id + ",";
      } else if (severity == undefined) {
        //severityString += item.Id + ",";
        severityString = "3";
      }
    });
    //event type list
    this.filters.EventTypesFilter.forEach(item => {
      if (item.FilterChecked === true) {
        item.Types.forEach(type => {

          Types.push(type);

        });
      }
    });
    Types.forEach(item => {
      if (item.FilterChecked) {
        eventString += item.Id + ",";
      }
      // else if (eventtype == undefined) {
      //  eventString += item.Id + ",";
      //}
    });
    this.eventtypeFilter = eventString;
    this.classificationFilter = classificationString;
    this.severityFilter = severityString;
    // console.log('eventtypeFilter', this.eventtypeFilter);
    //  console.log('classificationFilter', this.classificationFilter);
    //  console.log('severityFilter', this.severityFilter);
    if (this.dateStatus != this.CustomDateFunctionalityValue) {
      //console.log("it is reading here");
      this.customdate = false;
      this.eventsCustomDate = "";
      this.customdate_ = "";
      if (eventtype == undefined && classification == undefined && severity == undefined && this.dateStatus != this.CustomDateFunctionalityValue) {
        this.ActivatedFilter = false;
        this.fleetFilter = "";
        this.eventtypeFilter = "";
        this.classificationFilter = "";
        this.severityFilter = "";
        this.getAllEvents(this.dateStatus, 1);
        this.filterload = false;
        return;
      }
      this.ActivatedFilter = true;
      //classification list
      //if (classification !== undefined) {
      //  console.log("it is reading here");
      //  classificationString += 'true,'
      //} else {
      //  classificationString += 'false,'
      //}

      this.getAllEvents(this.dateStatus, this.status);
    } else {
      this.customdate = true;
      console.log("it is readingh here");
      if (this.customdate_ != "")
        this.getAllEvents(this.customdate_, this.status);
    }
  }
  //enable matched road data
  toggleMatchedRoadData(status) {
    //  console.log(status);
    this.MatchedRoadData = status;
    this.setBounds();
    //this.viewTrip();
  };

  saveData(statusId) {
    const Obj: any = {};
    Obj.ManagerId = this.selectedEventReviewObj.ManagerId;
    Obj.ComplianceDriverId = this.selectedEventReviewObj.ComplianceDriverId;
    Obj.ManagerNotes = this.selectedEventReviewObj.ManagerNotes;
    Obj.DriverNotes = this.selectedEventReviewObj.DriverNotes;
    Obj.EventId = this.incidetData.evetId;
    Obj.DriverMateId = this.selectedEventReviewObj.DriverMateId;
    Obj.DriverMateNotes = this.selectedEventReviewObj.DriverMateNotes;
    Obj.StatusId = statusId;

    if (this.incidetData.eventReviewStatus.ClassificationId !== 3 && this.incidetData.eventReviewStatus !== null) {
      if ((Obj.ComplianceDriverId === null || Obj.ComplianceDriverId === undefined || Obj.ComplianceDriverId === "") || (Obj.DriverNotes === null || Obj.DriverNotes === "")) {
        this.toaster.error("Enter Driver Details and Submit");
        return;
      }
    }

    if ((Obj.ManagerId === null || Obj.ManagerId === undefined || Obj.ManagerId === "") ||
      (Obj.ManagerNotes === null || Obj.ManagerNotes === "")) {
      this.toaster.error("Enter Manager Details and Submit");
      return;
    }
    if (statusId === 1) {
      this.ExtrasaveAddNotes('Compliance', 'Compliance Details Saved');
    } else {
      this.ExtrasaveAddNotes('Compliance', 'Compliance Details Completed');
    }

    if (this.incidetData.eventReviewStatus === null) {
      var obj1: any = {};
      obj1.DeviceIdentifier = this.incidetData.DeviceIdentifier;
      obj1.ClassificationId = 4;
      obj1.CategoryId = 2;
      obj1.ReasonId = 30;
      obj1.ReviewNotes = "Intervention";
      obj1.DriverId = this.driver.Id;
      obj1.VehicleId = this.vehicle.Id;
      obj1.EventId = this.incidetData.evetId;
      obj1.FleetId = this.incidetData.FleetId;
      this.http.post(environment.API_URL + "Device/SaveEventAudit/", obj1, { headers: this.reqHeader })
        .subscribe(
          (data: any) => {
            jQuery("#EventAuditactions").modal('hide');
            this.SaveCompliance(Obj);
          },
          error => {
            console.log("Error", error);
          }
        );
    } else {
      this.SaveCompliance(Obj);
    }
  }

  ExtrasaveAddNotes(Title, Description) {
    let obj: any = {};
    obj.UserName = localStorage.getItem('UserName');
    obj.EventId = this.incidetData.evetId;
    obj.NoteType = 0;
    obj.Title = Title;
    obj.Description = Description;
    this.http.post(environment.API_URL + "Fnol/AddNotes", obj, { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          this.getAddedNotes();
        },
        error => {
          this.toaster.error("Error" + error);
        }
      );
  };
  SaveCompliance(Obj) {
    var Types = [];
    this.ComplianceIncidentTasks.forEach(item => {
      if (item.CelebrateFlag === true || item.CoachFlag === true) {
        Types.push(item);
      }
    });
    Obj.Tasks = Types;
    this.http.post(environment.API_URL + "Device/SaveCompliance", Obj, { headers: this.reqHeader }).subscribe((data: any) => {
      this.getIncidentDetails(this.incidetData.evetId);
      this.toaster.success("Compliance Updated Successfully..!");
    })
  }
  GetComplianceIncidentTaskType() {
    this.ComplianceIncidentTasks = [];
    this.http.get(environment.API_URL + "Fnol/GetComplianceIncidentTaskType/" + this.incidetData.evetId + "/" + this.incidetData.FleetId + "/", { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          this.ComplianceIncidentTasks = data;
          console.log(data, 'ComplianceIncidentTasks');
        },

      );
  };
  changeTab(str) {
    //console.log(str);
    this.tabChange = str;
  }
  requestonItemChange(str) {
    this.reqreceipient = str;
  }
  printOut() {
    window.print();
  }
  SelectedManager: any;
  SelectedDriver: any;
  SelectedDriverMate: any;
  SelectedComplianceTasks = [];
  printcompliance() {
    this.SelectedComplianceTasks = [];
    this.SelectedManager = this.AllManagers.find(x => x.Id === this.selectedEventReviewObj.ManagerId);
    this.SelectedDriver = this.AllDrivers.find(x => x.Id === this.selectedEventReviewObj.ComplianceDriverId);
    this.SelectedDriverMate = this.AllDrivers.find(x => x.Id === this.selectedEventReviewObj.DriverMateId);
    //console.log(this.SelectedDriver);
    this.ComplianceIncidentTasks.forEach(item => {
      if (item.CelebrateFlag === true || item.CoachFlag === true) {
        this.SelectedComplianceTasks.push(item);
      }
    });
  }
  videoModal: boolean = true;

  maxmize() {
    this.maxClass = true;
    this.expand = !this.expand
    jQuery(".max").each(function () {
      this.requestFullscreen();
    })
    this.maxVideo = false;

  }
  minimize() {
    this.maxVideo = true;
    this.maxClass = false;
    this.videoModal = false;
    this.selectedevidencevideo = [];

  }
}
