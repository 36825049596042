<div class="row dashboard">
  <div class="main-title col">
    <h4 class="pull-left">Dashboard</h4>
    <span class="pull-right">Last Updated : {{DashboardLastUpdated}} </span>
    <span class="pull-right pr-1" *ngIf="Updatedashboardchk"><a (click)="getDashlets(DashboardId,1)"><i class="fad fa-sync-alt"></i></a></span>
  </div>
  <div class="card-scroll row no-m">
    <ng-container *ngFor="let dashlet of dashletsArray;">
      <div class="col-lg-4 col-md-6 col-sm-12 col-12" *ngIf="dashlet.ChartType=='value'">
        <div class="widget">
          <div class="widget-content2 card">
            <div class="card-stats">
              <div class="row box-style">
                <div class="col-4">
                  <!--[style.background-color]="dashlet.Color"-->
                  <div class="card-header" [ngClass]="dashlet.Color">
                    <i class="{{dashlet.Icon}}" style="color:#fff;"></i>
                  </div>
                </div>
                <div class='col-8 pull-right'>
                  <span class='title' [style.color]='dashlet.Color'>
                    {{dashlet.DashletTitle}}
                  </span>
                  <p><span *ngIf="dashlet.dashletdata.IsAllVehicle">All Vehicle || </span><span> {{dashlet.dashletdata.TimeLevel}}</span></p>
                </div>
              </div>
              <div class='col-xs-12 pt-1 pb-1' [ngClass]="dashlet.height">
                <h1 *ngIf="dashlet.KPI==1">{{dashlet.dashletdata.Total}} <span class='hours'>Events</span></h1>
                <h1 *ngIf="dashlet.KPI==3">{{dashlet.dashletdata.Count}}</h1>
                <h1 *ngIf="dashlet.KPI==7">{{dashlet.dashletdata.TimeDuration}} <span class='hours'>hh:mm</span></h1>
                <h1 *ngIf="dashlet.KPI==8">{{dashlet.dashletdata.IdleTime}} <span class='hours'>hh:mm</span></h1>
                <h1 *ngIf="dashlet.KPI==9">{{dashlet.dashletdata.Total}} <span class='hours'>Fleets</span></h1>
                <h1 *ngIf="dashlet.KPI==18">{{dashlet.dashletdata.Total}} <span class='hours'>Events</span></h1>
                <h1 *ngIf="dashlet.KPI==20">{{dashlet.dashletdata.Total}} <span class='hours'>Events</span></h1>
                <h1 *ngIf="dashlet.KPI==22">{{dashlet.dashletdata.Total}} <span class='hours'>Events</span></h1>
                <h1 *ngIf="dashlet.KPI==24">{{dashlet.dashletdata.Total}} <span class='hours'>Events</span></h1>
                <h1 *ngIf="dashlet.KPI==26">{{dashlet.dashletdata.Total}} <span class='hours'>Requests</span></h1>
                <h1 *ngIf="dashlet.KPI==27">
                  {{dashlet.dashletdata.Low}} <span class='hours'>Pending.</span>
                  {{dashlet.dashletdata.Medium}} <span class='hours'>Assigned.</span>
                  {{dashlet.dashletdata.Total}} <span class='hours'>Completed.</span>
                </h1>
                <div *ngIf="dashlet.KPI==16" id="chart_divgauge"></div>
                <div *ngIf="dashlet.KPI==17" id="piechart"></div>
              </div>
            </div>
          </div>
          <div class="" [ngClass]="dashlet.Color">

          </div>
        </div>

      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12" *ngIf="dashlet.ChartType=='table'">
        <div class="widget">
          <div class="widget-content2 card">
            <div class="card-stats">
              <div class="row box-style">
                <div class="col-4">
                  <!--[style.background-color]="dashlet.Color"-->
                  <div class="card-header" [ngClass]="dashlet.Color">
                    <i class="{{dashlet.Icon}}" style="color:#fff;"></i>
                  </div>
                </div>
                <div class='col-8 pull-right'>
                  <span class='title' [style.color]='dashlet.Color'>
                    {{dashlet.DashletTitle}}
                  </span>
                  <!-- <p><span *ngIf="dashlet.dashletdata.IsAllVehicle">All Vehicle || After 48 Hrs</span><!--<span> {{dashlet.dashletdata.TimeLevel}}</span></p>-->
                </div>
              </div>
              <div class='col-xs-12 pt-1 pb-1' [ngClass]="dashlet.height" style="overflow:scroll;">
                <table class="table table-striped evidence_table">
                  <thead>
                    <tr class="trhead">
                      <th scope="col">VRN</th>
                      <th scope="col">Event Type</th>
                      <th scope="col">Date Created</th>
                    </tr>
                  </thead>
                  <tbody *ngIf="dashlet.dashletdata.EventPendingsList.length!=0">
                    <tr *ngFor="let x of dashlet.dashletdata.EventPendingsList;" (click)="goToIncident(x)">
                      <td>{{x.VRN}}</td>
                      <td>{{x.EventTypeName}}</td>
                      <td>{{x.DateCreated}}</td>
                    </tr>
                  </tbody>
                </table>
                <div *ngIf="dashlet.dashletdata.EventPendingsList.length==0" class="tab-alert no-records">
                  <h5>No Records <span>Found.!</span></h5>
                </div>
              </div>
            </div>
          </div>
          <div class="" [ngClass]="dashlet.Color">

          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12" *ngIf="dashlet.ChartType=='bar'&&(dashlet.dashletdata.High!=0||dashlet.dashletdata.Medium!=0||dashlet.dashletdata.Low!=0)">
        <div class="widget">
          <div class="widget-content2 card">
            <div class="card-stats">
              <div class="row box-style">
                <div class="col-4">
                  <div class="card-header" [ngClass]="dashlet.Color">
                    <i class="{{dashlet.Icon}}" style="color:#fff;"></i>
                  </div>
                </div>
                <div class='col-8 pull-right'>
                  <span class='title' [style.color]='dashlet.Color'>
                    {{dashlet.DashletTitle}}
                  </span>
                  <p><span *ngIf="dashlet.dashletdata.IsAllVehicle">All Vehicle || </span><span> {{dashlet.dashletdata.TimeLevel}}</span></p>
                </div>
              </div>
              <div class='col-xs-12 pt-1 pb-1' [ngClass]="dashlet.height">
                <!--<google-chart #chart
                      [type]="dashlet.btype"
                      [title]="dashlet.btitle"
                      [data]="dashlet.bdata"
                      [columnNames]="dashlet.bcolumns"
                      [options]="dashlet.boptions">
        </google-chart>-->
                <div #DrivingEventsPerDayChart *ngIf="dashlet.KPI==2"></div>
                <div #GshockEventsPerDayChart *ngIf="dashlet.KPI==21"></div>
                <div #AccidentEventsPerDayChart *ngIf="dashlet.KPI==23"></div>
                <div #IncidentEventsPerDayChart *ngIf="dashlet.KPI==25"></div>
                <div #EmergencyEventsPerDayChart *ngIf="dashlet.KPI==19"></div>
              </div>
            </div>
          </div>
          <div class="" [ngClass]="dashlet.Color">
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
