 
<div class="row m-0">
  <div class="col-lg-3 col-md-12 col-sm-12 col-12 pt-3 viewSection card-scroll-full">
    <div class="text-center">
      <img [src]="profilepic" width="130" class="img-thumbnail" style="height: 130px; border-radius: 50%;" *ngIf="profilepic!=null">
      <span *ngIf="profilepic==null">
        <img src="../../assets/images/nouserimage.png" width="130" class="img-thumbnail" style="width:140px;">
      </span>
      <h6 class="pt-2">
        {{FirstName | uppercase}}{{LastName | uppercase}} - {{RoleName | uppercase}}
      </h6>
    </div>
    <div class="mt-3">
      <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item"><a class="nav-link active" data-toggle="tab" href="#profile1"><i class="fad fa-user"></i>  &nbsp; Personal Details</a></li>
        <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#profile2"><i class="fad fa-key"></i> &nbsp;Change Password</a></li>
      </ul>
    </div>
  </div>
  <div class="col-lg-9 col-md-12 col-sm-12 col-12 p-0">
    <div class="tab-content">
      <div class="tab-pane fade in active show" id="profile1" role="tabpanel">
        <div class="row no-m lb-head-single">
          <h6 class="col my-auto no-p">Personal Details</h6>
          <i tooltip="Edit" tooltip-placement="left" class="far fa-user-edit float-right" (click)="editUser()"></i>
        </div>
        <div class="card-scroll-single tab_card_scroll_one">
          <form class="p-1" name="userform" (ngSubmit)="submitUserForm()" [formGroup]="UserForm">
            <div class="row no-m">
              <div class="col-md-5 pl-05">
                <div class="form-group">
                  <label>First Name</label>
                  <input type="text" name="FirstName" class="form-control" formControlName="FirstName" maxlength="20" [ngClass]="{'disabled':userinpdisabled}" required>
                </div>
                <div class="form-group">
                  <label>Email</label>
                  <input type="text" name="Email" class="form-control" formControlName="Email" [ngClass]="{'disabled':userinpdisabled}" required>
                </div>
                <div class="form-group">
                  <label for="ContactNumber">Profile Picture</label>
                  <br />
                  <div *ngIf="visiblefilup">
                    <input type="file" class="form-control p-0" name="ProfilePicture" formControlName="ProfilePicture" [(ngModel)]="ProfilePicture_" (change)="ProfilePicture($event.target.files)" accept="image/*" [ngClass]="{'disabled':userinpdisabled}" />
                    <img src="../../assets/images/ButtonProcessing.gif" *ngIf="profilepicture" />
                  </div>

                  <div *ngIf="existedpropic">

                    <div class="form-group">
                    
                      <input type="text" name="Profilename" class="form-control" formControlName="Profilename" [ngClass]="{'disabled':userinpdisabled}">
                    </div>
                  </div>
                  <!--<input type="file" class="form-control p-0" name="profilepicture" formcontrolname="profilepicture" [(ngmodel)]="profilepicture_" (change)="profilepicture($event.target.files)" accept="image/*" [ngclass]="{'disabled':userinpdisabled}"/>
                  <img src="../../assets/images/buttonprocessing.gif" *ngif="profilepicture" />-->
                </div>
              </div>
              <div class="col-lg-5 col-md-12 col-sm-12 col-12">
                <div class="form-group">
                  <label>Last Name</label>
                  <input type="text" name="LastName" class="form-control" formControlName="LastName" maxlength="20" [ngClass]="{'disabled':userinpdisabled}" required>
                </div>
                <div class="form-group">
                  <label>Mobile Number</label>
                  <div class="d-flex">
                    <!--<span class="input-group-text"><i class="fas fa-phone-alt"></i></span>-->
                    <select [ngModel]="cntryCode" class="form-control" (change)="resetCode($event)" style="width: 50% !important;" formControlName="CountryCode" [ngClass]="{'disabled':userinpdisabled }">
                      <option *ngFor="let x of CountriesList; index as i" value="{{x.ISDcode}}">{{x.ISDcode}} - {{x.Name}}</option>
                    </select>
                    <input type="text" class="form-control" (keypress)="allowOnlyNo($event)" formControlName="Mobile" maxlength="15" placeholder="Enter Mobile Number" [ngClass]="{'disabled':userinpdisabled}" required>
                  </div>
                </div>
                <div class="form-group">
                  <label>UserName</label>
                  <input type="text" name="UserName" class="form-control" formControlName="UserName" [ngClass]="{'disabled':true}" required>
                </div>
                <div class="form-group">
                  <label>Fleet Name</label>
                  <input type="text" name="FleetName" class="form-control disabled" formControlName="FleetName" required>
                </div>

                <button *ngIf="btndisplay" class="btn btn-success float-right" [disabled]="userinpdisabled==true||!UserForm.valid||profilepicture==true">Submit</button>
              </div>
              <div class="form-group">
                <img src="../../assets/images/ButtonProcessing.gif" *ngIf="submitUserLoader" class="float-right" />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="tab-pane fade" id="profile2" role="tabpanel">
        <div class="row no-m lb-head-single">
          <h6 class="col my-auto no-p">Change Password</h6>
        </div>
        <div class="card-scroll-single">
          <form class="p-1" name="changepassword" (ngSubmit)="submitChangePassword()" [formGroup]="ChangePasswordForm">
            <div class="row no-m">
              <div class="col-md-5 pl-05">
                <div class="form-group">
                  <label>Current Password</label>
                  <input type="password" class="form-control" formControlName="CurrentPassword" [ngClass]="{ 'is-invalid': submittedchangepass && c.CurrentPassword.errors }" required>
                  <div *ngIf="submittedchangepass && c.CurrentPassword.errors" class="invalid-feedback">
                    <div *ngIf="c.CurrentPassword.errors.required">Current Password is required</div>
                  </div>
                </div>
                <div class="form-group">
                  <label>New Password</label>
                  <input type="password" class="form-control" formControlName="NewPassword" [ngClass]="{ 'is-invalid': submittedchangepass && c.NewPassword.errors }" required>
                  <div *ngIf="submittedchangepass && c.NewPassword.errors" class="invalid-feedback">
                    <div *ngIf="c.NewPassword.errors.pattern">
                      <ul class="validationmsg">
                        <li>Min Length 12</li>
                        <li>Max Length 15</li>
                        <li>One Capital</li>
                        <li>One Numeric</li>
                        <li>One Special</li>
                      </ul>
                    </div>
                    <div *ngIf="c.NewPassword.errors.required">New Password is required</div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Confirm Password</label>
                  <input type="password" class="form-control" formControlName="ConfirmPassword" [ngClass]="{ 'is-invalid': submittedchangepass && c.ConfirmPassword.errors }" required>
                  <div *ngIf="submittedchangepass && c.ConfirmPassword.errors" class="invalid-feedback">
                    <div *ngIf="c.ConfirmPassword.errors.required">Confirm Password is required</div>
                    <div *ngIf="c.ConfirmPassword.errors.mustMatch">Passwords must match</div>
                  </div>
                </div>
                <div class="form-group">
                  <button class="btn btn-success float-right mr-3">Submit</button>
                  <img src="../../assets/images/ButtonProcessing.gif" *ngIf="submitPasswordChange" class="float-right" />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
