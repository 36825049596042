<div *ngIf="loading">
  <div class="google-loader">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
  <div class="loader_txt">Loading...</div>
</div>

