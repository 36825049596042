import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpRequest } from '@angular/common/http';
import { NgForm, FormsModule, FormBuilder, FormGroup, Validators, FormControl, ReactiveFormsModule } from '@angular/forms';
import { environment } from '../../environments/environment';
import { MustMatch } from '../helpers/must-match.validator'
import { Title } from "@angular/platform-browser";
import { ToastrService } from 'ngx-toastr';
import { CommonModule } from '@angular/common';

import { NgIf, NgClass } from '@angular/common';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ng2-tooltip-directive';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [NgIf, NgClass, CommonModule, ReactiveFormsModule, FormsModule
    , BsDatepickerModule, TooltipModule, AutocompleteLibModule, NgMultiSelectDropDownModule],
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  UserForm: FormGroup;
  ChangePasswordForm: FormGroup;
  reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('token')
  });
  submitUserLoader: boolean = false;
  profilepic: any;
  RoleName: string;
  FirstName: string;
  FleetName: string;
  LastName: string;
  userid: any;
  cntryCode: string = '91';
  CountriesList: any;
  FleetGroupid: any;
  RoleId: any;
  Profilepic: any;
  submittedchangepass: boolean = false;
  userdata: any;
  submitPasswordChange: boolean = false;
  userinpdisabled: boolean = true;
  profilepicture: boolean = false;
  visiblefilup: boolean = false;
  existedpropic: boolean = true;
  Profilename: any;
  btndisplay: boolean = false;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private toaster: ToastrService,
    private titleService: Title) {
    this.titleService.setTitle("Driver Risk-Resolva | Profile");
  }

  ngOnInit() {
    this.getUserData();
    this.GetCountriesList();
    //user form
    this.UserForm = this.formBuilder.group({
      FirstName: ['', Validators.required],
      FleetName: [''],
      LastName: ['', Validators.required],
      Email: ['', [Validators.required, Validators.email]],
      CountryCode: [''],
      Mobile: ['', Validators.required],
      UserName: ['', Validators.required],
      ProfilePicture: [''],
      Profilename:['']

    });
    //change passsword form
    this.ChangePasswordForm = this.formBuilder.group({
      CurrentPassword: ['', Validators.required],
      NewPassword: [null,
        [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d](?=.*?[^\w\s]).{11,14}$/)]],
      ConfirmPassword: ['', Validators.required]
    },
      {
        validator: MustMatch('NewPassword', 'ConfirmPassword')
      })
  }

  GetCountriesList() {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'No-Auth': 'True' });
    return this.http.get(environment.API_URL + "Countries/", { headers: reqHeader })
      .subscribe(
        (data: any) => {
          console.log(data);
          this.CountriesList = data;
        },
        error => {
          console.log("Error", error);
        }
      );
  };
  //enable user edit fields
  editUser() {
   
    this.userinpdisabled = false;
    this.visiblefilup = !this.visiblefilup;
    this.existedpropic = !this.existedpropic;
    this.btndisplay = !this.btndisplay;
  
  };
  //profile picture upload
  ProfilePicture_: string = "";
  ProfilePicture(files: FileList) {
     
    this.profilepicture = true;
     
    const kb = Math.round(files.item(0).size / 1024);
    const mb = kb / 1024;
    if (mb > 25) {
      this.toaster.error("Please select below 25MB");
      this.ProfilePicture_ = "";
      return;
    }
    if (files[0].name.includes(".png" ||".PNG"||".jpeg"||"JPEG")) {
      const formdata: FormData = new FormData();
      formdata.append('EvidenceFile', files.item(0), files.item(0).name);
       
      return this.http.post("/api/uploadProfilePic", formdata)
        .subscribe(
          (data: any) => {
            this.Profilepic = data.data;
            this.profilepicture = false;
          },
          error => {
            console.log("Error", error);
          }
        );
    }
    else {
      this.toaster.error("Please choose PNG,JPEG format ");
      this.ProfilePicture_ = "";
      this.profilepicture = false;
      return;
    }
    //const formdata: FormData = new FormData();
    //formdata.append('EvidenceFile', files.item(0), files.item(0).name);
    // 
    //return this.http.post("/api/uploadProfilePic", formdata)
    //  .subscribe(
    //    (data: any) => {
    //      this.Profilepic = data.data;
    //      this.profilepicture = false;
    //    },
    //    error => {
    //      console.log("Error", error);
    //    }
    //);


  }
  //get user info
  getUserData() {
    return this.http.get(environment.API_URL + "users/Byusername", { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          console.log(data);
          this.userdata = data;
          this.profilepic = data.blobUserProfileUrl;
          this.RoleName = data.RoleName;
          this.FleetName = data.FleetName;
          this.FirstName = data.FirstName;
          this.LastName = data.LastName;
          this.userid = data.Id;
          this.FleetGroupid = data.FleetGroupid;
          this.RoleId = data.RoleId;
          this.Profilepic = data.Profilepic;
          this.UserForm.controls['FirstName'].setValue(data.FirstName.toUpperCase());
          this.UserForm.controls['FleetName'].setValue(data.FleetName.toUpperCase());
          this.UserForm.controls['LastName'].setValue(data.LastName.toUpperCase());
          this.UserForm.controls['Email'].setValue(data.Email);
          var value = data.PhoneNumber.substring(
            data.PhoneNumber.indexOf("+") + 1,
            data.PhoneNumber.lastIndexOf("-"));
          console.log(value);
          this.cntryCode = value;
           
          this.UserForm.controls['CountryCode'].setValue(value);
          var phn = data.PhoneNumber.split("-");
          console.log(phn[1]);
          this.UserForm.controls['Mobile'].setValue(phn[1]);
          this.UserForm.controls['UserName'].setValue(data.UserName.toUpperCase());
          this.Profilename = data.Profilepic;
          this.UserForm.controls['Profilename'].setValue(data.Profilepic);
   
        },
        error => {
          console.log("Error", error);
        }
      );
  }
  //update user data
  get f() { return this.UserForm.controls; }
  submitUserForm() {
    if (this.UserForm.invalid) {
      return;
    }
    let obj: any = {};
    obj.FirstName = this.f.FirstName.value;
    obj.LastName = this.f.LastName.value;
    obj.Email = this.f.Email.value;
    obj.PhoneNumber = "+" + this.f.CountryCode.value + "-" + this.f.Mobile.value;
    obj.UserName = this.f.UserName.value;
    obj.Id = this.userid;
    obj.FleetGroupid = this.FleetGroupid;
    obj.RoleId = this.RoleId;
    obj.Profilepic = this.Profilepic;


    return this.http.post(environment.API_URL + "users/Update", obj, { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          this.toaster.success("Profile updated successfully...!");
          this.userinpdisabled = true;
          this.UserForm.reset();
          this.getUserData();
          this.visiblefilup = !this.visiblefilup;
          this.existedpropic = !this.existedpropic;
          this.btndisplay = !this.btndisplay;
        },
        error => {
          console.log("Error", error);
        }
      );
  };
  //change password
  get c() { return this.ChangePasswordForm.controls; }
  submitChangePassword() {
    this.submittedchangepass = true;
    if (this.ChangePasswordForm.invalid) {
      return;
    }
    this.submitPasswordChange = true;
    let obj: any = {};
    obj.FirstName = this.FirstName;
    obj.LastName = this.LastName;
    obj.UserName = this.userdata.UserName;
    obj.CurrentPassword = this.c.CurrentPassword.value;
    obj.NewPassword = this.c.NewPassword.value;
    obj.ConfirmPassword = this.c.ConfirmPassword.value;
    obj.UserId = this.userdata.Id;

    return this.http.post(environment.API_URL + "users/changePassword", obj, { headers: this.reqHeader })
      .subscribe(
      (data: any) => {
          if (data === true) {
            this.toaster.success("Password updated successfully...!");
            this.submittedchangepass = false;
            this.submitPasswordChange = false;
            this.ChangePasswordForm.reset();
          } else {
            const errorMessages = {
              "Current Password Incorrect check Properly!": "Current Password Incorrect check Properly!",
              "password must differ from old passwords": "password must differ from old passwords",
              "Passwords should not be weak": "Password Must be strong"
            };
            const errorMessage = errorMessages[data] || "An unknown error occurred";

            this.toaster.error(errorMessage);
            this.ChangePasswordForm.controls['CurrentPassword'].setValue(null);
            this.submitPasswordChange = false;
          }

        },
        error => {
          console.log("Error", error);
          this.submitPasswordChange = false;
        }
      );
  };
  resetCode(val) {
    console.log(this.f.CountryCode.value);
  }

  allowOnlyNo(event: any) {
    const pattern = /[0-9\+\-\: ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    };
  }

}
