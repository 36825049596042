<div class="pre-loader" *ngIf="preloader==true">
  <div class="load-icon">
    <div class="ring-load">
      <div class="circle"></div>
      <!--<img src="assets/images/Favicon.svg" />-->
      <div class="pageload-icon"></div>
    </div>
  </div>
</div>

<!--<div class="live Events_new ox_hidden">-->
<div class="live Events_new">
  <div class="row m-0">

    <div class="col-lg-12 col-md-12 col-sm-12 col-12 p-0 lap2 Events_new_right">
      <div class="bod_left ox_hidden">
        <!--summary section
        *ngIf="summarysection==true && incidensection==false && viewManInc==false"-->
        <div class="summarySection">
          <div class="row no-m" *ngIf="exp" [hidden]="">
            <div class="lb-head-single col-12">
              <!--<h6>Incidents statistics</h6>-->
              <h6>Events statistics</h6>
            </div>
            <div class="col-8">
              <div class="row">
                <div class="col-6 no-p" *ngFor="let fleets of IncidentsList;index as i">
                  <div class="panel p-3">
                    <div class="fad fa-bells panelbg panel-blue"> </div>
                    <div class="panel-info">
                      <h4 class="panel-count">{{fleets?.EventsList?.length}}</h4>
                      <h4 class="panel-title">{{fleets?.FleetName}}</h4>
                    </div>
                  </div>
                </div>
                <div class="col-6 no-p">
                  <div class="panel p-3">
                    <div class="fad fa-user-check panelbg panel-blue"> </div>
                    <div class="panel-info">
                      <h4 class="panel-count">{{AssignedEvents?.length}}</h4>
                      <h4 class="panel-title">My List</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--summary section-->
        <!--Audit section-->
        <div class="AuditSection" *ngIf="false">
          <div class="p-3">
            <div class="row d-flex no-m" style="margin-bottom:10px !important;">
              <div class="col-2 no-p">
                <h5 class="Heading_h5">Audit</h5>
              </div>
              <div class="col-4 pr-05">
              </div>
              <div class="col-2 pr-05">
                <select class="form-control">
                  <option> All</option>
                </select>
              </div>
              <div class="col-2 pl-0">
                <select class="form-control">
                  <option> Today</option>
                </select>
              </div>
              <div class="col-2 pl-0">
                <input type="text" class="col form-control" placeholder="Search" style="width: 140px;">
                <i class=" ml-2 far fa-filter pull-right" data-target="#" data-toggle="modal" placement="left" tooltip="Filters"></i>
              </div>
            </div>
            <div class="row no-m">
              <table class=" table table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th class="tbl_th_tr" style="width: 22%;">Name</th>
                    <th class="tbl_th_tr" style="width: 13%;">Modified</th>
                    <th class="tbl_th_tr" style="width: 22%;">Shared by</th>
                    <th class="tbl_th_tr">Activity</th>
                  </tr>
                </thead>
                <tbody class="">
                  <tr *ngFor="let audit of auditDetails; index as i">
                    <td>{{audit?.name}}</td>
                    <td>{{audit?.closingTime |timeform}}</td>
                    <td>{{audit?.sharedBy}}</td>
                    <td>
                      <span class="mr-1"> <b class="font-w-6">{{audit?.sharedBy}}</b> {{audit?.activity}}</span>
                      <span class="float-right">{{audit?.closingTime |date:'short'}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!--Audit section Ends-->
        <!--incident analyze window
        *ngIf="incidensection&&!viewManInc&&!summarysection"
          -->
        <div class="incident_details_section" id="eventMap">
          <div class="row ml-0 lb-head-single tab_header_sticky">
            <div class="col-3 p-0">
              <h6 class="font13 txt_overflow mt-03">{{vehicle?.RegistrationNumber}} : {{incidetData?.displayname}}</h6>
            </div>
            <ul class="nav nav-tabs col-8 p-0" role="tablist">
              <li class="txt_tab" [ngClass]="tabChange=='overview'?'active_tab':''" (click)="changeTab('overview')"><a>Overview</a></li>
              <li class="txt_tab" [ngClass]="tabChange=='evidence'?'active_tab':''" (click)="changeTab('evidence')"><a>Evidence</a></li>
              <li class="txt_tab" [ngClass]="tabChange=='additional Details'?'active_tab':''" (click)="changeTab('additional Details')"><a>Additional Details</a></li>
              <li class="txt_tab" [ngClass]="tabChange=='request'?'active_tab':''" (click)="changeTab('request')">
                <a>
                  Requests
                  <span class="badge badge-warning">{{Requests?.length}}</span>
                </a>
              </li>
              <!--*ngIf="incidetData?.eventReviewStatus?.ClassificationId==1 ||  incidetData?.eventReviewStatus?.ClassificationId==2 || incidetData?.eventReviewStatus?.ClassificationId==4"-->
              <li class="txt_tab" [ngClass]="tabChange=='compliance'?'active_tab':''" (click)="changeTab('compliance')">
                <a>
                  Compliance
                </a>
              </li>
              <li class="txt_tab" [ngClass]="tabChange=='AuditHistory'?'active_tab':''" (click)="changeTab('AuditHistory')">
                <a>Audit</a>
              </li>
            </ul>
            <div class="col-1 p-0 cc_col-1">
              <a class="close" (click)="closeManInc()">&times;</a>
            </div>
          </div>
          <div class="tab-content card-scroll" [ngClass]="{'video_tab_content':Viewmap_Str=='Video','events_tab_content':Viewmap_Str=='Event'}">
            <div class="" id="one" *ngIf="tabChange=='overview'">
              <div class="row">
                <div class="col-lg-4 col-md-12 col-sm-12 col-12">
                  <div class="fleetNamesSection mb-02">
                    <a class="row no-m" data-toggle="collapse" href="#incident" aria-expanded="true">
                      <div class="col-12 p-0">
                        <h6>Incident</h6>
                        <div class="col-xs-1 pull-right">
                          <span class="collapsed"><i class="far fa-angle-right"></i></span>
                          <span class="expanded"><i class="far fa-angle-down"></i></span>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div class="collapse show" id="incident">
                    <div class="pop-live">
                      <div class="time">
                        <i class="far fa-tachometer"></i>{{incidetData?.spd}}
                        <span *ngIf="incidetData?.stringmileage=='KMS'">kph</span>
                        <span *ngIf="incidetData?.stringmileage!='KMS'">mph</span>
                      </div>
                      <div class="time"><i class="far fa-clock"></i>{{incidetData?.time}}</div>
                      <div class="pop-body">
                        <i class="far fa-map-marker-alt"></i> {{incidetData?.LocationAddress}}
                      </div>
                      <div class="row mt-05">
                        <div class="col-6 pl-0">
                          <div class="col text-left" *ngIf="incidetData?.EventAnalyticsFile!=null">
                            <a class=" btn btn-charcol" (click)="eventAnalyzeFun()" data-toggle="modal" data-target="#analyze">Analyze</a>
                          </div>
                        </div>
                        <div class="col-6 pt-05">
                          <div>
                            <a class=" add_link mr-3" *ngIf="audituserdata.Assigned == false && audituserdata.Status==0" (click)="userAssigned()">Assign</a>
                            <a class="add_link mr-3" *ngIf="audituserdata.Assigned == true && audituserdata.Status==1" (click)="userUnAssigned()">Unassign</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 col-12">
                  <div class="fleetNamesSection mb-02">
                    <a class="row no-m" data-toggle="collapse" href="#incident" aria-expanded="true">
                      <div class="col-12 p-0">
                        <h6>Driver</h6>
                        <div class="col-xs-1 pull-right">
                          <span class="collapsed"><i class="far fa-angle-right"></i></span>
                          <span class="expanded"><i class="far fa-angle-down"></i></span>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div class="collapse show" id="incident">
                    <div class="pop-live">
                      <div class="time"><i class="far fa-user"></i>{{driver?.Name}}</div>
                      <div class="time"><i class="far fa-phone-alt"></i>{{driver?.PhoneNumber}}</div>
                      <div class="pop-body" *ngIf="driver.Address !=null">
                        <i class="far fa-map-marker-alt"></i>

                        <span>{{driver.Address}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 col-12">
                  <div class="fleetNamesSection mb-02">
                    <a class="row no-m" data-toggle="collapse" href="#incident" aria-expanded="true">
                      <div class="col-12 p-0">
                        <h6>Vehicle</h6>
                        <div class="col-xs-1 pull-right">
                          <span class="collapsed"><i class="far fa-angle-right"></i></span>
                          <span class="expanded"><i class="far fa-angle-down"></i></span>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div class="collapse show" id="incident">
                    <div class="pop-live">
                      <div class="time"><i class="far fa-car"></i>{{vehicle?.RegistrationNumber}}</div>
                      <div class="time"><i class="far fa-car-garage"></i>{{vehicle?.Model}}</div>

                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="audituserdata.Assigned==true" class="p-1 text-center">
                <i class="fa fa-exclamation-triangle red" aria-hidden="true"></i>
                <div class="txt_info_warning">
                  <span class="mr-1"> <i class="fa fa-check-circle"></i> Assigned To: {{audituserdata?.Username}} </span>

                  <span class=""> <i class="far fa-clock"></i> Date&Time: {{audituserdata?.DateUpdatedStr}}</span>
                </div>
              </div>

              <div class="" *ngIf="MapDataStatus">
                <label class="checkbox">
                  <input type="checkbox"
                         [(ngModel)]="MatchedRoadData"
                         (change)="toggleMatchedRoadData(MatchedRoadData)">
                  <span>Use snap Gps</span>
                </label>
              </div>
              <div class="" style="min-height: 500px; padding-bottom: 35px !important;">
                <div class="col-12 no-p">
                  <div class="option_bar">
                    <div class="option" tooltip="Video" placement="left" (click)="changeTab('evidence')" [hide-delay]="0" *ngIf="incidetData?.VideoEnabled==true">
                      <i class="fad fa-video"></i>
                    </div>
                    <div class="option" data-toggle="modal" data-target="#gallery" (click)="OpenGalleryModal('images')" tooltip="Images" placement="left" [hide-delay]="0" *ngIf="incidetData.SnapshotEnabled==true">
                      <i class="fad fa-images"></i>
                    </div>
                    <div class="option" (click)="viewTrip()" tooltip="Trip" placement="left" [hide-delay]="0">
                      <i class="far fa-route"></i>
                    </div>
                  </div>


                  <google-map *ngIf="displayGoogleMaps" [zoom]="mapZoom" [options]="mapOptions"
                              [center]="center$ | async" height="568px" width="1050px"
                              (mapClick)="moveMap($event)" (mapMousemove)="move($event)">


                    <map-ground-overlay [url]="eventIcon"
                                        [bounds]="bounds"
                                        [position]="{ lat: incidetData.lat, lng: incidetData.lng }">

                    </map-ground-overlay>
                    <map-marker #markerElem1="mapMarker"
                                [position]="{ lat: incidetData.lat, lng: incidetData.lng }"
                                [options]="{mapTypeId: 'hybrid',  scrollwheel: true, icon: eventicon}"
                                (mapClick)="openInfoWindow(markerElem1,infoWindow1)">
                      <map-info-window #infoWindow1="mapInfoWindow">
                        <div class="pop-live">
                          <div class="time"><i class="far fa-tachometer"></i>{{incidetData?.displayname}}</div>
                          <div class="time">
                            <i class="far fa-tachometer"></i>{{incidetData?.spd}}
                            <span *ngIf="incidetData?.stringmileage=='KMS'">kph</span>
                            <span *ngIf="incidetData?.stringmileage!='KMS'">mph</span>
                          </div>
                          <div class="time"><i class="far fa-clock"></i>{{incidetData?.time_event}}</div>
                          <div class="pop-body">
                            <i class="far fa-map-marker-alt"></i> {{incidetData?.LocationAddress}}
                          </div>
                        </div>
                      </map-info-window>
                    </map-marker>


                    <map-marker #markerElem2="mapMarker"
                                [position]="{lat: tripDataObj.startLat, lng: tripDataObj.startLng}"
                                [options]="{mapTypeId: 'hybrid',  scrollwheel: true, icon: starticon}"
                                (mapClick)="openInfoWindow(markerElem2,infoWindow2)">
                      <map-info-window #infoWindow2="mapInfoWindow">
                        <div class="pop-live">
                          <div class="time"><i class="far fa-clock"></i>{{tripDataObj?.startTime}}</div>
                          <div class="pop-body">
                            <i class="far fa-map-marker-alt"></i> {{tripDataObj?.startLoc}}
                          </div>
                        </div>
                      </map-info-window>
                    </map-marker>


                    <map-marker #markerElem3="mapMarker"
                                [position]="{lat: tripDataObj?.endLat, lng: tripDataObj?.endLng}"
                                [options]="{mapTypeId: 'hybrid',  scrollwheel: true, icon: endicon}"
                                (mapClick)="openInfoWindow(markerElem3,infoWindow3)">
                      <map-info-window #infoWindow3="mapInfoWindow">
                        <div class="pop-live">
                          <div class="time"><i class="far fa-clock"></i>{{tripDataObj?.endTime}}</div>
                          <div class="pop-body">
                            <i class="far fa-map-marker-alt"></i> {{tripDataObj?.endLoc}}
                          </div>
                        </div>
                      </map-info-window>
                    </map-marker>


                    <!--<map-polyline *ngFor="let point of polyline;let i = index;" [strokeWeight]="6" [strokeColor]="point?.spd < 100 ? '#2196f3': 'red'">
                      <ng-container *ngIf="MatchedRoadData">
                        <map-polyline-point [position]="{lat: point?.mapdata?.lat, lng: point?.mapdata?.lng}">
                        </map-polyline-point>
                        <ng-container *ngIf="polyline[i+1]">
                          <map-polyline-point [position]="{lat: polyline[i+1].mapdata.lat, lng: polyline[i+1].mapdata.lng}">
                          </map-polyline-point>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="!MatchedRoadData">
                        <map-polyline-point [position]="{lat: point?.lat, lng: point?.lng}">
                        </map-polyline-point>
                        <ng-container *ngIf="polyline[i+1]">
                          <map-polyline-point [position]="{lat: polyline[i+1]?.lat, lng: polyline[i+1]?.lng}">
                          </map-polyline-point>
                        </ng-container>
                      </ng-container>
                    </map-polyline>-->


                  </google-map>




                </div>
              </div>
            </div>
            <div class="" *ngIf="tabChange=='evidence'">
              <table class="table table-striped evidence_table">
                <thead>
                  <tr class="trhead">
                    <th scope="col">Type</th>
                    <!--<th scope="col">File name</th>-->
                    <th scope="col">Created by</th>
                    <th scope="col">Date</th>
                    <!--<th scope="col">Address</th>-->
                    <th scope="col" class="text-center">Action</th>
                  </tr>
                </thead>
                <tbody *ngIf="incidentServercommands?.length!=0||evidenceList?.length!=0">
                  <tr *ngFor="let Evidences of incidentServercommands;">
                    <td>Video</td>
                    <!--<td>Command - {{Evidences.CommandTemplate}}</td>-->
                    <td>{{Evidences?.CreatedBy}}</td>
                    <td>{{Evidences?.TimeSentToDevice}}</td>
                    <!--<td>{{incidetData.LocationAddress}}</td>-->
                    <td class="text-center" *ngIf="Evidences.Status=='ReceivedFromDevice'"><a data-toggle="modal" data-target="#evidencevideo" (click)="playevidencevideo(Evidences)"><i class="fad fa-play-circle fa-2x text-green"></i></a></td>
                    <td *ngIf="Evidences.Status=='Raised'">Command Sent</td>
                    <td *ngIf="Evidences.Status=='SentToDevice'">
                      InProgress
                    </td>
                    <td *ngIf="Evidences.Status=='Signalled'">Signalled</td>
                    <td *ngIf="Evidences.Status=='PostProcessingReboot'">PostProcessing Reboot</td>
                    <td *ngIf="Evidences.Status=='ProcessingError'" class="text-center">
                      <span class="badge badge-danger" style="font-size:11px;">
                        <strong>Declined !</strong>
                      </span>
                    </td>
                  </tr>
                  <tr *ngFor="let Evidences of evidenceList;">
                    <td *ngIf="Evidences.EvidenceRequestId==1">Video</td>
                    <td *ngIf="Evidences.EvidenceRequestId==2">Image</td>
                    <td *ngIf="Evidences.EvidenceRequestId==3">stDocument</td>
                    <td *ngIf="Evidences.EvidenceRequestId==4">Other</td>
                    <!--<td>{{Evidences.Url}}</td>-->
                    <td>{{Evidences?.Name}}</td>
                    <td>{{Evidences?.DateAdded}}</td>
                    <!--<td>{{Evidences.Address}}</td>-->
                    <td class="text-center">
                      <a class="text-right col-2 no-p" data-toggle="modal" data-target="#ViewRequestModal2" (click)="viewRequest2(Evidences)">
                        <i class="far fa-arrow-alt-circle-right view_icon" tooltip="View" placement="left" [hide-delay]="0"></i>
                      </a>
                      <!--<a href="{{Evidences.BlobUrl}}" target="_blank">
                                        <i class="fad fa-eye fa-2x"></i>
                                        <i class="fad fa-play-circle fa-2x" *ngIf="Evidences.EvidenceRequestId=='Video'"></i>
                      <i class="fad fa-eye fa-2x" *ngIf="Evidences.EvidenceRequestId=='Document'"></i>
                                        </a>-->
                    </td>


                  </tr>
                </tbody>
              </table>
              <div class="tab-alert no-records" *ngIf="incidentServercommands?.length==0 && evidenceList?.length==0">
                <h5>No Records <span>Found.!</span></h5>
              </div>
              <!--<a class="btn btn-charcol mr-1 float-right pagination_btn">Next</a>
              <a class="btn btn-charcol mr-1 float-right pagination_btn">Prev</a>-->
            </div>
            <div class="" id="four" *ngIf="tabChange=='additional Details'">
              <div class="row">
                <div class="col-12">
                  <table class="table table-striped evidence_table">
                    <thead>
                      <tr class="trhead">
                        <th scope="col">Type</th>
                        <th scope="col">Created by</th>
                        <th scope="col">Contact</th>
                        <!--<th scope="col">File name</th>-->
                        <!--<th scope="col">Added by</th>-->
                        <th scope="col">Date</th>
                        <!--<th scope="col">Notes</th>-->
                        <th class="text-center" scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody *ngIf="evidenceList?.length!=0">
                      <tr *ngFor="let Evidences of evidenceList;">
                        <td *ngIf="Evidences.EvidenceRequestId==1">Video</td>
                        <td *ngIf="Evidences.EvidenceRequestId==2">Image</td>
                        <td *ngIf="Evidences.EvidenceRequestId==3">Documentd</td>
                        <td *ngIf="Evidences.EvidenceRequestId==4">Other</td>
                        <td>{{Evidences?.Name}}</td>
                        <td>{{Evidences?.ContactNumber}}</td>

                        <!--<td>{{Evidences.Url}}</td>-->
                        <!--<td>{{Evidences.UserName}}</td>-->
                        <td>{{Evidences?.DateAdded}}</td>

                        <!--<td>{{Evidences.Notes}}</td>-->
                        <td class="text-center">
                          <a class="text-right col-2 no-p" data-toggle="modal" data-target="#ViewRequestModal2" (click)="viewRequest2(Evidences)">
                            <i class="far fa-arrow-alt-circle-right view_icon" tooltip="View" placement="left" [hide-delay]="0"></i>
                          </a>
                        </td>

                      </tr>
                    </tbody>
                  </table>
                  <!--<a class="btn btn-charcol float-right mt-2" data-toggle="modal" data-target="#addAdditionalInfo">Add</a>-->
                  <button style="float:right;" class="btn_plus  btn btn-success" *ngIf="selectedEventReviewObj?.StatusId!=2" data-toggle="modal" data-target="#addAdditionalInfo">
                    <span> + </span>
                  </button>
                  <!--<div class="right_tab_alert">-->
                  <div class="">
                    <div class="tab-alert no-records" *ngIf="evidenceList?.length==0">
                      <h5 class="mt-3">No Records <span>Found.!</span></h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="" id="Requests" *ngIf="tabChange=='request'">
              <div class="row no-m justify-content-center pt-1">
                <div class="form-group col-3">
                  <select [(ngModel)]="RequestsFilterModal" (change)="GetRequestList(RequestsFilterModal)" class="form-control">
                    <option value="0">Completed/New</option>
                    <option *ngFor="let item of RequestFilters; index as i" value="{{item.Id}}">{{item.Name}}</option>
                  </select>
                </div>
                <!--<div class="form-group col-4">
                  <input type="text" class="form-control" value="" placeholder="Search" [(ngModel)]="searchRequests">
                </div>-->
              </div>
              <div class="row no-m">
                <table class="table table-striped evidence_table">
                  <thead>
                    <tr class="trhead">
                      <th scope="col">Type</th>
                      <th scope="col">Recipient</th>
                      <th scope="col">Created date</th>
                      <th scope="col">Due date</th>
                      <th scope="col" width="25%">Subject</th>
                      <th scope="col" class="text-center">Read</th>
                      <th scope="col" class="text-center">Status</th>
                    </tr>
                  </thead>
                  <tbody *ngIf="Requests?.length!=0">
                    <tr *ngFor="let Req of Requests | searchFilter: { DriverName:searchRequests, ManagerName: searchRequests,DueDateString:searchRequests,CreatedDateString:searchRequests};">
                      <td *ngIf="Req.RequestTypeId==1">Video</td>
                      <td *ngIf="Req.RequestTypeId==2">Image</td>
                      <td *ngIf="Req.RequestTypeId==3">Document</td>
                      <td *ngIf="Req.RequestTypeId==4">Other</td>
                      <td *ngIf="Req.DriverName!=null">{{Req?.DriverName}} ({{Req?.ReceipientTypeId}})</td>
                      <td *ngIf="Req.ManagerName!=null">{{Req?.ManagerName}} ({{Req?.ReceipientTypeId}})</td>
                      <td>{{Req?.CreatedDateString}}</td>
                      <td>
                        <span *ngIf="Req.Status==1||Req.Status==2" [ngClass]="{'text-danger':Req.DueDateStatus==2,'text-success':Req.DueDateStatus==1}">{{Req.DueDateString}}</span>
                        <span *ngIf="Req.Status==3||Req.Status==4">-</span>
                      </td>
                      <td>{{Req?.Subject}}</td>
                      <td *ngIf="Req.ReadStatus==1" class="text-center"><i class="far fa-envelope"></i></td>
                      <td *ngIf="Req.ReadStatus==2" class="text-center text-success"><i class="far fa-envelope-open"></i></td>
                      <td class="text-center">
                        <span *ngIf="Req.Status==1||Req.Status==2">New</span>
                        <a class="text-right col-2 no-p" data-toggle="modal" data-target="#ViewRequestModal" (click)="viewRequest(Req)" *ngIf="Req.Status==3">
                          <i class="far fa-arrow-alt-circle-right view_icon" tooltip="View" placement="left" [hide-delay]="0"></i>
                        </a>
                        <a class="text-right col-2 no-p" data-toggle="modal" data-target="#ViewRequestModal2" (click)="viewRequest(Req)" *ngIf="Req.Status==4">
                          <i class="far fa-arrow-alt-circle-right view_icon" tooltip="View" placement="left" [hide-delay]="0"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!--<a class="btn btn-success mr-3" data-toggle="modal" data-target="#RequestOnIncident" >Add Request</a>-->

                <div class="w-95">
                  <div class="tab-alert no-records" *ngIf="Requests?.length==0">
                    <h5>No Records <span>Found.!</span></h5>
                  </div>
                </div>
                <div class="no-p">
                  <!--<button style="float:right;" type="button" class="btn_plus  btn btn-success" (click)="CreateRequestForm()" data-toggle="modal" data-target="#RequestOnIncident">
                    <span> + </span>
                  </button>-->
                  <a style="float:right;" *ngIf="selectedEventReviewObj?.StatusId!=2" class="btn btn-success float-right mt-1" (click)="CreateRequestForm()" data-toggle="modal" data-target="#RequestOnIncident">
                    <span> + </span>
                  </a>
                </div>
              </div>
            </div>
            <div class="" *ngIf="tabChange=='compliance'">
              <div class="row mt-1 top_fix">
                <div class="col-md-6">
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 col-12 text-right">
                  <div *ngIf="incidetData?.eventReviewStatus==null">
                    <a class="btn btn-charcol mr-2" [ngClass]="{'btn-success' : selectedEventReviewObj.ClassificationId == 1}" (click)="eventAuditFun1(1)">Incident</a>
                    <a class="btn btn-charcol mr-2" [ngClass]="{'btn-success' : selectedEventReviewObj.ClassificationId == 2}" (click)="eventAuditFun1(2)">Accident</a>
                    <a class="btn btn-charcol mr-2 mb-1-tab" [ngClass]="{'btn-success' : selectedEventReviewObj.ClassificationId == 3}" (click)="eventAuditFun1(3)">Dismissed</a>
                    <a class="btn btn-charcol mr-2" [ngClass]="{'btn-success' : selectedEventReviewObj.ClassificationId == 4}" (click)="eventAuditFun1(4)">Intervention</a>
                  </div>
                  <div *ngIf="incidetData?.eventReviewStatus!=null">
                    <button class="btn btn-charcol mr-2" [ngClass]="{'btn-success' : selectedEventReviewObj.ClassificationId == 1}" (click)="eventAuditFun1(1)" [disabled]="selectedEventReviewObj?.StatusId == 2">Incident</button>
                    <button class="btn btn-charcol mr-2" [ngClass]="{'btn-success' : selectedEventReviewObj.ClassificationId == 2}" (click)="eventAuditFun1(2)" [disabled]="selectedEventReviewObj?.StatusId == 2">Accident</button>
                    <button class="btn btn-charcol mr-2" [ngClass]="{'btn-success' : selectedEventReviewObj.ClassificationId == 3}" (click)="eventAuditFun1(3)" [disabled]="selectedEventReviewObj?.StatusId == 2">Dismissed</button>
                    <button class="btn btn-charcol mr-2" [ngClass]="{'btn-success' : selectedEventReviewObj.ClassificationId == 4}" (click)="eventAuditFun1(4)" [disabled]="selectedEventReviewObj?.StatusId == 2">Intervention</button>
                  </div>
                </div>
              </div>
              <div class="row pt-15 pb-15 pl-15 pr-07">
                <div class="col-6">
                  <div class="Manger_comp mb-2  pl-0">
                    <h5 class="pt-2 mb-1 Heading_h5">Manager</h5>
                    <select class="form-control" [(ngModel)]="selectedEventReviewObj.ManagerId" [disabled]="selectedEventReviewObj?.StatusId == 2">
                      <option value="" selected>select</option>
                      <option *ngFor="let managers of AllManagers" [ngValue]="managers.Id">{{managers?.FirstName}} {{managers?.LastName}}</option>
                    </select>
                    <h5 class="mb-1 compliance_notes_heading_txt">Notes:</h5>
                    <textarea class="compliance_txtarea" [disabled]="selectedEventReviewObj?.StatusId == 2" maxlength="999" [(ngModel)]="selectedEventReviewObj.ManagerNotes"> </textarea>
                  </div>
                  <div class="Manger_comp mb-2  pl-0">
                    <h5 class="pt-2 mb-1 Heading_h5">Driver</h5>
                    <select class="form-control" [(ngModel)]="selectedEventReviewObj.ComplianceDriverId" [disabled]="selectedEventReviewObj?.StatusId == 2">
                      <option value="" selected>select</option>
                      <option *ngFor="let driver of AllDrivers" [ngValue]="driver.Id">{{driver?.Name}}</option>
                    </select>
                    <h5 class="mb-1 compliance_notes_heading_txt">Notes:</h5>
                    <textarea class="compliance_txtarea" [disabled]="selectedEventReviewObj?.StatusId == 2" maxlength="999" [(ngModel)]="selectedEventReviewObj.DriverNotes"> </textarea>
                  </div>
                  <div class="Manger_comp mb-2  no-p">
                    <div>
                      <h5 class="pt-2 mb-1 Heading_h5">Driver Mate</h5>
                      <select class="form-control" [(ngModel)]="selectedEventReviewObj.DriverMateId" [disabled]="selectedEventReviewObj?.StatusId == 2">
                        <option value="" selected>select</option>
                        <option *ngFor="let driver of AllDrivers" [ngValue]="driver.Id">{{driver?.Name}}</option>
                      </select>
                      <h5 class="mb-1 compliance_notes_heading_txt">Notes:</h5>
                      <textarea class="compliance_txtarea" [(ngModel)]="selectedEventReviewObj.DriverMateNotes" maxlength="999" [disabled]="selectedEventReviewObj?.StatusId == 2"> </textarea>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="Manger_comp mb-2 no-p">
                    <table class="table table-striped evidence_table">
                      <thead>
                        <tr class="trhead">
                          <th>Incident Task</th>
                          <th class="text-center">Celebrate</th>
                          <th class="text-center">Coach</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let x of ComplianceIncidentTasks">
                          <td>{{x.TypeName}}</td>
                          <td class="text-center">
                            <label class="checkbox">
                              <input type="checkbox" [(ngModel)]="x.CelebrateFlag">
                              <span> </span>
                            </label>
                          </td>
                          <td class="text-center">
                            <label class="checkbox">
                              <input type="checkbox" [(ngModel)]="x.CoachFlag">
                              <span> </span>
                            </label>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

              </div>





            </div>
            <div class="p-1" id="AuditHistory" *ngIf="tabChange=='AuditHistory'">

              <h5 class="pt-2 Heading_h5">Event History</h5>
              <div *ngIf="incidetData?.eventReviewStatus==null && evidenceList?.length==0 && addnoteslist?.length==0" class="no-records">
                <h5>The event has not yet audited !</h5>
              </div>
              <ul class="timeline m-1" *ngIf="evidenceList?.length==0||addnoteslist?.length==0">
                <li class="timeline-inverted" *ngIf="incidetData?.eventReviewStatus!=null">
                  <div class="timeline-badge warning"><i class="far fa-history"></i></div>
                  <div class="timeline-panel">
                    <div class="timeline-heading">
                      <p class="timeline-title">Audit information</p>
                      <p class="float-right"><small class="text-muted"><i class="fal fa-clock"></i> {{incidetData?.eventReviewStatus?.ReviewTime}}</small></p>
                    </div>
                    <div class="timeline-body">
                      <p>The event was reviewed by {{incidetData?.eventReviewStatus?.UserName}}, and changed to the {{incidetData?.eventReviewStatus?.Classification}}</p>
                      <p>Notes : {{incidetData?.eventReviewStatus?.ReviewNotes}}</p>
                      <p *ngIf="incidetData?.eventReviewStatus!=null">
                        <span class="col-xs-4" *ngIf="incidetData?.eventReviewStatus.ClassificationId==3||incidetData?.eventReviewStatus.ClassificationId==4">Category : </span>
                        <span class="col-xs-8 text-primary" *ngIf="incidetData?.eventReviewStatus.ClassificationId==3||incidetData?.eventReviewStatus.ClassificationId==4">: {{incidetData?.eventReviewStatus.Category}}</span>
                      </p>
                      <p *ngIf="incidetData?.eventReviewStatus!=null">
                        <span class="col-xs-4" *ngIf="incidetData?.eventReviewStatus.ClassificationId==3||incidetData?.eventReviewStatus.ClassificationId==4">Reason : </span>
                        <span class="col-xs-8 text-primary" *ngIf="incidetData?.eventReviewStatus.ClassificationId==3||incidetData?.eventReviewStatus.ClassificationId==4">: {{incidetData?.eventReviewStatus.Reason}}</span>
                      </p>
                    </div>
                  </div>
                </li>
                <li class="timeline-inverted" *ngFor="let Evidences of evidenceList;">
                  <div class="timeline-badge warning"><i class="far fa-history"></i></div>
                  <div class="timeline-panel">
                    <div class="timeline-heading">
                      <p class="timeline-title">Addtional Details</p>
                      <p class="float-right"><small class="text-muted"><i class="fal fa-clock"></i> {{Evidences?.DateAdded}}</small></p>
                    </div>
                    <div class="timeline-body">
                      <p>It was created by {{Evidences?.Name}}, and type of additional data is {{Evidences?.EvidenceRequestId}}.</p>
                    </div>
                  </div>
                </li>
                <li class="timeline-inverted" *ngFor="let item of addnoteslist">
                  <div class="timeline-badge warning"><i class="far fa-history"></i></div>
                  <div class="timeline-panel">
                    <div class="timeline-heading">
                      <p class="timeline-title">Notes information</p>
                      <p class="float-right"><small class="text-muted"><i class="fal fa-clock"></i> {{item?.CreatedDateStr}}</small></p>
                    </div>
                    <div class="timeline-body" *ngIf="item.NoteType!=0">
                      <p>The notes was added by the {{item?.UserName}}, and type of the notes is <span *ngIf="item.NoteType==1">call</span><span *ngIf="item.NoteType==2">mail</span>.</p>
                    </div>
                    <div class="timeline-body" *ngIf="item.NoteType==0">
                      <p>{{item.Title}} - {{item.Description}}</p>
                    </div>
                  </div>

                </li>
              </ul>
            </div>

            <div class="livefoot map_footer row no-m">

              <!--<div *ngIf="audituserdata.Assigned==true" class="col">
                Assigned By: <span class="text-primary">{{audituserdata.Username}} </span> <span> | </span>
                Date&Time: <span class="text-primary">{{audituserdata.DateCreated}}</span>
              </div>-->

              <div class="col d-flex justify-content-end">
                <a class="btn btn-charcol float-right mr-3" data-toggle="modal" data-target="#EventAuditactions" (click)="eventAuditFun()" *ngIf="incidetData?.eventReviewStatus==null">
                  Actions
                </a>

                <!--<a class="btn btn-warning mr-3" *ngIf="audituserdata.Assigned==false&&audituserdata.Status==0" (click)="userAssigned()">Assign</a>
                <a class="btn btn-warning mr-3" *ngIf="audituserdata.Assigned==true&&audituserdata.Status==0" (click)="userUnAssigned()">Unassign</a-->
                <a class="btn btn-charcol mr-3" *ngIf="selectedEventReviewObj?.StatusId !=2" data-toggle="modal" data-target="#AddNotes">Add Notes</a>
                <a class="btn btn-charcol mr-3" data-toggle="modal" data-target="#EventAuditactions" *ngIf="incidetData?.eventReviewStatus!=null">Actions</a>

                <a class="btn btn-charcol mr-3" *ngIf="tabChange=='compliance' && this.incidetData?.eventReviewStatus?.StatusId != 2" (click)="saveData(1)">Save & edit later</a>

                <a class="btn btn-charcol mr-3" *ngIf="tabChange=='compliance' && this.incidetData?.eventReviewStatus?.StatusId != 2" (click)="print=!print;completeHide=!completeHide;readOnlyData=!readOnlyData;saveData(2)">Save & Complete</a>



                <!--  <a class="btn btn-charcol mr-3" *ngIf="tabChange=='compliance'&& completeHide" (click)="saveData(1)">{{saveLater?'Save': 'Save & edit later'}}</a>-->
                <!--<a class="btn btn-charcol mr-3" *ngIf="tabChange=='compliance'" (click)="saveData(3)">Print</a>-->
                <a class="btn btn-charcol mr-3" *ngIf="(tabChange=='compliance' || print)&& (selectedEventReviewObj?.StatusId==1 || selectedEventReviewObj?.StatusId==2)" data-toggle="modal" data-target="#print_comp" (click)="printcompliance()">Print</a>

                <!-- <a class="btn btn-charcol mr-3" *ngIf="tabChange=='compliance' && completeHide" (click)="print=!print;completeHide=!completeHide;readOnlyData=!readOnlyData;saveData(2)">Save and Complete</a>
                <a class="btn btn-success mr-3" data-toggle="modal" data-target="#RequestOnIncident" (click)="CreateRequestForm()">Add Request</a>-->
              </div>
            </div>

            <!--add response section-->
          </div>
          <!--incident analyze window-->
          <!--create manual incident-->
          <div class="analyzeEvent_section" *ngIf="viewManInc==true && incidensection==false && summarysection==false">
            <div class="lb-head-single">
              <h6 class="float-left">Create Manual Incident</h6>
              <a class="close" (click)="closeManInc()">&times;</a>
            </div>
            <form name="form" (ngSubmit)="onSubmitCreateManualIncident(1)" [formGroup]="createManualIncidentForm">
              <div class="modal-body card-scroll">
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="FleetId">Fleet Name</label>
                      <!--<select class="form-control" name="FleetId" [(ngModel)]="FleetId" (change)="getVehicles(FleetId)" formControlName="FleetId" [ngClass]="{ 'is-invalid': submitted && f.FleetId.errors }" required>
                        <option *ngFor="let item of filters.FleetListFilter; index as i" value="{{item.FleetId}}">{{item.FleetName}}</option>
                      </select>-->
                      <div class="ng-autocomplete col-12 no-p">
                        <ng-autocomplete [data]="dropdownfleetlist"
                                         [searchKeyword]="'FleetName'"
                                         (selected)='getVehicles($event)'
                                         [itemTemplate]="itemTemplate"
                                         [notFoundTemplate]="notFoundTemplate"
                                         [formControlName]="FleetId">
                        </ng-autocomplete>

                        <ng-template #itemTemplate let-item>
                          <a [innerHTML]="item.FleetName"></a>
                        </ng-template>

                        <ng-template #notFoundTemplate let-notFound>
                          <div [innerHTML]="notFound"></div>
                        </ng-template>
                      </div>
                      <img src="../../assets/images/ButtonProcessing.gif" *ngIf="fleetload" />
                      <div *ngIf="submitted && f.FleetId.errors" class="invalid-feedback">
                        <div *ngIf="f.FleetId.errors.required">FleetId is required</div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="EventType">EventType</label>
                      <select class="form-control" name="EventType" formControlName="EventType" [ngClass]="{ 'is-invalid': submitted && f.EventType.errors }" required>
                        <option *ngFor="let item of filters.EventTypesFilter; index as i" value="{{item.Id}}">{{item.Name}}</option>
                      </select>
                      <div *ngIf="submitted && f.EventType.errors" class="invalid-feedback">
                        <div *ngIf="f.EventType.errors.required">EventType is required</div>
                      </div>
                    </div>
                    <!--<div class="form-group">
                      <label for="driverName">Driver Name</label>
                      <input type="text" class="form-control" name="driverName" [(ngModel)]="model.driverName" #driverName="ngModel" [ngClass]="{ 'is-invalid': f.submitted && driverName.invalid }" required />
                      <div *ngIf="f.submitted && driverName.invalid" class="invalid-feedback">
                        <div *ngIf="driverName.errors.required">Driver Name is required</div>
                      </div>
                    </div>-->
                    <div class="form-group">
                      <label for="time">Time</label>
                      <!--<input type="text" class="form-control" name="time" [(ngModel)]="model.time" #time="ngModel" [ngClass]="{ 'is-invalid': f.submitted && time.invalid }" required />
                      <div *ngIf="f.submitted && time.invalid" class="invalid-feedback">
                        <div *ngIf="time.errors.required">Time is required</div>
                      </div>-->
                      <!--<timepicker [(ngModel)]="model.time" [hourStep]="1" [minuteStep]="1" [showSeconds]="true" [secondsStep]="1"></timepicker>-->
                      <div style="display:flex;">
                        <select class="form-control timeControl" name="event_hours" formControlName="event_hours" [ngClass]="{ 'is-invalid': submitted && f.event_hours.errors }" required>
                          <option value="">HH</option>
                          <option value="00">00</option>
                          <option value="01">01</option>
                          <option value="02">02</option>
                          <option value="03">03</option>
                          <option value="04">04</option>
                          <option value="05">05</option>
                          <option value="06">06</option>
                          <option value="07">07</option>
                          <option value="08">08</option>
                          <option value="09">09</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="13">13</option>
                          <option value="14">14</option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                          <option value="17">17</option>
                          <option value="18">18</option>
                          <option value="19">19</option>
                          <option value="20">20</option>
                          <option value="21">21</option>
                          <option value="22">22</option>
                          <option value="23">23</option>
                        </select> <span class="timeColon">:</span>
                        <select class="form-control timeControl" name="event_min" formControlName="event_min" [ngClass]="{ 'is-invalid': submitted && f.event_min.errors }" required>
                          <option value="">MM</option>
                          <option value="00">00</option>
                          <option value="01">01</option>
                          <option value="02">02</option>
                          <option value="03">03</option>
                          <option value="04">04</option>
                          <option value="05">05</option>
                          <option value="06">06</option>
                          <option value="07">07</option>
                          <option value="08">08</option>
                          <option value="09">09</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="13">13</option>
                          <option value="14">14</option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                          <option value="17">17</option>
                          <option value="18">18</option>
                          <option value="19">19</option>
                          <option value="20">20</option>
                          <option value="21">21</option>
                          <option value="22">22</option>
                          <option value="23">23</option>
                          <option value="24">24</option>
                          <option value="25">25</option>
                          <option value="26">26</option>
                          <option value="27">27</option>
                          <option value="28">28</option>
                          <option value="29">29</option>
                          <option value="30">30</option>
                          <option value="31">31</option>
                          <option value="32">32</option>
                          <option value="33">33</option>
                          <option value="34">34</option>
                          <option value="35">35</option>
                          <option value="36">36</option>
                          <option value="37">37</option>
                          <option value="38">38</option>
                          <option value="39">39</option>
                          <option value="40">40</option>
                          <option value="41">41</option>
                          <option value="42">42</option>
                          <option value="43">43</option>
                          <option value="44">44</option>
                          <option value="45">45</option>
                          <option value="46">46</option>
                          <option value="47">47</option>
                          <option value="48">48</option>
                          <option value="49">49</option>
                          <option value="50">50</option>
                          <option value="51">51</option>
                          <option value="52">52</option>
                          <option value="53">53</option>
                          <option value="54">54</option>
                          <option value="55">55</option>
                          <option value="56">56</option>
                          <option value="57">57</option>
                          <option value="58">58</option>
                          <option value="59">59</option>
                        </select> <span class="timeColon">:</span>
                        <select class="form-control timeControl" name="event_sec" formControlName="event_sec" [ngClass]="{ 'is-invalid': submitted && f.event_sec.errors }" required>
                          <option value="">SS</option>
                          <option value="00">00</option>
                          <option value="01">01</option>
                          <option value="02">02</option>
                          <option value="03">03</option>
                          <option value="04">04</option>
                          <option value="05">05</option>
                          <option value="06">06</option>
                          <option value="07">07</option>
                          <option value="08">08</option>
                          <option value="09">09</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="13">13</option>
                          <option value="14">14</option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                          <option value="17">17</option>
                          <option value="18">18</option>
                          <option value="19">19</option>
                          <option value="20">20</option>
                          <option value="21">21</option>
                          <option value="22">22</option>
                          <option value="23">23</option>
                          <option value="24">24</option>
                          <option value="25">25</option>
                          <option value="26">26</option>
                          <option value="27">27</option>
                          <option value="28">28</option>
                          <option value="29">29</option>
                          <option value="30">30</option>
                          <option value="31">31</option>
                          <option value="32">32</option>
                          <option value="33">33</option>
                          <option value="34">34</option>
                          <option value="35">35</option>
                          <option value="36">36</option>
                          <option value="37">37</option>
                          <option value="38">38</option>
                          <option value="39">39</option>
                          <option value="40">40</option>
                          <option value="41">41</option>
                          <option value="42">42</option>
                          <option value="43">43</option>
                          <option value="44">44</option>
                          <option value="45">45</option>
                          <option value="46">46</option>
                          <option value="47">47</option>
                          <option value="48">48</option>
                          <option value="49">49</option>
                          <option value="50">50</option>
                          <option value="51">51</option>
                          <option value="52">52</option>
                          <option value="53">53</option>
                          <option value="54">54</option>
                          <option value="55">55</option>
                          <option value="56">56</option>
                          <option value="57">57</option>
                          <option value="58">58</option>
                          <option value="59">59</option>
                        </select>
                        <div *ngIf="submitted && (f.event_hours.errors||f.event_min.errors||f.event_sec.errors)" class="invalid-feedback">
                          <div *ngIf="f.event_sec.errors.required||f.event_min.errors.required||f.event_hours.errors.required">Time is required</div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="Lattitude">Lattitude</label>
                      <input type="text" class="form-control" name="Lattitude" formControlName="Lattitude" [ngClass]="{ 'is-invalid': submitted && f.Lattitude.errors }" required />
                      <div *ngIf="submitted && f.Lattitude.errors" class="invalid-feedback">
                        <div *ngIf="f.Lattitude.errors.required">Lattitude is required</div>
                      </div>
                    </div>
                    <select class="form-control severityControl" name="severity" formControlName="severity" [ngClass]="{ 'is-invalid': submitted && f.severity.errors }" required>
                      <option value="">Select Severity</option>
                      <option value="1">Low</option>
                      <option value="2">Medium</option>
                      <option value="3">High</option>
                    </select>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <label for="DeviceIdentifier">Vehicle</label>
                      <select class="form-control" name="DeviceIdentifier" formControlName="DeviceIdentifier" [ngClass]="{ 'is-invalid': submitted && f.DeviceIdentifier.errors }" required>
                        <option *ngFor="let item of vehiclesByFleet; index as i" value="{{item.DeviceIdentifier}}">{{item.VRN}}</option>
                      </select>
                      <div *ngIf="submitted && f.DeviceIdentifier.errors" class="invalid-feedback">
                        <div *ngIf="f.DeviceIdentifier.errors.required">Vehicle is required</div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="EventDate">Date</label>
                      <input type="text" class="form-control" formControlName="EventDate" name="EventDate" [ngClass]="{ 'is-invalid': submitted && f.EventDate.errors }" required [maxDate]="maxDate" bsDatepicker
                             [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY',isAnimated: true,showWeekNumbers:false}" />
                      <div *ngIf="submitted && f.EventDate.errors" class="invalid-feedback">
                        <div *ngIf="f.EventDate.errors.required">Date is required</div>
                      </div>
                    </div>
                    <div class="form-group" style="margin-top: 44px;">
                      <label class="checkbox">
                        <input type="checkbox" name="videostatus" formControlName="videostatus">
                        <span>Request Video</span>
                      </label>
                    </div>
                    <div class="form-group">
                      <label for="Longitude">Longitude</label>
                      <input type="text" class="form-control pull-left" formControlName="Longitude" name="Longitude" [ngClass]="{ 'is-invalid': submitted && f.Longitude.errors }" required />
                      <div *ngIf="submitted && f.Longitude.errors" class="invalid-feedback">
                        <div *ngIf="f.Longitude.errors.required">Longitude is required</div>
                      </div>
                      <div class="input-group-append ml-2">
                        <span class="input-group-text cursor-pointer rounded-0" data-toggle="modal" data-target="#map" (click)="openMapWindow()"><i class="fas fa-map-marked-alt"></i></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div *ngIf="maninctimeerror==true" class="alert alert-warning">It seems your vehicle was in offline for selected time! Are you sure want to create an event?  </div>
                  </div>
                </div>
              </div>
              <div class="livefoot row no-gutters">
                <div class="col">
                  <span *ngIf="maninctimeerror==false" class="float-right">
                    <img src="../../assets/images/ButtonProcessing.gif" *ngIf="submitManIncLoader" />
                  </span>
                  <button class="btn btn-success float-right" [hidden]="maninctimeerror==true" [disabled]="!createManualIncidentForm.valid">Submit</button>
                  <div *ngIf="maninctimeerror==true">
                    <button class="btn btn-success float-right" (click)="onSubmitCreateManualIncident(2)">Yes</button>
                    <a class="btn btn-charcol float-right mr-3" (click)="closeManIncWarn()">Cancel</a>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!--create manual incident-->
        </div>
      </div>
    </div>
  </div>
  <!--*ngIf="incidensection==true&&viewManInc==false&&summarysection==false"-->
  <div class="modal fade modal-custom" id="analyze" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title">Analyze</h6>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body event_analyze_section" style="height: calc(100vh - 80px);">
          <div class="row no-m">
            <div class="col-6 no-p card-scroll-single" style="height: calc(100vh - 100px);">
              <div class="col-12 pl-0">
                <ul class="nav nav-tabs col-8 p-0" role="tablist">
                  <li class="nav-item active"><a class="nav-link active" data-toggle="tab" href="#analyzevideos">Videos</a></li>
                  <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#analyzeimages">Images</a></li>
                </ul>
                <div class="tab-content">
                  <div class="tab-pane fade in active show" id="analyzevideos" role="tabpanel">
                    <div class="" *ngIf="incidetData?.VideoEnabled==true">
                      <div id="carousel" class="slide">
                        <div class="carousel-inner">
                          <div class="tab-alert no-videos" *ngIf="incidentServercommands?.length==0">
                            <h5>No videos are available.</h5>
                          </div>
                          <div *ngIf="incidentServercommands?.length==1">
                            <div class="item active" *ngIf="incidentServercommands[0].Status=='ReceivedFromDevice'">
                              <video controls [src]="incidentServercommands[0].Media[0].BlobUrl" class="media-responsive">
                                Your browser does not support this type of video.
                              </video>
                            </div>
                            <span *ngIf="incidentServercommands[0].Status=='Raised'">Command Sent</span>
                            <span *ngIf="incidentServercommands[0].Status=='SentToDevice'">InProgress</span>
                            <span *ngIf="incidentServercommands[0].Status=='Signalled'">Signalled</span>
                            <span *ngIf="incidentServercommands[0].Status=='PostProcessingReboot'">PostProcessing Reboot</span>
                            <span *ngIf="incidentServercommands[0].Status=='ProcessingError'">
                              <span class="badge badge-danger" style="font-size:11px;">
                                <strong>Declined !</strong>
                              </span>
                            </span>
                          </div>
                          <div *ngIf="incidentServercommands?.length>1">
                            <div class="row no-gutters">
                              <select [(ngModel)]="selectedCommand" (change)="changeCommandAnalyze(selectedCommand)" class="form-control col-3 d-flex justify-content-end">
                                <option *ngFor="let item of incidentServercommands; index as i" value="{{item?.CommandData}}">{{item?.Media[0]?.ChannelName}}</option>
                              </select>
                            </div>
                            <div *ngIf="selectServercmd.Status=='ReceivedFromDevice'">
                              <div *ngFor="let item of selectServercmd.Media; index as i">
                                <video controls id="video" src="{{item.BlobUrl}}" class="media-responsive"></video>
                              </div>
                            </div>
                            <span *ngIf="selectServercmd.Status=='Raised'">Command Sent</span>
                            <span *ngIf="selectServercmd.Status=='SentToDevice'">InProgress</span>
                            <span *ngIf="selectServercmd.Status=='Signalled'">Signalled</span>
                            <span *ngIf="selectServercmd.Status=='PostProcessingReboot'">PostProcessing Reboot</span>
                            <div class="col-centered-all pt-5" *ngIf="selectServercmd.Status=='ProcessingError'">
                              <span class="badge badge-danger" style="font-size:11px;">
                                <strong>Declined !</strong>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="analyzeimages" role="tabpanel">
                    <div class="" *ngIf="incidetData?.SnapshotEnabled==true">
                      <div [hidden]="incidetData?.imageList?.length!=0">
                        <div class="tab-alert no-images">
                          <h6>No images are available.</h6>
                        </div>
                      </div>
                      <div id="carousel" class="slide" [hidden]="incidetData?.imageList?.length==0">
                        <div class="carousel-inner">
                          <div class="item active">
                            <img [src]="selectedImage" class="media-responsive">
                            <button class="btn-left" (click)="prevImageClick()" [ngClass]="{'no_event':imageindex==0}"><i class="fal fa-chevron-circle-left"></i></button>
                            <button class="btn-right" (click)="nextImageClick()" [ngClass]="{'no_event':(imageindex+1)==incidetData?.imageList?.length}"><i class="fal fa-chevron-circle-right"></i></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 no-p">
                <h5>Gsensor Graph</h5>
                <div class="row">
                  <div class="col-3">
                    <label class="checkbox">
                      <input type="checkbox" [(ngModel)]="Gsensor_x" (change)="gsensorgraph(Gsensor_x,Gsensor_y,Gsensor_z)">
                      <span>GX</span>
                    </label>
                  </div>
                  <div class="col-3">
                    <label class="checkbox">
                      <input type="checkbox" [(ngModel)]="Gsensor_y" (change)="gsensorgraph(Gsensor_x,Gsensor_y,Gsensor_z)">
                      <span>GY</span>
                    </label>
                  </div>
                  <div class="col-3">
                    <label class="checkbox">
                      <input type="checkbox" [(ngModel)]="Gsensor_z" (change)="gsensorgraph(Gsensor_x,Gsensor_y,Gsensor_z)">
                      <span>GZ</span>
                    </label>
                  </div>
                </div>
                <google-chart #chart
                              [type]="gtype"
                              [data]="gdata"
                              [columns]="gcolumns"
                              [options]="goptions">
                </google-chart>
                <!---->
              </div>
              <div class="col-12 no-p">
                <h5>Speed Graph</h5>
                <google-chart #chart
                              [type]="stype"
                              [data]="sdata"
                              [columns]="scolumns"
                              [options]="sgoptions">

                </google-chart>
              </div>
            </div>
            <div class="col-6 no-p">
              <h5>Map</h5>
              <!--<google-map  (mapReady)="onMapReady($event)" [zoom]="18" >
                <map-marker *ngFor="let item of AnalyzeListPoints;let i = index;" [position]="{lat: item?.Latitude, lng: item?.Longitude}"
                            [options]="{zoom: 18, scrollwheel: true, mapTypeId: 'hybrid',bounds: AnalyzeBounds}"
                            [iconUrl]="analyzeicon">

                </map-marker>
              </google-map>-->
              <google-map [zoom]="18" (mouseOver)="onMouseOver(infoWindow, $event)" (mouseOut)="onMouseOut(infoWindow, $event)">
                <map-marker #markerElem="mapMarker"
                            *ngFor="let item of AnalyzeListPoints;let i = index;"
                            [position]="{lat: item?.Latitude, lng: item?.Longitude}"
                            [options]="{mapTypeId: 'hybrid',  scrollwheel: true, icon: analyzeicon}"
                            >

                </map-marker>
              </google-map>
            </div>
          </div>
        </div>
        <div class="livefoot">
          <a href="" class="btn btn-charcol pull-right mr-1" data-dismiss="modal" aria-label="Close">Close</a>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade modal-custom-sm" id="filters" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document" style="width: 60%">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title">Filters</h6>
          <!--<span *ngIf="ActivatedFilter" (click)="ClearFilters()" class="clear-filter cler_filters">
            <i class="fal fa-times"></i> Clear Filters
          </span>-->
          <a class="ml-05 close close_f" data-dismiss="modal" aria-label="Close" tooltip="Close" placement="left" [hide-delay]="0" [z-index]="2000" #IncidentFileterCloseButton>
            <!--<span aria-hidden="true" tooltip="Close" placement="left" [z-index]="1000" [hide-delay]="0">&times;</span>-->
            <span aria-hidden="true">&times;</span>
          </a>
        </div>
        <div class="modal-body card-scroll filters">
          <!--<div class="col-4 no-p">
            <ng-multiselect-dropdown [placeholder]="'Select Fleet'"
                                     [data]="filters.FleetListFilter"
                                     class="form-control"
                                     [(ngModel)]="selectedFleets"
                                     [settings]="dropdownSettings"
                                     (onSelect)="onItemSelect($event)"
                                     (onSelectAll)="onSelectAll($event)">
            </ng-multiselect-dropdown>
          </div>-->
          <!--<h5>Record Type</h5>
          <div *ngFor="let record of filters.EventRecordType">
            <label class="checkbox">
              <input type="checkbox" [(ngModel)]="record.status">
              <span>{{record.Name}}</span>
            </label>
          </div>-->
          <h5>Severity</h5>
          <div class="row">
            <div class="col-3" *ngFor="let severity of filters.SeverityFilter">
              <label class="checkbox">
                <input type="checkbox" [(ngModel)]="severity.FilterChecked">
                <span>{{severity?.Name}}</span>
              </label>
            </div>
          </div>
          <h5>Classifications</h5>
          <div class="row">
            <div class="col-3" *ngFor="let clsf of filters?.clasificationList">
              <label class="checkbox">
                <input type="checkbox" [(ngModel)]="clsf.FilterChecked">
                <span>{{clsf?.Classification}}</span>
              </label>
            </div>
          </div>

          <h5>Event Groups</h5>
          <div class="row">
            <div class="col-12" *ngFor="let group of filters?.EventTypesFilter;index as i">
              <div class="fleetNamesSection border-0 mb-1 filter_fleetNamesSection">
                <label class="checkbox">
                  <input type="checkbox" [(ngModel)]="group.AllChecked" (change)="EventTypesCheckAll(group.EventTypeGroupId)" [disabled]="group.status==false">
                  <span>{{group.EventTypeGroupName}}</span>
                </label>
                <span class="material-switch mb-1 float-right">
                  <input id="switch-lable{{i+1}}" name="someSwitchOption001" type="checkbox" [(ngModel)]="group.FilterChecked" (change)="EventGroupTypeChange(group)" />
                  <label for="switch-lable{{i+1}}" class="bg-primary"></label>
                </span>
              </div>
              <div class="row no-m">
                <div class="py-2 col-4" *ngFor="let type of group.Types">
                  <label class="checkbox">
                    <input type="checkbox" [(ngModel)]="type.FilterChecked" [disabled]="group.FilterChecked==false" (change)="optionToggled(group)">
                    <span>{{type?.Name}}</span>
                  </label>
                  <span class="float-right">
                    <i class="far fa-bell" *ngIf="type?.ClassificationTypeId==1" tooltip="Event" placement="left" [hide-delay]="0"></i>
                    <i class="far fa-info-circle" *ngIf="type?.ClassificationTypeId==2" tooltip="Info" placement="left" [hide-delay]="0"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="livefoot">
          <div class="col no-p">
            <button class="btn btn-success pull-right" [disabled]="filterload" (click)="changeStatus()" *ngIf="dateStatus!=CustomDateFunctionalityValue">Apply</button>
            <button class="btn btn-success pull-right" [disabled]="filterload" (click)="customDateFun(customdatefilter)" *ngIf="dateStatus==CustomDateFunctionalityValue">Apply</button>
            <img src="../../assets/images/ButtonProcessing.gif" *ngIf="filterload" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--event audit window-->
  <div class="modal right fade" id="EventAuditactions" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog no-m" style="width:27%">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title">Event Review</h6>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" #CloseEventAuditModal>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <!--<div>
          <div class="modal-body card-scroll">
            <div class="form-group">
              <label for="auditClassification">Status</label>
              <select class="form-control" name="AuditClassification" [(ngModel)]="selectedEventReviewObj.ClassificationId" [disabled]="selectedEventReviewObj?.StatusId == 2">
                <option value="">Select</option>
                <option *ngFor="let item of Classifications; index as i" value="{{item.Id}}">{{item.Classification}}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="FaultCategory">Fault Category</label>
              <select class="form-control" name="FaultCategory" (change)="getFaultReasons(selectedEventReviewObj.FaultCategoryId)" [(ngModel)]="selectedEventReviewObj.FaultCategoryId" [disabled]="selectedEventReviewObj?.StatusId == 2">
                <option value="">Select</option>
                <option *ngFor="let item of FaultCategories" value="{{item.Id}}">{{item.Category}}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="FaultReasons">Fault Reasons</label>
              <select class="form-control" name="FaultReasons" [(ngModel)]="selectedEventReviewObj.FaultReasonId" [disabled]="selectedEventReviewObj?.StatusId == 2">
                <option value="">Select</option>
                <option *ngFor="let item of FaultReasons" value="{{item.Id}}">{{item.Reason}}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="Notes">Notes</label>
              <textarea rows="3" cols="30" class="form-control" placeholder="Type here" [(ngModel)]="selectedEventReviewObj.ReviewNotes" name="Notes" [disabled]="selectedEventReviewObj?.StatusId == 2"></textarea>
            </div>
          </div>
          <div class="livefoot row no-gutters">
            <div class="col">
              <button class="btn btn-success float-right mr-3" (click)="eventAuditSubmit()" [disabled]="selectedEventReviewObj?.StatusId == 2" >Submit</button>
            </div>
          </div>
        </div>-->


        <form (ngSubmit)="eventAuditSubmit()" [formGroup]="eventsForm">
          <div class="modal-body card-scroll" style="height:calc(100vh - 80px);">
            <div class="form-group">
              <label for="auditClassification">Status</label>
              <select class="form-control" formControlName="AuditClassification" name="AuditClassification" [(ngModel)]="selectedEventReviewObj.ClassificationId" [disabled]="selectedEventReviewObj?.StatusId == 2">
                <option value="">Select</option>
                <option *ngFor="let item of Classifications; index as i" value="{{item.Id}}">{{item.Classification}}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="FaultCategory">Fault Category</label>
              <select class="form-control" name="FaultCategory" formControlName="FaultCategory" (change)="getFaultReasons(selectedEventReviewObj.FaultCategoryId)" [(ngModel)]="selectedEventReviewObj.FaultCategoryId" [disabled]="selectedEventReviewObj?.StatusId == 2">
                <option value="">Select</option>
                <option *ngFor="let item of FaultCategories" value="{{item.Id}}">{{item.Category}}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="FaultReasons">Fault Reasons</label>
              <select class="form-control" name="FaultReasons" formControlName="FaultReasons" [(ngModel)]="selectedEventReviewObj.FaultReasonId" [disabled]="selectedEventReviewObj?.StatusId == 2">
                <option value="">Select</option>
                <option *ngFor="let item of FaultReasons" value="{{item.Id}}">{{item.Reason}}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="Notes">Notes</label>
              <textarea rows="3" cols="30" class="form-control" formControlName="Notes" placeholder="Type here" [(ngModel)]="selectedEventReviewObj.ReviewNotes" name="Notes" [disabled]="selectedEventReviewObj?.StatusId == 2"></textarea>
            </div>
          </div>
          <div class="livefoot row no-gutters">
            <div class="col">
              <button class="btn btn-success float-right" [disabled]="!eventsForm.valid || selectedEventReviewObj?.StatusId == 2">Submit</button>
            </div>
          </div>
        </form>
      </div><!-- modal-content -->
    </div><!-- modal-dialog -->
  </div>
  <!--request on incident-->
  <div class="modal right fade" id="RequestOnIncident" tabindex="-1" role="dialog" aria-hidden="true" style="z-index: 1;">
    <div class="modal-dialog no-m" style="width:27%">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title">Add Request</h6>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" #CloseRequestModal (click)="closeForm()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form name="createreqform" (ngSubmit)="onSubmitReqForm()" [formGroup]="createReqForm" class="">
          <div class="modal-body card-scroll">
            <div class="form-group">
              <label>Request type</label>
              <select name="ReqType" class="form-control" formControlName="ReqType" required>
                <option *ngFor="let item of evidencetypes; index as i" value="{{item.Id}}">{{item.Name}}</option>
              </select>
              <div *ngIf="reqsubmitted && rf.ReqType.errors" class="invalid-feedback">
                <div *ngIf="rf.ReqType.errors.required">Request type is required</div>
              </div>
            </div>
            <div style="display:flex;">
              <div class="mb-1 ddl_request">
                <input id="Manager" type="radio" value="1" formControlName="reqreceipient" (change)="requestonItemChange(1)" required>
                <label for="Manager" class="">Manager</label>
              </div>
              <div class="mb-1 ml-1 ddl_request">
                <input id="Driver" type="radio" value="2" formControlName="reqreceipient" (change)="requestonItemChange(2)" required>
                <label for="Driver">Driver</label>
              </div>
              <!--<div class="mb-1">
                <input type="radio" name="reqreceipient" [(ngModel)]="reqreceipient" [checked]="reqreceipient =='1'" (change)="requestonItemChange(1)" />
                <label>Manager</label>
              </div>
              <div class="mb-1 ml-1">
                <input type="radio" name="reqreceipient" [(ngModel)]="reqreceipient" (change)="requestonItemChange(2)" />
                <label>Driver</label>
              </div>-->
            </div>
            <div class="form-group" *ngIf="reqreceipient =='2'">
              <label>Select Driver</label>
              <!--<select class="form-control" name="ReceipientId" [(ngModel)]="ReceipientId" formControlName="ReceipientId">
                <option *ngFor="let item of driverlist; index as i" value="{{item.Id}}">{{item.Name}}-{{item.PhoneNumber}}-{{item.FleetName}}</option>
              </select>-->
              <div class="ng-autocomplete col-12 no-p">
                <ng-autocomplete [data]="AllDrivers"
                                 [searchKeyword]="'Name'"
                                 [itemTemplate]="itemTemplate1"
                                 [notFoundTemplate]="notFoundTemplate1"
                                 (selected)='selectReceipient($event)'
                                 [formControlName]="ReceipientId">
                </ng-autocomplete>

                <ng-template #itemTemplate1 let-item>
                  <a [innerHTML]="item.Name"></a>
                </ng-template>

                <ng-template #notFoundTemplate1 let-notFound>
                  <div [innerHTML]="notFound"></div>
                </ng-template>
              </div>
            </div>
            <div class="form-group" *ngIf="reqreceipient=='1'">
              <label>Select Manager</label>

              <!--<select class="form-control" name="ReceipientId" [(ngModel)]="ReceipientId" formControlName="ReceipientId">
                <option *ngFor="let item of managerlist; index as i" value="{{item.Id}}">{{item.FirstName}} {{item.LastName}}</option>
              </select>-->
              <div class="ng-autocomplete col-12 no-p">

                <ng-autocomplete [data]="AllManagers"
                                 [searchKeyword]="'FirstName'"
                                 [itemTemplate]="itemTemplate2"
                                 [notFoundTemplate]="notFoundTemplate2"
                                 (selected)='selectReceipient($event)'
                                 [formControlName]="ReceipientId">
                </ng-autocomplete>

                <ng-template #itemTemplate2 let-item>
                  <a [innerHTML]="item?.FirstName"></a>
                </ng-template>

                <ng-template #notFoundTemplate2 let-notFound>
                  <div [innerHTML]="notFound"></div>
                </ng-template>
              </div>
            </div>
            <label for="RequestDueDate">Due Date</label>
            <div class="form-group row mx-0">
              <input type="date" class="form-control col-4 mr-1" min="{{currentData}}" name="RequestDueDate" formControlName="RequestDueDate" />
              <select class="form-control col" name="Request_hours" formControlName="Request_hours">
                <option value="">HH</option>
                <option value="00">00</option>
                <option value="01">01</option>
                <option value="02">02</option>
                <option value="03">03</option>
                <option value="04">04</option>
                <option value="05">05</option>
                <option value="06">06</option>
                <option value="07">07</option>
                <option value="08">08</option>
                <option value="09">09</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
              </select> <span class="timeColon">:</span>
              <select class="form-control col" name="Request_min" formControlName="Request_min">
                <option value="">MM</option>
                <option value="00">00</option>
                <option value="01">01</option>
                <option value="02">02</option>
                <option value="03">03</option>
                <option value="04">04</option>
                <option value="05">05</option>
                <option value="06">06</option>
                <option value="07">07</option>
                <option value="08">08</option>
                <option value="09">09</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
                <option value="24">24</option>
                <option value="25">25</option>
                <option value="26">26</option>
                <option value="27">27</option>
                <option value="28">28</option>
                <option value="29">29</option>
                <option value="30">30</option>
                <option value="31">31</option>
                <option value="32">32</option>
                <option value="33">33</option>
                <option value="34">34</option>
                <option value="35">35</option>
                <option value="36">36</option>
                <option value="37">37</option>
                <option value="38">38</option>
                <option value="39">39</option>
                <option value="40">40</option>
                <option value="41">41</option>
                <option value="42">42</option>
                <option value="43">43</option>
                <option value="44">44</option>
                <option value="45">45</option>
                <option value="46">46</option>
                <option value="47">47</option>
                <option value="48">48</option>
                <option value="49">49</option>
                <option value="50">50</option>
                <option value="51">51</option>
                <option value="52">52</option>
                <option value="53">53</option>
                <option value="54">54</option>
                <option value="55">55</option>
                <option value="56">56</option>
                <option value="57">57</option>
                <option value="58">58</option>
                <option value="59">59</option>
              </select> <span class="timeColon">:</span>
              <select class="form-control col" name="Request_sec" formControlName="Request_sec">
                <option value="">SS</option>
                <option value="00">00</option>
                <option value="01">01</option>
                <option value="02">02</option>
                <option value="03">03</option>
                <option value="04">04</option>
                <option value="05">05</option>
                <option value="06">06</option>
                <option value="07">07</option>
                <option value="08">08</option>
                <option value="09">09</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
                <option value="24">24</option>
                <option value="25">25</option>
                <option value="26">26</option>
                <option value="27">27</option>
                <option value="28">28</option>
                <option value="29">29</option>
                <option value="30">30</option>
                <option value="31">31</option>
                <option value="32">32</option>
                <option value="33">33</option>
                <option value="34">34</option>
                <option value="35">35</option>
                <option value="36">36</option>
                <option value="37">37</option>
                <option value="38">38</option>
                <option value="39">39</option>
                <option value="40">40</option>
                <option value="41">41</option>
                <option value="42">42</option>
                <option value="43">43</option>
                <option value="44">44</option>
                <option value="45">45</option>
                <option value="46">46</option>
                <option value="47">47</option>
                <option value="48">48</option>
                <option value="49">49</option>
                <option value="50">50</option>
                <option value="51">51</option>
                <option value="52">52</option>
                <option value="53">53</option>
                <option value="54">54</option>
                <option value="55">55</option>
                <option value="56">56</option>
                <option value="57">57</option>
                <option value="58">58</option>
                <option value="59">59</option>
              </select>
            </div>
            <div class="form-group">
              <label>Subject</label>
              <input class="form-control" placeholder="" type="text" name="ReqSubject" formControlName="ReqSubject" required>
              <div *ngIf="reqsubmitted && rf.ReqSubject.errors" class="invalid-feedback">
                <div *ngIf="rf.ReqSubject.errors.required">Subject is required</div>
              </div>
            </div>
            <div class="form-group">
              <label>Notes</label>
              <textarea rows="4" cols="40" class="form-control" placeholder="Type here" name="ReqMessage" formControlName="ReqMessage"></textarea>
            </div>
          </div>
          <div class="livefoot row no-gutters">
            <div class="col">
              <button type="submit" class="btn btn-success float-right mr-1" [disabled]="!createReqForm.valid">Submit</button>
              <img src="../../assets/images/ButtonProcessing.gif" *ngIf="submitManReq" class="float-right" />
            </div>
          </div>
        </form>
      </div><!-- modal-content -->
    </div><!-- modal-dialog -->
  </div>

  <div class="modal right fade" id="addAdditionalInfo" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog no-m" style="width:27%">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title">Add addtional details</h6>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" #AddAdditionalDetailsModal>
            <span aria-hidden="true" (click)="closeAdditionalForm()">&times;</span>
          </button>
        </div>

        <form name="form" (ngSubmit)="additionalEvidenceData()" [formGroup]="additionalEvidenceForm">
          <div class="modal-body card-scroll">
            <div class="form-group">
              <label for="FleetId">Evidence Type</label>
              <!--<select class="form-control" name="EvidenceRequestId" (change)="evidenceReqType($event.target.value)" formControlName="EvidenceRequestId" [ngClass]="{ 'is-invalid': evidencesubmitted && e.EvidenceRequestId.errors }" required>
                <option *ngFor="let item of evidencetypes; index as i" value="{{item.Id}}">{{item.Name}}</option>
              </select>
              <div *ngIf="evidencesubmitted && e.EvidenceRequestId.errors" class="invalid-feedback">
                <div *ngIf="e.EvidenceRequestId.errors.required">Evidence Type is required</div>
              </div>-->

              <select class="form-control" name="EvidenceRequestId" (change)="evidenceReqType($event.target.value)" formControlName="EvidenceRequestId"
                      [ngClass]="{ 'is-invalid': additionalEvidenceForm.get('EvidenceRequestId').invalid && additionalEvidenceForm.get('EvidenceRequestId').touched }"
                      required>
                <option *ngFor="let item of evidencetypes; index as i" value="{{item.Id}}">{{item.Name}}</option>
              </select>
              <div *ngIf="additionalEvidenceForm.get('EvidenceRequestId').invalid && additionalEvidenceForm.get('EvidenceRequestId').touched" class="invalid-feedback">
                <div *ngIf="additionalEvidenceForm.get('EvidenceRequestId').errors.required">Evidence Type is required</div>
              </div>
            </div>
            <div class="form-group">
              <label for="Name">Name</label>
              <!--<input type="text" class="form-control" name="Name" formControlName="Name" [ngClass]="{ 'is-invalid': evidencesubmitted && e.Name.errors }" required />
              <div *ngIf="evidencesubmitted && e.Name.errors" class="invalid-feedback">
                <div *ngIf="e.Name.errors.required">Name is required</div>
              </div>-->
              <input type="text" class="form-control" formControlName="Name"
                     [class.is-invalid]="additionalEvidenceForm.get('Name').invalid && additionalEvidenceForm.get('Name').touched"
                     required />
              <div *ngIf="additionalEvidenceForm.get('Name').invalid && additionalEvidenceForm.get('Name').touched" class="invalid-feedback">
                Name is required
              </div>
            </div>
            <div class="form-group">
              <label for="ContactNumber">Contact Number</label>
              <input type="text" class="form-control" (keypress)="allowOnlyNo($event)" name="ContactNumber" formControlName="ContactNumber" [ngClass]="{ 'is-invalid': additionalEvidenceForm.get('ContactNumber').invalid && additionalEvidenceForm.get('ContactNumber').touched }" required />
              <div *ngIf="additionalEvidenceForm.get('ContactNumber').invalid && additionalEvidenceForm.get('ContactNumber').touched" class="invalid-feedback">
                <div *ngIf="additionalEvidenceForm.get('ContactNumber').errors.required">ContactNumber is required</div>
              </div>
            </div>
            <div class="form-group">
              <label for="ContactNumber">EvidenceFile</label>
              <input type="file" class="form-control pl-0" name="EvidenceFile" formControlName="EvidenceFile" (change)="handleFileInput($event.target.files)" accept="{{evidenceFileAccept}}" [ngClass]="{ 'is-invalid': additionalEvidenceForm.get('EvidenceFile').invalid && additionalEvidenceForm.get('EvidenceFile').touched }" required />
              <div *ngIf="additionalEvidenceForm.get('EvidenceFile').invalid && additionalEvidenceForm.get('EvidenceFile').touched" class="invalid-feedback">
                <div *ngIf="additionalEvidenceForm.get('EvidenceFile').errors.required">File is required</div>
              </div>
              <img src="../../assets/images/ButtonProcessing.gif" *ngIf="additionalfile" />
            </div>
            <div class="form-group">
              <label for="Address">Notes</label>
              <textarea rows="3" cols="30" class="form-control" placeholder="Type here" name="Address" formControlName="Address" [ngClass]="{ 'is-invalid': additionalEvidenceForm.get('Address').invalid && additionalEvidenceForm.get('Address').touched }" required></textarea>
              <div *ngIf="additionalEvidenceForm.get('Address').invalid && additionalEvidenceForm.get('Address').touched" class="invalid-feedback">
                <div *ngIf="additionalEvidenceForm.get('Address').errors.required">Address is required</div>
              </div>
            </div>
          </div>
          <div class="livefoot row no-gutters">
            <div class="col">
              <button class="btn btn-success float-right" [disabled]="!additionalEvidenceForm.valid||additionalfile==true || isdissable">Add</button>
              <img src="../../assets/images/ButtonProcessing.gif" *ngIf="submitEvidenceLoader" />
            </div>
          </div>
        </form>
      </div><!-- modal-content -->
    </div><!-- modal-dialog -->
  </div>

  <div class="modal right fade" id="AddNotes" tabindex="-1" role="dialog" aria-hidden="true" style="z-index: 111;">
    <div class="modal-dialog no-m" style="width:27%">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title">Add Notes</h6>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" #AddNotesModal>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form class="form addNotesForm" (ngSubmit)="saveAddNotes()" [formGroup]="saveAddNotesForm">
          <div class="modal-body card-scroll" style="height:calc(100vh - 80px);">
            <div class="form-group">
              <label>Type of Note</label>
              <select class="form-control" formControlName="Type" required>
                <option value="1">Call</option>
                <option value="2">Email</option>
              </select>
            </div>
            <div class="form-group">
              <label for="uname1">Title</label>
              <input type="text" class="form-control" formControlName="Title" required>
            </div>
            <div class="form-group">
              <label>Description</label>
              <textarea class="form-control" rows="6" id="text" formControlName="Description" required></textarea>
            </div>
          </div>
          <div class="livefoot row no-gutters">
            <div class="col">
              <button class="btn btn-success float-right" [disabled]="!saveAddNotesForm.valid">Save</button>
              <img src="../../assets/images/ButtonProcessing.gif" *ngIf="saveAddNotesLoader" class="float-right" />
            </div>
          </div>
        </form>
        <!--<div class="livefoot">
          <button type="button" class="btn btn-success float-right" id="btnLogin">Save</button>
          <a href="" class="btn btn-charcol pull-right mr-1" data-dismiss="modal" aria-label="Close">Close</a>
        </div>-->
      </div>
    </div>
  </div>
  <!--Existing  video multichannel view -->
  <div class="modal fade modal-custom " id="evidencevideo" tabindex="-1" role="dialog" *ngIf="videoModal">
    <div class="modal-dialog video_modal_popup  no-m" #fullScreen1 [ngClass]="{'video_max_popup': maxClass,'video_modal_popup' :!maxClass}">
      <div class="modal-content max" [ngClass]="{'video_max_popup': !maxClass,'video_modal_popup' :maxClass}">
        <div class="modal-header">
          <h6 class="modal-title">Video channel section</h6>
          <div class="video_multichannel_tabs">

            <span class="pull-right mr-1 ml-2" *ngIf="DeviceChannelCount>1&&selectedevidencevideo?.length>1">

              <i class="fa fa-square mr-1" (click)="VideoChannelView(1)"></i>
              <i class="fa fa-th-large" (click)="VideoChannelView(2)"></i>

              <!--<i class="fal fa-th-large" *ngIf="DeviceChannelCount>=4" (click)="VideoChannelView(4)"></i>
              <i class="fal fa-th-large" *ngIf="DeviceChannelCount>=9" (click)="VideoChannelView(9)"></i>-->
            </span>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="minimize()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div class="modal-body p-05">
          <div *ngIf="DeviceChannelCount==1">
            <div class="video" *ngFor="let item of selectedevidencevideo; index as i">
              <div *ngIf="item?.BlobUrl!=null">
                <video #videoPlayer [muted]="true" (dblclick)="fullScreen()" (ended)="vidEnded()" [src]="item?.BlobUrl" (drop)="drop('drop1')" class="media-responsive">
                  Browser not supported
                </video>
              </div>
              <div *ngIf="item?.BlobUrl==null" class="bg_black_vd">
                <p>Video still in processing...!</p>
                <video [src]="item?.BlobUrl"></video>
              </div>
            </div>
          </div>
          <div *ngIf="DeviceChannelCount>1">
            <!--<div *ngIf="selectedevidencevideo.length!=0">
              <a class="btn btn-success mr-1" (click)="video_play()" *ngIf="PlayButton">Play</a>
              <a class="btn btn-success mr-1" (click)="video_pause()" *ngIf="!PlayButton">Pause</a>
              <span *ngIf="SkipButtons">
                <a class="btn btn-success mr-1" (click)="videoBackward(5)"><i class="far fa-backward"></i></a>
                <a class="btn btn-success mr-1" (click)="videoForward(5)"><i class="far fa-forward"></i></a>
                <a class="btn btn-success"><i class="fas fa-expand"></i></a>
              </span>
            </div>-->
            <div class=" row no-m">
              <div class="no-p card-scroll" [ngClass]="{'col-md-10 ':PlayButton,'col-md-12':!PlayButton}">
                <div class="row no-m">

                  <div class="video p-03" *ngIf="(channelcountchange==1)" [ngClass]="{'col-md-12':channelcountchange==1}">
                    <div *ngIf="selectedSingleVideo.BlobUrl!=null" class="text-center">
                      <label [id]="selectedSingleVideo.Id" class="channel_txt">{{selectedSingleVideo?.ChannelName}}</label>
                      <video [muted]="true" [src]="selectedSingleVideo?.BlobUrl" id="selectedVideo{{selectedSingleVideo?.ChannelName}}" (dblclick)="fullScreen('video1')" (ended)="vidEnded()" (drop)="allowDrop('drop1',selectedSingleVideo?.ChannelName,selectedSingleVideo.Id)" (dragover)="false" class="media-responsive video_player">
                        Your browser does not support HTML5 video.
                      </video>
                    </div>
                    <div *ngIf="selectedSingleVideo?.BlobUrl==null" class="bg_black_vd modal_video_bigbox" style="width: 100%; height: 98.5%;">
                      <p class="channel_txt bg_black">{{selectedSingleVideo?.ChannelName}}</p>
                      <div class="videoprogress">
                        <i class="fa fa-video"></i>
                        <p class="channel_txt">Videos Still Pending</p>
                      </div>
                      <video [src]="selectedSingleVideo?.BlobUrl" type="video/mp4" style="width:100%; height:100%;"></video>
                    </div>
                  </div>


                  <div class="video p-03" *ngIf="(channelcountchange==2)" [ngClass]="{'col-md-6':(channelcountchange==2)&&selectedevidencevideo?.length<=4,'col-md-12':channelcountchange==1,'col-md-4':(channelcountchange==2)&&selectedevidencevideo?.length>=5}">
                    <div *ngIf="selectedevidencevideo[0]?.BlobUrl!=null" class="text-center">
                      <label [id]="selectedevidencevideo[0].Id" class="channel_txt">{{selectedevidencevideo[0]?.ChannelName}}</label>
                      <video [muted]="true" [src]="selectedevidencevideo[0]?.BlobUrl" id="selectedVideo{{selectedevidencevideo[0]?.ChannelName}}" (dblclick)="fullScreen('video1')" (ended)="vidEnded()" (drop)="allowDrop('drop1',selectedevidencevideo[0]?.ChannelName,selectedevidencevideo[0].Id)" (dragover)="false" class="media-responsive video_player">
                        Your browser does not support HTML5 video.
                      </video>
                    </div>
                    <div *ngIf="selectedevidencevideo[0]?.BlobUrl==null" class="bg_black_vd modal_video_bigbox" style="width: 100%; height: 98.5%;">
                      <p class="channel_txt bg_black">{{selectedevidencevideo[0]?.ChannelName}}</p>
                      <div class="videoprogress">
                        <i class="fa fa-video"></i>
                        <p class="channel_txt">Videos Still Pending</p>
                      </div>
                      <video [src]="selectedevidencevideo[0]?.BlobUrl" type="video/mp4" style="width:100%; height:100%;"></video>
                    </div>
                  </div>
                  <div class="video p-03 text-center" *ngIf="(channelcountchange==2)&&selectedevidencevideo?.length>=2" [ngClass]="{'col-md-4':(channelcountchange==2)&&selectedevidencevideo?.length>=5,'col-md-6':(channelcountchange==2)&&selectedevidencevideo?.length<=4}">
                    <div *ngIf="selectedevidencevideo[1]?.BlobUrl!=null">
                      <label [id]="selectedevidencevideo[1].Id" class="channel_txt">{{selectedevidencevideo[1]?.ChannelName}}</label>
                      <video [muted]="true" [src]="selectedevidencevideo[1]?.BlobUrl" id="selectedVideo{{selectedevidencevideo[1]?.ChannelName}}" (dblclick)="fullScreen('video2')" (drop)="allowDrop('drop2',selectedevidencevideo[1]?.ChannelName,selectedevidencevideo[1].Id)" (dragover)="false" class="media-responsive video_player">
                        Your browser does not support HTML5 video.
                      </video>
                    </div>
                    <div *ngIf="selectedevidencevideo[1]?.BlobUrl==null" class="bg_black_vd modal_video_bigbox" style="width: 100%; height: 98.5%;">
                      <p class="channel_txt bg_black">{{selectedevidencevideo[1]?.ChannelName}}</p>
                      <div class="videoprogress">
                        <i class="fa fa-video"></i>
                        <p class="channel_txt">Videos Still Pending</p>
                      </div>
                      <video [src]="selectedevidencevideo[1]?.BlobUrl" type="video/mp4" style="width:100%; height:100%;"></video>
                    </div>
                  </div>
                  <div class="video p-03 text-center" *ngIf="(channelcountchange==2)&&selectedevidencevideo?.length>=3" [ngClass]="{'col-md-4':(channelcountchange==2)&&selectedevidencevideo?.length>=5,'col-md-6':(channelcountchange==2)&&selectedevidencevideo?.length<=4}">
                    <div *ngIf="selectedevidencevideo[2]?.BlobUrl!=null">
                      <label [id]="selectedevidencevideo[2].Id" class="channel_txt">{{selectedevidencevideo[2]?.ChannelName}}</label>
                      <video [muted]="true" [src]="selectedevidencevideo[2]?.BlobUrl" id="selectedVideo{{selectedevidencevideo[2]?.ChannelName}}" (dblclick)="fullScreen('video3')" (drop)="allowDrop('drop3',selectedevidencevideo[2]?.ChannelName,selectedevidencevideo[2].Id)" (dragover)="false" class="media-responsive video_player">
                        Your browser does not support HTML5 video.
                      </video>
                    </div>
                    <div *ngIf="selectedevidencevideo[2]?.BlobUrl==null" class="bg_black_vd modal_video_bigbox" style="width: 100%; height: 98.5%;">
                      <p class="channel_txt bg_black">{{selectedevidencevideo[2]?.ChannelName}}</p>
                      <div class="videoprogress">
                        <i class="fa fa-video"></i>
                        <p class="channel_txt">Videos Still Pending</p>
                      </div>
                      <video [src]="selectedevidencevideo[2]?.BlobUrl" type="video/mp4" style="width:100%; height:100%;"></video>
                    </div>
                  </div>
                  <div class="video p-03  text-center" *ngIf="(channelcountchange==2)&&selectedevidencevideo?.length>=4" [ngClass]="{'col-md-4':(channelcountchange==2)&&selectedevidencevideo?.length>=5, 'col-md-6':(channelcountchange==2)&&selectedevidencevideo?.length<=4}">
                    <div *ngIf="selectedevidencevideo[3]?.BlobUrl!=null">
                      <label [id]="selectedevidencevideo[3].Id" class="channel_txt">{{selectedevidencevideo[3]?.ChannelName}}</label>
                      <video [muted]="true" [src]="selectedevidencevideo[3]?.BlobUrl" id="selectedVideo{{selectedevidencevideo[3]?.ChannelName}}" (dblclick)="fullScreen('video4')" (drop)="allowDrop('drop4',selectedevidencevideo[3]?.ChannelName,selectedevidencevideo[3].Id)" (dragover)="false" class="media-responsive video_player">
                        Your browser does not support HTML5 video.
                      </video>
                    </div>
                    <div *ngIf="selectedevidencevideo[3]?.BlobUrl==null" class="bg_black_vd modal_video_bigbox" style="width: 100%; height: 98.5%;">
                      <p class="channel_txt bg_black">{{selectedevidencevideo[3]?.ChannelName}}</p>
                      <div class="videoprogress">
                        <i class="fa fa-video"></i>
                        <p class="channel_txt">Videos Still Pending</p>
                      </div>
                      <video [src]="selectedevidencevideo[3]?.BlobUrl" type="video/mp4" style="width:100%; height:100%;"></video>
                    </div>
                  </div>
                  <div class="video p-03 text-center" *ngIf="(channelcountchange==2) && selectedevidencevideo?.length>=5" [ngClass]="{'col-md-4':(channelcountchange==2)&&selectedevidencevideo?.length>=5}">
                    <div *ngIf="selectedevidencevideo[4]?.BlobUrl!=null">
                      <label [id]="selectedevidencevideo[4].Id" class="channel_txt">{{selectedevidencevideo[4]?.ChannelName}}</label>
                      <video [muted]="true" [src]="selectedevidencevideo[4]?.BlobUrl" id="selectedVideo{{selectedevidencevideo[4]?.ChannelName}}" (dblclick)="fullScreen('video5')" (drop)="allowDrop('drop5',selectedevidencevideo[4]?.ChannelName,selectedevidencevideo[4].Id)" (dragover)="false" class="media-responsive video_player">
                        Your browser does not support HTML5 video.
                      </video>
                    </div>
                    <div *ngIf="selectedevidencevideo[4]?.BlobUrl==null" class="bg_black_vd modal_video_bigbox" style="width: 100%; height: 98.5%;">
                      <p class="channel_txt bg_black">{{selectedevidencevideo[4]?.ChannelName}}</p>
                      <div class="videoprogress">
                        <i class="fa fa-video"></i>
                        <p class="channel_txt">Videos Still Pending</p>
                      </div>
                      <video [src]="selectedevidencevideo[4]?.BlobUrl" type="video/mp4" style="width:100%; height:100%;"></video>
                    </div>
                  </div>
                  <div class="video p-03 text-center" *ngIf="(channelcountchange==2) && selectedevidencevideo?.length>=6" [ngClass]="{'col-md-4':(channelcountchange==2)&&selectedevidencevideo?.length>=5}">
                    <div *ngIf="selectedevidencevideo[5]?.BlobUrl!=null">
                      <label [id]="selectedevidencevideo[5].Id" class="channel_txt">{{selectedevidencevideo[5]?.ChannelName}}</label>
                      <video [muted]="true" [src]="selectedevidencevideo[5]?.BlobUrl" id="selectedVideo{{selectedevidencevideo[5]?.ChannelName}}" (dblclick)="fullScreen('video6')" (drop)="allowDrop('drop6',selectedevidencevideo[5]?.ChannelName,selectedevidencevideo[5].Id)" (dragover)="false" class="media-responsive video_player">
                        Your browser does not support HTML5 video.
                      </video>
                    </div>
                    <div *ngIf="selectedevidencevideo[5]?.BlobUrl==null" class="bg_black_vd modal_video_bigbox" style="width: 100%; height: 98.5%;">
                      <p class="channel_txt bg_black">{{selectedevidencevideo[5]?.ChannelName}}</p>
                      <div class="videoprogress">
                        <i class="fa fa-video"></i>
                        <p class="channel_txt">Videos Still Pending</p>
                      </div>
                      <video [src]="selectedevidencevideo[5]?.BlobUrl" type="video/mp4" style="width:100%; height:100%;"></video>
                    </div>
                  </div>
                  <div class="video p-03 text-center" *ngIf="(channelcountchange==2)&&selectedevidencevideo.length>=7" [ngClass]="{'col-md-4':(channelcountchange==2)&&selectedevidencevideo?.length>=5}">
                    <div *ngIf="selectedevidencevideo[6]?.BlobUrl!=null">
                      <label [id]="selectedevidencevideo[6].Id" class="channel_txt">{{selectedevidencevideo[6]?.ChannelName}}</label>
                      <video [muted]="true" [src]="selectedevidencevideo[6]?.BlobUrl" id="selectedVideo{{selectedevidencevideo[6]?.ChannelName}}" (dblclick)="fullScreen('video7')" (drop)="allowDrop('drop7',selectedevidencevideo[6]?.ChannelName,selectedevidencevideo[6].Id)" (dragover)="false" class="media-responsive video_player">
                        Your browser does not support HTML5 video.
                      </video>
                    </div>
                    <div *ngIf="selectedevidencevideo[6]?.BlobUrl==null" class="bg_black_vd modal_video_bigbox" style="width: 100%; height: 98.5%;">
                      <p class="channel_txt bg_black">{{selectedevidencevideo[6]?.ChannelName}}</p>
                      <div class="videoprogress">
                        <i class="fa fa-video"></i>
                        <p class="channel_txt">Videos Still Pending</p>
                      </div>
                      <video [src]="selectedevidencevideo[6]?.BlobUrl" type="video/mp4" style="width:100%; height:100%;"></video>
                    </div>
                  </div>
                  <div class="video p-03 text-center" *ngIf="(channelcountchange==2)&&selectedevidencevideo?.length>=8" [ngClass]="{'col-md-4':(channelcountchange==2)&&selectedevidencevideo?.length>=5}">
                    <div *ngIf="selectedevidencevideo[7]?.BlobUrl!=null">
                      <label [id]="selectedevidencevideo[7].Id" class="channel_txt">{{selectedevidencevideo[7]?.ChannelName}}</label>
                      <video [muted]="true" [src]="selectedevidencevideo[7]?.BlobUrl" id="selectedVideo{{selectedevidencevideo[7]?.ChannelName}}" (dblclick)="fullScreen('video8')" (drop)="allowDrop('drop8',selectedevidencevideo[7]?.ChannelName,selectedevidencevideo[7].Id)" (dragover)="false" class="media-responsive video_player">
                        Your browser does not support HTML5 video.
                      </video>
                    </div>
                    <div *ngIf="selectedevidencevideo[7]?.BlobUrl==null" class="bg_black_vd modal_video_bigbox" style="width: 100%; height: 98.5%;">
                      <p class="channel_txt bg_black">{{selectedevidencevideo[7]?.ChannelName}}</p>
                      <div class="videoprogress">
                        <i class="fa fa-video"></i>
                        <p class="channel_txt">Videos Still Pending</p>
                      </div>
                      <video [src]="selectedevidencevideo[7]?.BlobUrl" type="video/mp4" style="width:100%; height:100%;"></video>
                    </div>
                  </div>
                  <div class="video p-03 text-center" *ngIf="(channelcountchange==2)&&selectedevidencevideo?.length>=9" [ngClass]="{'col-md-4':(channelcountchange==2)&&selectedevidencevideo?.length>=5}">
                    <div *ngIf="selectedevidencevideo[8]?.BlobUrl!=null">
                      <label [id]="selectedevidencevideo[8].Id" class="channel_txt">{{selectedevidencevideo[8]?.ChannelName}}</label>
                      <video [muted]="true" [src]="selectedevidencevideo[8]?.BlobUrl" id="selectedVideo{{selectedevidencevideo[8]?.ChannelName}}" (dblclick)="fullScreen('video9')" (drop)="allowDrop('drop9',selectedevidencevideo[8]?.ChannelName,selectedevidencevideo[8].Id)" (dragover)="false" class="media-responsive video_player">
                        Your browser does not support HTML5 video.
                      </video>
                    </div>
                    <div *ngIf="selectedevidencevideo[8]?.BlobUrl==null" class="bg_black_vd modal_video_bigbox" style="width: 100%; height: 98.5%;">
                      <p class="channel_txt bg_black">{{selectedevidencevideo[8]?.ChannelName}}</p>
                      <div class="videoprogress">
                        <i class="fa fa-video"></i>
                        <p class="channel_txt">Videos Still Pending</p>
                      </div>
                      <video [src]="selectedevidencevideo[8]?.BlobUrl" type="video/mp4" style="width:100%; height:100%;"></video>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-2 pr-0 pt-05 pl-05 pb-05 videoside_section card-scroll" [hidden]="isDisplay">
                <div id="thumbcarousel" class="carousel slide" data-interval="false">
                  <div class="carousel-inner">
                    <div class="item active" *ngFor="let item of selectedevidencevideo; index as i">
                      <div *ngIf="item?.BlobUrl!=null">
                        <div class="thumb  hvr-grow-shadow incident_thumb" id="drag{{item?.ChannelName}}">
                          <span class="channelCount channel_txt">{{item?.ChannelName}}</span>
                          <video id="selectedVideo" class="thumb_dimen no-p media-responsive" [src]="item?.BlobUrl" draggable="true" (dragstart)="drag(item)"></video>
                        </div>
                      </div>
                      <div *ngIf="item?.BlobUrl==null">
                        <div class="thumb  hvr-grow-shadow incident_thumb" id="drag{{item?.ChannelName}}">
                          <!--<span class="channelCount channel_txt">{{item?.ChannelName}}</span>-->
                          <p class="channel_txt bg_charcoal">{{item?.ChannelName}}</p>

                          <label class="channel_name2">Videos Still Pending</label>
                          <video id="selectedVideo" class="thumb_dimen no-p media-responsive" [src]="item?.BlobUrl" draggable="true" (dragstart)="drag(item)"></video>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer modal_footer_position mobile_controls">
          <!--<div class="m-auto">
            <div *ngIf="selectedevidencevideo?.length!=0">
              <a class="btn btn-success mr-1" (click)="video_play()" *ngIf="PlayButton">Play</a>
              <a class="btn btn-success mr-1" (click)="video_pause()" *ngIf="!PlayButton">Pause</a>
              <span *ngIf="SkipButtons">
                <a class="btn btn-success  mr-1" (click)="videoBackward(5)"><i class="far fa-backward"></i></a>
                <a class="btn btn-success  mr-1" (click)="videoForward(5)"><i class="far fa-forward"></i></a>
                <a class="btn btn-success"><i class="fas fa-expand"></i></a>
              </span>
            </div>
          </div>-->
          <!--<div class="m-auto">
            <a class="btn btn-success mr-1" (click)="video_play()" *ngIf="PlayButton">Play</a>
            <a class="btn btn-success mr-1" (click)="video_pause()" *ngIf="!PlayButton">Pause</a>
            <a class="btn btn-success mr-1" (click)="videoBackward(5)"><i class="far fa-backward"></i></a>
            <a class="btn btn-success mr-1" (click)="videoForward(5)"><i class="far fa-forward"></i></a>
            <a class="btn btn-success mr-1 ml-05" *ngIf="maxVideo" (click)="maxmize()"><i class="fas fa-expand"></i></a>
            <a class="btn btn-success mr-1 ml-05" *ngIf="!maxVideo" (click)="minimize()"><i class="fas fa-expand"></i></a>
          </div>-->
          <!--<div class="m-auto">
            <a class="btn btn-success mr-1" (click)="video_play()" *ngIf="PlayButton">Play</a>
            <a class="btn btn-success mr-1" (click)="video_pause()" *ngIf="!PlayButton">Pause</a>
            <a class="btn btn-success mr-1" (click)="videoBackward(5)"><i class="far fa-backward"></i></a>
            <a class="btn btn-success mr-1" (click)="videoForward(5)"><i class="far fa-forward"></i></a>
            <a class="btn btn-success mr-1 ml-05" *ngIf="maxVideo" (click)="maxmize()"><i class="fas fa-expand"></i></a>
            <a class="btn btn-success mr-1 ml-05" *ngIf="!maxVideo" (click)="minimize()"><i class="fas fa-expand"></i></a>
          </div>-->
          <div class="col-lg-9 col-md-6 col-sm-7 col-6 mobile_controls_left">
            <div class="d-flex pl-35 pt-06">
              <a class="btn btn-success mr-1" *ngIf="PlayButton" (click)="video_play()">Play</a>
              <a class="btn btn-success mr-1" *ngIf="!PlayButton" (click)="video_pause()">Pause</a>
              <div class="mr-05 pt-05">
                <span id="time"></span>/<span id="duration"></span>
              </div>
              <div class="video_bar">
                <input type="range" id="inputSeek" value="0">
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-5 col-6 mobile_controls_right">
            <div class="m-auto">
              <a class="btn btn-success mr-1" (click)="videoBackward(5)">
                <i class="far fa-backward"></i>
              </a>
              <a class="btn btn-success mr-1" (click)="videoForward(5)">
                <i class="far fa-forward"></i>
              </a>

              <div class="mute_ddldropdown d-inline-block">
                <a class="btn btn-success mr-1 dropdown-toggle" (click)="muteOptions()">
                  <i class="fas fa-volume-mute" *ngIf="muteButton"></i>
                  <i class="fas fa-volume" *ngIf="!muteButton"></i>
                </a>

                <div class="mute_ddl controlspopup" *ngIf="showpopup">
                  <div class="mute_head d-flex">
                    <h5 class="modal-title">Mute Options</h5>
                    <a class="mute_close" (click)="muteOptionsClose()"> <i class="fal fa-times"></i></a>
                  </div>
                  <div class="mute_body card-scroll">
                    <div class="row no-m mb-1" *ngFor="let data of selectedevidencevideo; index as i">
                      <div class="col-md-6 col-sm-6 col-6 no-p text-left">
                        <a href="#">{{data?.ChannelName}}</a>
                      </div>
                      <div class="col-md-6 col-sm-6 col-6 no-p text-right">
                        <!--<label class="switch switch-small">
                          <input type="checkbox" ngChange="muteVideo1(data.ChannelName)" [(ngModel)]="data.IsMuted">
                          <span class="slider"></span>
                        </label>-->

                        <label class="switch">
                          <input type="checkbox"
                                 [checked]="data.IsMuted"
                                 [(ngModel)]="data.IsMuted"
                                 (change)="muteVideo1(data.ChannelName)" />
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--<a class="btn btn-success mr-1" *ngIf="maxVideo" (click)="maxmize()">
                <i class="fas fa-expand"></i>
              </a>
              <a class="btn btn-success mr-1" *ngIf="!maxVideo" (click)="minimize()">
                <i class="fas fa-expand"></i>
              </a>-->
              <a *ngIf="toggleClass === 'ft-maximize'" class="btn btn-success mr-1" href="javascript:;" (click)="openFullscreen()">
                <i class="fas fa-expand"></i>
              </a>
              <a *ngIf="toggleClass === 'ft-minimize'" class="btn btn-success mr-1" href="javascript:;" (click)="closeFullscreen()">
                <i class="fas fa-compress"></i>
              </a>
              <a class="btn btn-success ml-05" (click)="DownloadVideos(selectedevidencevideo)">
                <i class="fas fa-download"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--New video multichannel view -->
  <!--<div class="modal fade modal-custom" id="evidencevideo" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog no-m">
      <div class="modal-content">
        <div class="modal-header">
          <div class="col-xs-5">
            <h5 class="modal-title" style="margin-top: -12px;">Overview</h5>
          </div>
          <div class="col-xs-6">
            <div class="row">
              <div class="col-xs-6">
                <h5 class="modal-title" style="margin-top: -12px;margin-right: 20px;">Video channel section</h5>
              </div>
              <div class="col-xs-6">
                <span class="pull-right mr-1" *ngIf="DeviceChannelCount>1&&selectedevidencevideo.length>1">
                  <i class="fal fa-square pr-05" (click)="VideoChannelView(1)"></i>
                  <i class="fal fa-dice-two pr-05" (click)="VideoChannelView(2)"></i>
                  <i class="fal fa-square pr-05"></i>
                  <i class="fal fa-dice-two pr-05"></i>
                  <i class="fal fa-th-large" *ngIf="DeviceChannelCount>=4" (click)="VideoChannelView(4)"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="col-xs-1">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div class="modal-body">
          <div style="height: calc(105vh - 90px) !important;">
            <div *ngIf="DeviceChannelCount==1">
              <div *ngIf="selectedevidencevideo.length!=0">
                <a class="btn btn-charcol" (click)="video_play()" *ngIf="PlayButton">Play</a>
                <a class="btn btn-charcol" (click)="video_pause()" *ngIf="!PlayButton">Pause</a>
                <span *ngIf="SkipButtons">
                  <a class="btn btn-charcol" (click)="videoBackward(5)"><i class="far fa-backward"></i></a>
                  <a class="btn btn-charcol" (click)="videoForward(5)"><i class="far fa-forward"></i></a>
                </span>
              </div>
              <div class="video" *ngFor="let item of selectedevidencevideo; index as i">
                <div *ngIf="item.Url!=null">
                  <video #videoPlayer (ended)="vidEnded()" [src]="item.BlobUrl" class="media-responsive">
                    Browser not supported
                  </video>
                </div>
                <div *ngIf="item.Url==null">
                  <p>Video still in processing...!</p>
                </div>
              </div>
            </div>
            <div *ngIf="DeviceChannelCount==2">

              <!--single & double video multichannel view -->
  <!--9screen video multichannel view ends-->
  <!--</div>
          </div>
        </div>
      </div>
    </div>
  </div>-->
  <div class="modal fade modal-custom" id="map" tabindex="-1" role="dialog">
    <div class="modal-dialog no-m">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title">Map</h6>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body selectlocation p-0">
          <div class="mx-auto p-1 col-6">
            <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()" [(ngModel)]="searchControl" placeholder="Search Location" #search_ autocomplete="off">
          </div>
          <!--<google-map [zoom]="zoom">
            <map-marker [position]="{lat: latitude_map, lng: longitude_map}"
                        [options]="{draggable: true}"
                        (dragEnd)="markerDragEnd($event)"></map-marker>
          </google-map>-->


          <google-map [zoom]="zoom">
            <map-marker #markerElem="mapMarker"
                        [position]="{lat: latitude_map, lng: longitude_map}"
                        [options]="{draggable: true}"
                        (dragEnd)="markerDragEnd($event)"
                        >

            </map-marker>
          </google-map>
          <div class="row no-gutters d-flex justify-content-center p-2">
            <div class="col-4 text-center">
              <!--<h5>Address: {{address}}</h5>-->
              <h6>Latitude: {{latitude_map}}</h6>
            </div>
            <div class="col-4 text-center">
              <h6>Longitude: {{longitude_map}}</h6>
            </div>
          </div>
        </div>
        <div class="livefoot">
          <a href="" class="btn btn-success pull-right" data-dismiss="modal" aria-label="Close">Save</a>
          <a href="" class="btn btn-charcol pull-right mr-1" data-dismiss="modal" aria-label="Close">Close</a>
        </div>
      </div><!-- modal-content -->
    </div><!-- modal-dialog -->
  </div>

  <div class="modal modal-custom right fade" id="gallery" tabindex="-1" role="dialog" aria-hidden="true" *ngIf="incidensection==true&&viewManInc==false&&summarysection==false">
    <div class="modal-dialog no-m">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title">Gallery</h6>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body product-slider card-scroll-single">
          <div class="tab" *ngIf="incidetData.SnapshotEnabled==true&&incidetData?.VideoEnabled==true">
            <ul class="nav nav-tabs col-12 justify-content-center incidenttab_ul" role="tablist">
              <li class="nav-item" [ngClass]="{'active':MediaGalleryActiveTab=='images'}">
                <a class="nav-link" data-toggle="tab" href="#images" *ngIf="incidetData.SnapshotEnabled==true" [ngClass]="{'active':MediaGalleryActiveTab=='images'}">
                  <span><i class="fad fa-images"></i></span>
                </a>
              </li>
              <!--<li class="nav-item" [ngClass]="{'active':MediaGalleryActiveTab=='video'}">
                <a class="nav-link" data-toggle="tab" href="#videos" *ngIf="selectedIncidentObj.VideoEnabled==true" [ngClass]="{'active':MediaGalleryActiveTab=='video'}">
                  <span><i class="fad fa-video"></i></span>
                </a>
              </li>-->
            </ul>
            <div class="tab-content no-p">
              <div class="tab-pane fade in" id="images" role="tabpanel" *ngIf="incidetData.SnapshotEnabled==true" [ngClass]="{'active':MediaGalleryActiveTab=='images','show':MediaGalleryActiveTab=='images'}">
                <div class="tab-alert no-images" *ngIf="incidetData?.imageList?.length == 0">
                  <h6>No images are available.</h6>
                </div>
                <div *ngIf="incidetData?.imageList?.length != 0">
                  <div id="carousel" class="slide">
                    <div class="carousel-inner">
                      <div class="item active">
                        <button class="btn no-p no-background" (click)="prevImageClick()" [ngClass]="{'no_event':imageindex==0}"><i class="fal fa-chevron-circle-left"></i></button>
                        <img src="{{selectedImage}}" class="media-responsive">
                        <button class="btn no-p no-background" (click)="nextImageClick()" [ngClass]="{'no_event':(imageindex+1)==incidetData?.imageList?.length}"><i class="fal fa-chevron-circle-right"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="videos" role="tabpanel" *ngIf="incidetData?.VideoEnabled==true" [ngClass]="{'active':MediaGalleryActiveTab=='video','show':MediaGalleryActiveTab=='video'}">
                <div class="collapse show" id="collapseOne" aria-labelledby="headingOne" data-parent="#accordionExample">
                  <div id="carousel" class="slide">
                    <div class="carousel-inner">
                      <div class="tab-alert no-videos" *ngIf="commandVideoList?.length==0">
                        <h5>No videos are available.</h5>
                      </div>
                      <div *ngIf="incidentServercommands?.length==1">
                        <div class="item active" *ngIf="incidentServercommands[0].Status=='ReceivedFromDevice'&&incidentServercommands[0].Media[0].BlobUrl!=null">
                          <div *ngFor="let x of commandVideoList">
                            <video [src]="x.BlobUrl" controls class="media-responsive">
                              Your browser does not support HTML5 video.
                            </video>
                          </div>
                        </div>
                        <div class="item active" *ngIf="incidentServercommands[0].Status=='ReceivedFromDevice'&&incidentServercommands[0].Media[0].BlobUrl==null">
                          <p>Video still in processing...!</p>
                        </div>
                        <span *ngIf="incidentServercommands[0].Status=='Raised'">Command Sent</span>
                        <span *ngIf="incidentServercommands[0].Status=='SentToDevice'">InProgress</span>
                        <span *ngIf="incidentServercommands[0].Status=='Signalled'">Signalled</span>
                        <span *ngIf="incidentServercommands[0].Status=='PostProcessingReboot'">PostProcessing Reboot</span>
                        <div class="col-centered-all" *ngIf="incidentServercommands[0].Status=='ProcessingError'">
                          <span class="badge badge-danger">
                            <strong>Declined !</strong>
                          </span>
                        </div>
                      </div>
                      <div *ngIf="incidentServercommands?.length>1">
                        <div class="row no-m justify-content-center">
                          <select [(ngModel)]="selectedCommand" (change)="changeCommandAnalyze(selectedCommand)" class="form-control col-3 m-2">
                            <option *ngFor="let item of incidentServercommands; index as i" value="{{item.CommandData}}">Video{{i+1}}</option>
                          </select>
                        </div>
                        <div *ngIf="selectServercmd.Status=='ReceivedFromDevice'">
                          <div *ngFor="let item of selectServercmd.Media; index as i">
                            <video controls id="video" src="{{item.BlobUrl}}" class="media-responsive"></video>
                          </div>
                        </div>
                        <span *ngIf="selectServercmd.Status=='Raised'">Command Sent</span>
                        <span *ngIf="selectServercmd.Status=='SentToDevice'">InProgress</span>
                        <span *ngIf="selectServercmd.Status=='Signalled'">Signalled</span>
                        <span *ngIf="selectServercmd.Status=='PostProcessingReboot'">PostProcessing Reboot</span>
                        <div class="col-centered-all" *ngIf="selectServercmd.Status=='ProcessingError'">
                          <span class="badge badge-danger">
                            <strong>Declined !</strong>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--incident request files-->
  <div class="modal modal-custom left fade" id="IncidentRequestFileModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog no-m">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title">Overview</h6>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div *ngIf="selectedReqFileType=='1'">
            <video controls id="video" [src]="selectedReqFileUrl" class="media-responsive"></video>
          </div>
          <div *ngIf="selectedReqFileType=='2'">
            <img [src]="selectedReqFileUrl" class="media-responsive">
          </div>
          <div *ngIf="selectedReqFileType=='3'||selectedReqFileType=='4'">
            <iframe [class.thumbnail]="thumbnail" [src]="selectedReqFileUrl" width="100%" height="1000" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--view request view-->
  <div class="modal right fade" id="ViewRequestModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog no-m" style="width: 71.9%!important">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title">View Request</h6>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" #CloseCompleteRequestModal>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body card-scroll">
          <div class="row subject-sec">
            <div class="col-6">
              <h5>You : </h5>
              <p>{{selectedRequestNotes}}</p>
            </div>
            <div class="col-6">
              <h5>Message from {{selectedRequestReceipient}} ({{selectedRequestReceipientId}})</h5>
              <p>{{selectedRequestMessage}}</p>
            </div>
          </div>
          <div id='carousel-custom' class="carousel slide">
            <ol class='carousel-top'>
              <li *ngFor="let item of selectedRequestMedia; index as i">
                <div class="thumbnail" *ngIf="selectedRequestRequestTypeId==1" [ngClass]="{'active':item.blobrequestfileUrl==ActiveSelectedThumNailImage}">
                  <span class="check-label">
                    <label class="checkbox">
                      <input type="checkbox" [(ngModel)]="item.isChecked" (ngModelChange)="AddToEvidenceFun(item)">
                      <span>Video{{i+1}}</span>
                    </label>
                  </span>
                  <div class="thumbnail-item">
                    <video id="" [src]="item.blobrequestfileUrl" class="item-img"></video>
                    <div class="item-info" (click)="SelectedThumbNailImg(item)">
                      <i class="fad fa-expand"></i>
                    </div>
                  </div>
                </div>
                <div class="thumbnail" *ngIf="selectedRequestRequestTypeId==2" [ngClass]="{'active':item.blobrequestfileUrl==ActiveSelectedThumNailImage}">
                  <span class="check-label">
                    <label class="checkbox">
                      <input type="checkbox" [(ngModel)]="item.isChecked" (ngModelChange)="AddToEvidenceFun(item)">
                      <span>Image{{i+1}}</span>
                    </label>
                  </span>
                  <div class="thumbnail-item">
                    <img [src]="item.blobrequestfileUrl" class="item-img" />
                    <div class="item-info" (click)="SelectedThumbNailImg(item)">
                      <i class="fad fa-expand"></i>
                    </div>
                  </div>
                </div>
                <div class="thumbnail" *ngIf="selectedRequestRequestTypeId==3" [ngClass]="{'active':item.blobrequestfileUrl==ActiveSelectedThumNailImage}">
                  <span class="check-label">
                    <label class="checkbox">
                      <input type="checkbox" [(ngModel)]="item.isChecked" (ngModelChange)="AddToEvidenceFun(item)">
                      <span>Document{{i+1}}</span>
                    </label>
                  </span>
                  <div class="thumbnail-item">
                    <img src="../../assets/images/doc.png" class="item-img contain-fit" />
                    <div class="item-info" (click)="SelectedThumbNailImg(item)">
                      <i class="fad fa-expand"></i>
                    </div>
                  </div>
                </div>
                <div class="thumbnail" *ngIf="selectedRequestRequestTypeId==4" [ngClass]="{'active':item.blobrequestfileUrl==ActiveSelectedThumNailImage}">
                  <span class="check-label">
                    <label class="checkbox">
                      <input type="checkbox" [(ngModel)]="item.isChecked" (ngModelChange)="AddToEvidenceFun(item)">
                      <span>Other{{i+1}}</span>
                    </label>
                  </span>
                  <div class="thumbnail-item">
                    <img src="../../assets/images/docs.png" class="item-img contain-fit" />
                    <div class="item-info" (click)="SelectedThumbNailImg(item)">
                      <i class="fad fa-expand"></i>
                    </div>
                  </div>
                </div>
              </li>
            </ol>

            <div class='carousel-inner'>
              <div class='carousel-item active'>
                <div *ngIf="selectedRequestRequestTypeId==1">
                  <video controls id="video" [src]="SelectedThumbNailImage" class="thumb-img"></video>
                </div>
                <div *ngIf="selectedRequestRequestTypeId==2">
                  <img [src]="SelectedThumbNailImage" class="thumb-img">
                </div>
                <div *ngIf="selectedRequestRequestTypeId==3">
                  <iframe [src]="SelectedThumbNailImage" height="1000" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen class="thumb-img"></iframe>
                </div>
                <div *ngIf="selectedRequestRequestTypeId==4">
                  <iframe [src]="SelectedThumbNailImage" height="1000" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen class="thumb-img"></iframe>
                </div>
              </div>
              <!--<a class="carousel-control-prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>-->
            </div>
          </div>
        </div>

        <div class="livefoot row no-gutters">
          <div class="col">
            <button class="btn btn-warning" (click)="CompleteRequest()">Complete Task</button>
          </div>
          <div class="col-4 d-flex justify-content-end">
            <span class="check_count">Selected <span class="text-success">{{EvidenceFileListCount}}</span> / {{selectedRequestMediaLength}}</span>
            <button class="btn btn-success" (click)="RequestMoveToEvidence()">Move to incident</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--view request view-->
  <div class="modal right fade" id="ViewRequestModal2" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog no-m" style="width: 71.9%!important">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title">View Request</h6>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" #CloseRequestModal2>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body card-scroll">
          <div class="row subject-sec">
            <div class="col-12">
              <h5>You : </h5>
              <p>{{selectedRequestNotes}}</p>
            </div>
          </div>
          <div id='carousel-custom' class="carousel slide">
            <ol class='carousel-top'>
              <li *ngFor="let item of selectedRequestMedia; index as i">
                <div class="thumbnail" *ngIf="selectedRequestRequestTypeId==1" [ngClass]="{'active':item.blobrequestfileUrl==ActiveSelectedThumNailImage}">
                  <div class="thumbnail-item">
                    <video id="" [src]="item.blobrequestfileUrl" class="item-img"></video>
                    <div class="item-info" (click)="SelectedThumbNailImg(item)">
                      <i class="fad fa-expand"></i>
                    </div>
                  </div>
                </div>
                <div class="thumbnail" *ngIf="selectedRequestRequestTypeId==2" [ngClass]="{'active':item.blobrequestfileUrl==ActiveSelectedThumNailImage}">
                  <div class="thumbnail-item">
                    <img [src]="item.blobrequestfileUrl" class="item-img" />
                    <div class="item-info" (click)="SelectedThumbNailImg(item)">
                      <i class="fad fa-expand"></i>
                    </div>
                  </div>
                </div>
                <div class="thumbnail" *ngIf="selectedRequestRequestTypeId==3" [ngClass]="{'active':item.blobrequestfileUrl==ActiveSelectedThumNailImage}">
                  <div class="thumbnail-item">
                    <img src="../../assets/images/doc.png" class="item-img contain-fit" />
                    <div class="item-info" (click)="SelectedThumbNailImg(item)">
                      <i class="fad fa-expand"></i>
                    </div>
                  </div>
                </div>
                <div class="thumbnail" *ngIf="selectedRequestRequestTypeId==4" [ngClass]="{'active':item.blobrequestfileUrl==ActiveSelectedThumNailImage}">
                  <div class="thumbnail-item">
                    <img src="../../assets/images/docs.png" class="item-img contain-fit" />
                    <div class="item-info" (click)="SelectedThumbNailImg(item)">
                      <i class="fad fa-expand"></i>
                    </div>
                  </div>
                </div>
              </li>
            </ol>

            <div class='carousel-inner'>
              <div class='carousel-item active'>
                <div *ngIf="selectedRequestRequestTypeId==1">
                  <video controls id="video" [src]="SelectedThumbNailImage" class="thumb-img"></video>
                </div>
                <div *ngIf="selectedRequestRequestTypeId==2">
                  <img [src]="SelectedThumbNailImage" class="thumb-img">
                </div>
                <div *ngIf="selectedRequestRequestTypeId==3">
                  <iframe [src]="SelectedThumbNailImage" height="1000" class="thumb-img"></iframe>
                </div>
                <div *ngIf="selectedRequestRequestTypeId==4">
                  <iframe [src]="SelectedThumbNailImage" height="1000" class="thumb-img"></iframe>
                </div>
              </div>
              <!--<a class="carousel-control-prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade modal-custom-sm" id="print_comp" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content print-container">
        <div class="modal-header">
          <h6 class="modal-title">Print Compliance</h6>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <div>
          </div>
        </div>
        <div class="modal-body card-scroll">
          <div class="row">
            <div class="col-9">
              <h5 class="Heading_h5">Compliance</h5>
            </div>
            <div class="col-3 text-right">
              <span><b class="blue">Status :</b> <span *ngIf="selectedEventReviewObj?.StatusId==1"> Draft</span> <span *ngIf="selectedEventReviewObj?.StatusId==2"> Complete</span> </span>
            </div>
          </div>
          <div class="Main_compliance">
            <div class="row mb-1 no-m">
              <div class="col-4 pl-0">
                <div class="compliance_details">
                  <b>VRN:</b> {{vehicle?.RegistrationNumber}}
                </div>
              </div>
              <div class="col-4 pl-0">
                <div class="compliance_details">
                  <b>Speed:</b> {{incidetData?.spd}}
                  <span *ngIf="incidetData?.stringmileage=='KMS'">kph</span>
                  <span *ngIf="incidetData?.stringmileage!='KMS'">mph</span>
                </div>
              </div>
              <div class="col-4 pl-0">
                <div class="compliance_details">
                  <b> Event Type:</b> {{incidetData?.displayname}}
                </div>
              </div>
            </div>
            <div class="Main_compliance">
              <div class="row mb-1 no-m">
                <div class="col-4 pl-0">
                  <div class="compliance_details">
                    <b> Driver:</b> <span *ngIf="SelectedDriver?.Name== null"> {{driver?.Name}}</span><span *ngIf="SelectedDriver?.Name!= null"> {{SelectedDriver?.Name}}</span>
                  </div>
                </div>
                <div class="col-4 pl-0">
                  <div class="compliance_details">
                    <b>Manager:</b> {{SelectedManager?.FirstName}} {{SelectedManager?.LastName}}
                  </div>
                </div>
                <div class="col-4 pl-0">
                  <div class="compliance_details">
                    <b>Event Time:</b> {{incidetData?.time_event}}
                  </div>
                </div>
              </div>
            </div>
            <div class="inner_compliance">
              <div> <h5> <b>Compliance Notes</b></h5> </div>
              <div class="row no-m">
                <div class="col-4 compliance_notes">
                  <div>
                    <b>Manager Notes </b>
                  </div>
                </div>
                <div class="col-4 compliance_notes">
                  <div>
                    <b> Driver Notes</b>
                  </div>
                </div>
                <div class="col-4 compliance_notes" *ngIf="selectedEventReviewObj?.DriverMateNotes!= null">
                  <div>
                    <b> Driver Mate Notes</b>
                  </div>
                </div>
              </div>
              <div class="row no-m">
                <div class="col-4 compliance_notes_inner">
                  <div>
                    {{selectedEventReviewObj?.ManagerNotes}}
                  </div>
                </div>
                <div class="col-4 compliance_notes_inner bod_right">
                  <div class="pl-05">
                    {{selectedEventReviewObj?.DriverNotes}}
                  </div>
                </div>
                <div class="col-4 compliance_notes_inner bod_right" *ngIf="selectedEventReviewObj?.DriverMateNotes!= null">
                  <div class="pl-05">
                    {{selectedEventReviewObj?.DriverMateNotes}}
                  </div>
                </div>
              </div>
            </div>
            <div class="row no-m">
              <table class="table table-striped evidence_table">
                <thead>
                  <tr class="trhead">
                    <th>Incident Task</th>
                    <th class="text-center">Celebrate</th>
                    <th class="text-center">Coach</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let x of SelectedComplianceTasks">
                    <td>{{x.TypeName}}</td>
                    <td class="text-center">
                      <label class="checkbox">
                        <input type="checkbox" [(ngModel)]="x.CelebrateFlag">
                        <span> </span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="checkbox">
                        <input type="checkbox" [(ngModel)]="x.CoachFlag">
                        <span> </span>
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="signature">
              <div class="row">
                <div class="col-6">
                  <div class="mb-1 mt-2">
                    <label class="mr-1">
                      Manager Signature ({{SelectedManager?.FirstName}} {{SelectedManager?.LastName}})
                    </label>
                    <input type="text" class="form-control signature_txtbox">
                  </div>
                </div>
                <div class="col-4">
                  <div class="mt-2 mb-1">
                    <label class="mr-1">
                      Date
                    </label>
                    <input type="text" class="form-control signature_txtbox">
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="SelectedDriver?.Name!= null">
                <div class="col-6">
                  <div class="mb-2">
                    <label>
                      Driver Signature ({{SelectedDriver?.Name}})
                    </label>
                    <input type="text" class="form-control signature_txtbox">
                  </div>
                </div>
                <div class="col-4">
                  <div class="mb-1">
                    <label class="mr-1">
                      Date
                    </label>
                    <input type="text" class="form-control signature_txtbox">
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="SelectedDriverMate?.Name!= null">
                <div class="col-6">
                  <div class="mb-2">
                    <label>
                      Driver Mate Signature ({{SelectedDriverMate?.Name}})
                    </label>
                    <input type="text" class="form-control signature_txtbox">
                  </div>
                </div>
                <div class="col-4">
                  <div class="mb-1">
                    <label class="mr-1">
                      Date
                    </label>
                    <input type="text" class="form-control signature_txtbox">
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="live_foot">
          <div class="col no-p">
            <button class="btn btn-success pull-right" (click)="printOut()">Print</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
