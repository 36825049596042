import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from "@angular/router";
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  appsettings: any;
  constructor(
    private http: HttpClient,
    private router: Router,
  ) { }

  login(username: string, password: string) {
    var userData = "username=" + username + "&password=" + password + "&grant_type=password";
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'No-Auth': 'True' });
    return this.http.post(environment.API_URL + "users/login", userData, { headers: reqHeader, observe: 'response' as 'response' })
      .pipe(map(user => {

        // login successful if there's a jwt token in the response
        if (user) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
        }

        return user;
      }));
  }
  //GetAppSettings based on appsettings.json file
  GetAppSettings() {
    if (localStorage.getItem('apiUrl')) {
      environment.API_URL = localStorage.getItem('apiUrl');
    }
    return this.http.get("/api/AppSettings")
      .subscribe(
        (data: any) => {
          this.appsettings = data.data;
          environment.API_URL = this.appsettings.apiUrl;
          localStorage.setItem("apiUrl", this.appsettings.apiUrl);
        },
        error => {
          console.log("authentication Service GetAppSettings failed.");
        }
      );
  };

  GetWhitelabelStylesheet() {
    return this.http.get("/api/WhitelabelStylesheet/");
  };


  logout() {
    // remove user from local storage to log user out
    localStorage.clear();
    this.router.navigate(['/login']);
  }
}
