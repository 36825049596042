<div class="row login">
  <div class="col-12 col-sm-12 col-md-12 col-lg-8 p-0 rightsection hidden-xs"></div>
  <div class="col-12 col-sm-12 col-md-12 col-lg-4 p-0 col-centered">
    <div class="login-form">
      <div class="text-center pb-3">
        <!--<img src="../../assets/images/FNOL-logo.jpg" width="300" />-->
        <div class="logo-login"></div>
      </div>
      <div *ngIf="loginSection==true">
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="form-signin">
          <div class="row no-m">
            <div class="input-group">
              <!--<label class="col-12 no-p" for="username">Username</label>-->
              <span class="input-group-text" id="basic-addon1"><i class="far fa-envelope"></i></span>
              <input type="text" formControlName="username" placeholder="Enter Username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
              <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                <div *ngIf="f.username.errors.required">Username is required</div>
              </div>
            </div>
            <div class="input-group">
              <!--<label class="col-12 no-p" for="password">Password</label>-->
              <span class="input-group-text" id="basic-addon1"><i class="far fa-key"></i></span>
              <input type="password" formControlName="password" placeholder="Enter Password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
              <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Password is required</div>
              </div>
            </div>
            <div class="input-group pull-right rest_pwd">
              <a (click)="ResetPassword()" class="">Reset password ?</a>
            </div>
          </div>
          <div class="mt-1 text-center">
            <button [disabled]="loading" class="btn btn-success">Login <i class="fad fa-sign-in-alt"></i></button>
            <img src="../../assets/images/ButtonProcessing.gif" *ngIf="loading" />
          </div>
        </form>
      </div>
      <!--reset password section-->
      <div *ngIf="ResetpasswordSection==true">
        <h5>Reset Password</h5>
        <form [formGroup]="ResetPasswordForm" (ngSubmit)="ResetPasswordFormSubmit()" class="form-signin">
          <div class="row no-m">
            <div class="input-group">
              <!--<label class="col-12 no-p" for="username">Username</label>-->
              <span class="input-group-text" id="basic-addon1"><i class="far fa-envelope"></i></span>
              <input type="text" formControlName="username" placeholder="Enter Username" class="form-control" [ngClass]="{ 'is-invalid': submitted && r.username.errors }" />
              <div *ngIf="submitted && r.username.errors" class="invalid-feedback">
                <div *ngIf="r.username.errors.required">Username is required</div>
              </div>
            </div>
            <div class="input-group">
              <!--<label class="col-12 no-p" for="password">Password</label>-->
              <span class="input-group-text" id="basic-addon1"><i class="far fa-envelope"></i></span>
              <select class="form-control" (change)="ResetPasswordOtpTypeChange($event)" formControlName="otptype" [ngClass]="{ 'is-invalid': submitted && r.otptype.errors }" style="padding: 0px 20px !important;height: 40px!important;">
                <option disabled [selected]="notSelected">select</option>
                <option value="mobileNumber">Mobile</option>
                <option value="email">Email</option>
              </select>
              <div *ngIf="submitted && r.otptype.errors" class="invalid-feedback">
                <div *ngIf="r.otptype.errors.required">OTP type is required</div>
              </div>
            </div>
            <div class="input-group" *ngIf="selectedOtpType=='mobileNumber'">
              <span class="input-group-text" id="basic-addon1"><i class="far fa-envelope"></i></span>
              <select placeholder="Select Type" [ngModel]="cntryCode" class="form-control" formControlName="CountryCode" [ngClass]="{ 'is-invalid': submitted && r.CountryCode.errors }" style="padding: 0px 20px !important;height: 40px!important;">
                <option *ngFor="let x of CountriesList; index as i" value="{{x.ISDcode}}">{{x.ISDcode}} - {{x.Name}}</option>
              </select>
              <input type="text" formControlName="otpdata" placeholder="Enter Mobile Number" class="form-control" [ngClass]="{ 'is-invalid': submitted && r.otpdata.errors }" />
              <div *ngIf="submitted && r.otpdata.errors" class="invalid-feedback">
                <div *ngIf="r.otpdata.errors.required">Mobile number is required</div>
              </div>
            </div>
            <div class="input-group" *ngIf="selectedOtpType=='email'">
              <span class="input-group-text" id="basic-addon1"><i class="far fa-envelope"></i></span>
              <input type="text" formControlName="otpdata" placeholder="Enter Email Address" class="form-control" [ngClass]="{ 'is-invalid': submitted && r.otpdata.errors }" />
              <div *ngIf="submitted && r.otpdata.errors" class="invalid-feedback">
                <div *ngIf="r.otpdata.errors.required">Email address is required</div>
              </div>
            </div>
            <div class="input-group pull-right">
              <a (click)="ResetPasswordBack()" class="">Back</a>
            </div>
          </div>
          <div class="mt-1 text-center">
            <button [disabled]="loading" class="btn btn-success">Get OTP<i class="fad fa-sign-in-alt"></i></button>
            <img src="../../assets/images/ButtonProcessing.gif" *ngIf="loadingReset" />
          </div>
         <!-- <div *ngIf="error && rstrError" id="rst" class="alert alert-danger">{{error}}</div>-->
        </form>
      </div>
      <!--enter otp section-->
      <div *ngIf="EnterOtpSection==true">
        <h5>Reset Password</h5>
        <form [formGroup]="EnterOtpForm" (ngSubmit)="EnterOtpSubmit()" class="form-signin">
          <div class="row no-m">
            <div class="input-group pl-0">
              <!--<label class="col-12 no-p" for="username">Username</label>-->
              <!--<span class="input-group-text" id="basic-addon1"><i class="far fa-envelope"></i></span>-->
              <input style="border-left: 1px solid #e2e6ea !important;" type="text" formControlName="otpvalue" placeholder="Enter OTP" class="form-control" [ngClass]="{ 'is-invalid': submitted && o.otpvalue.errors }" required />
              <div *ngIf="submitted && o.otpvalue.errors" class="invalid-feedback">
                <div *ngIf="o.otpvalue.errors.required">OTP is required</div>
              </div>
            </div>

         
            <div class="mt-1 text-center w-100">
              <button [disabled]="loading" class="btn btn-success float-left">Verify<i class="fad fa-sign-in-alt"></i></button>
              <img src="../../assets/images/ButtonProcessing.gif" *ngIf="loading" />

              <span class="font-13px text-black float-right mr-2" *ngIf="!hidetimervalue">{{maxTime}} Seconds has left.</span>
              <a (click)="ResetPasswordFormSubmit()" class="font-13px text-black pull-right mr-3" *ngIf="hidetimervalue">Resend OTP?</a>
            </div>
            
          </div>
         <!-- <div *ngIf="error && otpError" class="alert alert-danger">{{error}}</div>-->
        </form>
      </div>
      <!--Re-enter password section-->
      <div *ngIf="EnterNewPasswordSection==true">
        <h5>Reset Password</h5>
        <form [formGroup]="EnterNewPasswordForm" (ngSubmit)="EnterNewPasswordSubmit()" class="form-signin">
          <div class="row no-m">
            <div class="input-group pl-0">
              <!--<label class="col-12 no-p" for="username">Username</label>-->
              <!--<span class="input-group-text" id="basic-addon1"><i class="far fa-envelope"></i></span>-->
              <input  style="border-left: 1px solid #e2e6ea !important;" type="password" formControlName="newpassword" placeholder="NewPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && rp.newpassword.errors }" required />
              <div *ngIf="submitted && rp.newpassword.errors" class="invalid-feedback">
                <div *ngIf="rp.newpassword.errors.pattern">
                  <ul class="validationmsg">
                    <li>Min Length 8</li>
                    <li>Max Length 12</li>
                    <li>One Capital</li>
                    <li>One Numeric</li>
                    <li>One Special</li>
                  </ul>
                </div>
                <div *ngIf="rp.newpassword.errors.required">New Password is required</div>
              </div>
            </div>
            <div class="input-group pl-0">
              <!--<label class="col-12 no-p" for="username">Username</label>-->
            
              <input  style="border-left: 1px solid #e2e6ea !important;" type="password" formControlName="confirmpassword" placeholder="Confirm Password" class="form-control" [ngClass]="{ 'is-invalid': submitted && rp.confirmpassword.errors }" required />
              <div *ngIf="submitted && rp.confirmpassword.errors" class="invalid-feedback">
                <div *ngIf="rp.confirmpassword.errors.required">Confirm password is required</div>
                <div *ngIf="rp.confirmpassword.errors.mustMatch">Passwords must match</div>
              </div>
            </div>
            <div class="mt-1 text-center">
              <button [disabled]="loading" class="btn btn-success">Verify<i class="fad fa-sign-in-alt"></i></button>
              <img src="../../assets/images/ButtonProcessing.gif" *ngIf="loading" />
            </div>
          </div>
        <!--  <div *ngIf="error" class="alert alert-danger">{{error}}</div>-->
        </form>
      </div>
    </div>
    <div class="powered">
      © {{currentyear}}
      <span class="copytextInfo"></span>
      <!-- © {{currentyear}} Fleetly-->
    </div>
  </div>
</div>
