import { Component, OnInit } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from "@angular/router";
import { AuthenticationService } from '../services/authentication.service';
import { first } from 'rxjs/operators';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { CommonModule } from '@angular/common';
import { NgIf, NgClass } from '@angular/common';
import { FormsModule } from '@angular/forms';
@Component({
  selector: 'app-nav-menu',
  standalone: true,
  imports: [NgIf, NgClass, CommonModule, FormsModule, RouterLink, RouterLinkActive],
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {
  profilepic: any;
  rolename: any;
  username: any;
  error = '';
  currentyear: number = (new Date()).getFullYear();
  private apiUrl = environment.API_URL;

  public navmenutogglebutton: boolean = false;
  constructor(
    private router: Router,
    private http: HttpClient,
    private alertService: ToastrService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.getLockScreenStatus();
    this.profilepic = localStorage.getItem('profile');
    this.rolename = localStorage.getItem('roledisplayname');
    this.username = localStorage.getItem('fname')+ ' ' + localStorage.getItem('lname');
  }
  logout() {
    this.authenticationService.logout();
  }
  navmenutoggle() {
    this.navmenutogglebutton = !this.navmenutogglebutton;
    console.log(this.navmenutogglebutton);
    
  }
  //lock screen
  mdlSampleIsOpen: boolean = false;
  openLockScreen(status) {
    return this.http.get("/api/LockScreen")
      .subscribe(
        (data: any) => {
          this.mdlSampleIsOpen = status;
        },
        error => {
          console.log("Error", error);
        }
      );
  };
  //get status of lockscreen
  getLockScreenStatus() {
    return this.http.get("/api/GetLockScreenStatus")
      .subscribe(
        (data: any) => {
          if (data === true) {
            this.mdlSampleIsOpen = true;
          } else {
            this.mdlSampleIsOpen = false;
          }
        },
        error => {
          console.log("Error", error);
        }
      );
  };
  //update lockscreen value false in session
  updateLockScreenStatus() {
    return this.http.get("/api/UpdateLockScreenStatus")
      .subscribe(
        (data: any) => {
          this.mdlSampleIsOpen = false;
        },
        error => {
          console.log("Error", error);
        }
      );
  };
  //submit lockscreen
  lockpassword = "";
  submitLockScreen() {
    if (this.lockpassword == "") {
      this.alertService.error("please enter password");
      return
    }
    this.authenticationService.login(localStorage.getItem('UserName'), this.lockpassword)
      .pipe(first())
      .subscribe(
        (data: any) => {
          localStorage.setItem("token", data.body.access_token);
          this.updateLockScreenStatus();
          this.lockpassword = "";
          this.error = '';
        },
        error => {
          this.mdlSampleIsOpen = true;
          this.error = "Please Check Entered Password !";
          this.lockpassword = "";
        });
  };

}
